import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Observable, of, throwError } from "rxjs";
import { delay, mergeMap, materialize, dematerialize } from "rxjs/operators";
import { User } from "../models/user";
import { Role } from "../models/role";

const users: User[] = [
  {
    id: "1",
    //img: "assets/images/user/admin.jpg",
    username: "admin@software.com",
    password: "admin@123",
    name: "Sarah",
    //lastName: "Smith",
    role: Role.Admin,
    token: "admin-token",
    user_type: ""
  },
  {
    id: "2",
    //img: "assets/images/user/employee.jpg",
    username: "employee@software.com",
    password: "employee@123",
    name: "Ashton",
    //lastName: "Cox",
    role: Role.Employee,
    token: "employee-token",
    user_type: ""
  },
  {
    id: "3",
    //img: "assets/images/user/client.jpg",
    username: "client@software.com",
    password: "client@123",
    name: "Cara",
    //lastName: "Stevens",
    role: Role.Client,
    token: "client-token",
    user_type: ""
  },
];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(handleRoute));

    function handleRoute() {
      switch (true) {
        case url.endsWith("/authenticate") && method === "POST":
          return authenticate();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions

    function authenticate() {
      const { username, password } = body;
      const user = users.find(
        (x) => x.username === username && x.password === password
      );
      if (!user) {
        return error("Username or password is incorrect");
      }
      return ok({
        id: user.id,
        //img: user.img,
        username: user.username,
        name: user.name,
        //lastName: user.lastName,
        role: user.role,
        token: user.token,
      });
    }

    function au(){
        const { username, password } = body;
        let poolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId
        };
        var authenticationData = {
            Username : username,
            Password : password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        var userData = {
            Username : username,
            Pool : userPool
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        var chk;
        var X;
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                console.log("SUCCESS")
                var accessToken = result.getAccessToken().getJwtToken();
                console.log("RESULT:",result)
                console.log("RE",accessToken)
                /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
                var idToken = result.getIdToken().getJwtToken();
                console.log("RESU:",idToken)
                try{
                      cognitoUser.getUserAttributes((err, result) => {
                        if (err) {
                          console.log("\n\Some Error: ", err, "\n\n");
                        } else {
                          chk=1
                          console.log('ATTRIBUTES', result);
                          X={
                            id: result[5].Value,
                            //img: user.img,
                            username: result[8].Value,
                            name: result[8].Value,
                            //lastName: user.lastName,
                            role: "Admin",
                            token: "admin-token",
                        }
                        return ok(X)
                        }
                  })
                }catch(err){
                  console.log(err)
                }
            },
    
            onFailure: function(err) {
                chk=0
                console.log("FAILURE")
                return error("Username or password is incorrect");
                //alert(err);
            },
    
        });/*
        if(chk==1)
        {
          console.log("CORRECT INFORMATION")
          return ok(X);
      }
        else if(chk==0)
        {
          console.log("INCORRECT")
          return error("Username or password is incorrect");
        }*/
    }
    // helper functions

    function ok(body?) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function error(message) {
      return throwError({ error: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: "Unauthorised" } });
    }

    function isLoggedIn() {
      return headers.get("Authorization") === "Bearer fake-jwt-token";
    }
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
