import { RouteInfo } from "./sidebar.metadata";
import { SubmenuInfo } from "./sidebar.metadata";
import { DreamboardService } from "src/app/admin/dreamboard/dreamboard/dreamboard.service";
import { Component, OnInit,Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { APIService } from "src/app/API.service";



@Injectable({
  providedIn: 'root',
})

export class Sidebar{
  routes: RouteInfo[] = [];
  loginDetail: any;
  client: any;
  dreamdata: any = [];
  path:any=[];

  constructor(private api: APIService) {
    // this.loginDetail = JSON.parse(localStorage.getItem('currentUser'));
    // this.client = this.loginDetail.client;
    //console.log('LoginDetail:', this.loginDetail);
    
  }


  
  fetchData(pageNumber: any): Promise<void> {

    return new Promise<void>(async (resolve, reject) => {
      try {
        this.loginDetail = JSON.parse(localStorage.getItem('currentUser'));
        this.client = this.loginDetail.client;
      
        const lookupTable = await this.api.GetLookupTableDreamBoard(this.client, pageNumber);
  
        if (lookupTable && lookupTable.Elements) {
          const newData = JSON.parse(lookupTable.Elements);
          if (newData.length > 0) {
            //this.dreamdata = this.dreamdata.concat(newData);
            this.dreamdata = [...this.dreamdata,...newData];
            console.log("NEW",newData)
            console.log("DreamDATA",this.dreamdata)
            this.path=this.dreamdata.map(entry => entry[0]);
            console.log('path:', this.path);
  
            // Recursively call fetchData
            await this.fetchData(Number(pageNumber) + 1);
            resolve();
          } else {
            console.log('No more data to fetch.');
            resolve();
          }
        } else {
          console.log('Invalid or no data from API.');
          //reject('Invalid or no data from API');
          resolve();
        }
      } catch (error) {
        console.log('Error fetching data from API:', error);
        reject(error);
      }
    });
  }
  
 
  async generatedreamboard() {
    const submenu: SubmenuInfo[] = [];
  
    try {
      this.path=[]
      this.dreamdata=[]
      await this.fetchData(1);
      const pathArray = this.path;
      console.log(pathArray);
  
      for (const pathValue of pathArray) {
        submenu.push({
      path: `/admin/dreamboard/view-dreamboard/${pathValue}`,
      title: `${pathValue}`, 
      moduleName: "dashboard", 
      iconType: "",
      icon: "",
      class: "ml-menu",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [""],
      submenu: [],
      permission: [`view-dreamboard/${pathValue}`]
    });
  }
  
  this.ROUTES[2].submenu=submenu; 
  console.log("SUBMEU",this.ROUTES)

} catch (error) {
  console.error('Error while generating dreamboard:', error);
}

  return this.ROUTES;
}


ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
    permission:["Dashboard"]
  },

  // Admin Modules
  {
    path: "",
    title: "MENUITEMS.DASHBOARD.TEXT",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "monitor",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/dashboard/dashboard2",
        //title: "MENUITEMS.DASHBOARD.LIST.DASHBOARD2",
        title: "Dashboard",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["dashboard2"]
      },
      {
        path: "/admin/dreamboard/dreamboard",
        //title: "MENUITEMS.DASHBOARD.LIST.DASHBOARD2",
        title: "Dreamboard",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission: ["dreamboard"]
        
      },
    ],
    permission: ["default"]
  },
  {
    path: "",
    title: "Modules",
    moduleName: "dream",
    iconType: "feather",
    icon: "folder",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu:[],
 
    permission: ["default"]
  },
  {
    path: "",
    title: "MENUITEMS.ATTENDANCE.TEXT",
    moduleName: "attendance",
    iconType: "feather",
    icon: "edit",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/attendance/today",
        title: "MENUITEMS.ATTENDANCE.LIST.TODAY",
        moduleName: "attendance",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Admin"],
        submenu: [],
        permission:["today"]
      },
      {
        path: "/admin/attendance/employee",
        title: "MENUITEMS.ATTENDANCE.LIST.EMPLOYEE",
        moduleName: "attendance",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Admin"],
        submenu: [],
        permission: ["employee"]
        
      },
      {
        path: "/admin/attendance/audit-trial",
        title: "Audit-Trial",
        moduleName: "attendance",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Admin"],
        submenu: [],
        permission:["audit-trial"]
      },
    ],
    permission: ["default"]
  },
  // {
  //   path: "",
  //   title: "MENUITEMS.PROJECTS.TEXT",
  //   moduleName: "projects",
  //   iconType: "feather",
  //   icon: "book",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/projects/allProjects",
  //       title: "MENUITEMS.PROJECTS.LIST.ALL-PROJECTS",
  //       moduleName: "projects",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/projects/addProject",
  //       title: "MENUITEMS.PROJECTS.LIST.ADD-PROJECT",
  //       moduleName: "projects",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/projects/projectDetails",
  //       title: "MENUITEMS.PROJECTS.LIST.PROJECT-DETAILS",
  //       moduleName: "projects",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "MENUITEMS.FORMS.TEXT",
    moduleName: "forms",
    iconType: "feather",
    icon: "layout",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [

      {
        path: "/admin/forms/allformparameter",
        title: "MENUITEMS.FORMS.LIST.FORM-PARAMETER.TEXT",
        moduleName: "form",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allformparameter"]
      },
      {
        path: "/admin/forms/allformtype",
        title: "MENUITEMS.FORMS.LIST.FORM-TYPE.TEXT",
        moduleName: "form",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allformtype"]
      },
      {
        path: "/admin/forms/allformuserpermission",
        title: "MENUITEMS.FORMS.LIST.FORM-USER-PERMISSION.TEXT",
        moduleName: "form",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission: ["allformuserpermission"]
        
      },
    ],
    permission: ["default"]
  },
  // {
  //   path: "",
  //   title: "MENUITEMS.EMPLOYEES.TEXT",
  //   moduleName: "employees",
  //   iconType: "feather",
  //   icon: "users",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/employees/allEmployees",
  //       title: "MENUITEMS.EMPLOYEES.LIST.ALL-EMPLOYEE",
  //       moduleName: "employees",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/employees/addemployee",
  //       title: "MENUITEMS.EMPLOYEES.LIST.ADD-EMPLOYEE",
  //       moduleName: "employees",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/employees/edit-employee",
  //       title: "MENUITEMS.EMPLOYEES.LIST.EDIT-EMPLOYEE",
  //       moduleName: "employees",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/employees/employee-profile",
  //       title: "MENUITEMS.EMPLOYEES.LIST.PROFILE",
  //       moduleName: "employees",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/configuration/allRoles",
  //       title: "MENUITEMS.CONFIGURATION.LIST.ROLE.TEXT",
  //       moduleName: "role",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "MENUITEMS.CONFIGURATION.TEXT",
    moduleName: "configuration",
    iconType: "feather",
    icon: "users",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [

      {
        path: "/admin/configuration/allDepartments",
        title: "MENUITEMS.CONFIGURATION.LIST.DEPARTMENT.TEXT",
        moduleName: "departments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allDepartments"]
      },
      {
        path: "/admin/configuration/allLocationTypes",
        title: "MENUITEMS.CONFIGURATION.LIST.LOCATION-TYPES.TEXT",
        moduleName: "location_types",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allLocationTypes"]
      },
      {
        path: "/admin/configuration/allManufacturers",
        title: "MENUITEMS.CONFIGURATION.LIST.MANUFACTURERS.TEXT",
        moduleName: "manufacturer",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allManufacturers"]
      },
      {
        path: "/admin/configuration/allAssetMaps",
        title: "MENUITEMS.CONFIGURATION.LIST.ASSET-MAP.TEXT",
        moduleName: "asset_map",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allAssetMaps"]
      },
      {
        path: "/admin/configuration/allModels",
        title: "MENUITEMS.CONFIGURATION.LIST.MODEL.TEXT",
        moduleName: "model",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allModels"]
      },
      {
        path: "/admin/configuration/allPriorities",
        title: "MENUITEMS.CONFIGURATION.LIST.PRIORITY.TEXT",
        moduleName: "priority",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:["allPriorities"]
    },
    {
      path: "/admin/configuration/allSubDepartments",
      title: "MENUITEMS.CONFIGURATION.LIST.SUB-DEPARTMENT.TEXT",
      moduleName: "subdepartment",
      iconType: "",
      icon: "",
      class: "ml-menu",
      groupTitle: false,
      badge: "",
      badgeClass: "",
      role: [""],
      submenu: [],
      permission:["allSubDepartments"]
  },
  {
    path: "/admin/configuration/allVendorCompanies",
    title: "MENUITEMS.CONFIGURATION.LIST.VENDOR-COMPANY.TEXT",
    moduleName: "vendorcompany",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allVendorCompanies"]
  },
  {
    path: "/admin/configuration/allRoles",
    title: "MENUITEMS.CONFIGURATION.LIST.ROLE.TEXT",
    moduleName: "role",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allRoles"]
  },
  {
    path: "/admin/configuration/allLocations",
    title: "MENUITEMS.CONFIGURATION.LIST.LOCATION.TEXT",
    moduleName: "location",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allLocations"]
  },
  {
    path: "/admin/configuration/allProblems",
    title: "MENUITEMS.CONFIGURATION.LIST.PROBLEM.TEXT",
    moduleName: "problem",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allProblems"]
  },
  {
    path: "/admin/configuration/allUsers",
    title: "MENUITEMS.CONFIGURATION.LIST.USER.TEXT",
    moduleName: "user",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allUsers"]
  },
  {
    path: "/admin/configuration/allmqtt",
    title: "MENUITEMS.CONFIGURATION.LIST.MQTT.TEXT",
    moduleName: "mqtt",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allmqtt"]
  },
  {
    path: "/admin/configuration/allAssets",
    title: "MENUITEMS.CONFIGURATION.LIST.ASSET.TEXT",
    moduleName: "asset_add",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission:["allAssets"]
  },
  
  {
    path: "/admin/configuration/allShifts",
    title: "MENUITEMS.CONFIGURATION.LIST.SHIFT.TEXT",
    moduleName: "asset_add",
    iconType: "",
    icon: "",
    class: "ml-menu",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
    permission: ["allShifts"]
  }
  
  ],
  permission: ["default"]
  },
  // {
  //   path: "",
  //   title: "MENUITEMS.LEAVES.TEXT",
  //   moduleName: "leaves",
  //   iconType: "feather",
  //   icon: "trello",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/leaves/leave-requests",
  //       title: "MENUITEMS.LEAVES.LIST.LEAVE-REQUESTS",
  //       moduleName: "leaves",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["Admin"],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/leaves/leave-balance",
  //       title: "MENUITEMS.LEAVES.LIST.LEAVE-BALANCE",
  //       moduleName: "leaves",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["Admin"],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/leaves/leave",
  //       title: "MENUITEMS.LEAVES.LIST.LEAVE-TYPES",
  //       moduleName: "leaves",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["Admin"],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "MENUITEMS.HOLIDAYS.TEXT",
  //   moduleName: "holidays",
  //   iconType: "feather",
  //   icon: "coffee",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/holidays/all-holidays",
  //       title: "MENUITEMS.HOLIDAYS.LIST.ALL-HOLIDAYS",
  //       moduleName: "holidays",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["Admin"],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/holidays/add-holiday",
  //       title: "MENUITEMS.HOLIDAYS.LIST.ADD-HOLIDAY",
  //       moduleName: "holidays",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["Admin"],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/holidays/edit-holiday",
  //       title: "MENUITEMS.HOLIDAYS.LIST.EDIT-HOLIDAY",
  //       moduleName: "holidays",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["Admin"],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "MENUITEMS.CLIENTS.TEXT",
  //   moduleName: "clients",
  //   iconType: "feather",
  //   icon: "user",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/clients/all-clients",
  //       title: "MENUITEMS.CLIENTS.LIST.ALL-CLIENTS",
  //       moduleName: "clients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/clients/add-client",
  //       title: "MENUITEMS.CLIENTS.LIST.ADD-CLIENT",
  //       moduleName: "clients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/clients/edit-client",
  //       title: "MENUITEMS.CLIENTS.LIST.EDIT-CLIENT",
  //       moduleName: "clients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "MENUITEMS.ACCOUNTS.TEXT",
  //   moduleName: "accounts",
  //   iconType: "feather",
  //   icon: "book-open",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/accounts/all-payment",
  //       title: "MENUITEMS.ACCOUNTS.LIST.ALL-PAYMENTS",
  //       moduleName: "accounts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/accounts/add-payment",
  //       title: "MENUITEMS.ACCOUNTS.LIST.ADD-PAYMENT",
  //       moduleName: "accounts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/accounts/invoice",
  //       title: "MENUITEMS.ACCOUNTS.LIST.INVOICE",
  //       moduleName: "accounts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },

  // Employee Modules
  {
    path: "/employee/dashboard",
    title: "MENUITEMS.EMPLOYEE.DASHBOARD",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "airplay",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/employee/attendance",
    title: "MENUITEMS.EMPLOYEE.ATTENDANCE",
    moduleName: "attendance",
    iconType: "feather",
    icon: "edit",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/employee/myleaves",
    title: "MENUITEMS.EMPLOYEE.MY-LEAVES",
    moduleName: "myleaves",
    iconType: "feather",
    icon: "file-text",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/employee/myteam",
    title: "MENUITEMS.EMPLOYEE.MYTEAM",
    moduleName: "myteam",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/employee/myprojects",
    title: "MENUITEMS.EMPLOYEE.MYPROJECTS",
    moduleName: "myprojects",
    iconType: "feather",
    icon: "database",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/employee/mytasks",
    title: "MENUITEMS.EMPLOYEE.MYTASKS",
    moduleName: "mytasks",
    iconType: "feather",
    icon: "command",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/employee/settings",
    title: "MENUITEMS.EMPLOYEE.SETTINGS",
    moduleName: "settings",
    iconType: "feather",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/apps/chat",
    title: "MENUITEMS.EMPLOYEE.CHAT",
    moduleName: "apps",
    iconType: "feather",
    icon: "message-square",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
    permission:[""]
  },
  // Client Modules
  {
    path: "/client/dashboard",
    title: "MENUITEMS.CLIENT.DASHBOARD",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "airplay",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Client"],
    submenu: [],
    permission:[""]
  },
  {
    path: "",
    title: "MENUITEMS.CLIENT.PROJECTS.TEXT",
    moduleName: "projects",
    iconType: "feather",
    icon: "book",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Client"],
    submenu: [
      {
        path: "/client/projects/myProjects",
        title: "MENUITEMS.CLIENT.PROJECTS.LIST.MY-PROJECTS",
        moduleName: "projects",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:[""]
      },
      {
        path: "/client/projects/projectDetails",
        title: "MENUITEMS.CLIENT.PROJECTS.LIST.PROJECT-DETAILS",
        moduleName: "projects",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:[""]
      },
    ],
    permission: [""]
  },
  {
    path: "",
    title: "MENUITEMS.CLIENT.SUPPORTS.TEXT",
    moduleName: "supports",
    iconType: "feather",
    icon: "slack",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Client"],
    submenu: [
      {
        path: "/client/supports/tickets",
        title: "MENUITEMS.CLIENT.SUPPORTS.LIST.TICKETS",
        moduleName: "supports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission:[""]
      },
      {
        path: "/client/supports/ticketDetails",
        title: "MENUITEMS.CLIENT.SUPPORTS.LIST.TICKET-DETAILS",
        moduleName: "supports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
        permission: [""]
        
      },
    ],
    permission: ["ticket"]
  },

  {
    path: "/client/billing",
    title: "MENUITEMS.CLIENT.BILLING",
    moduleName: "billing",
    iconType: "feather",
    icon: "file-text",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Client"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/apps/chat",
    title: "MENUITEMS.CLIENT.CHAT",
    moduleName: "apps",
    iconType: "feather",
    icon: "message-circle",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Client"],
    submenu: [],
    permission:[""]
  },
  {
    path: "/client/settings",
    title: "MENUITEMS.CLIENT.SETTINGS",
    moduleName: "settings",
    iconType: "feather",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Client"],
    submenu: [],
    permission:[""]
  },

  // Common Modules

  // {
  //   path: "",
  //   title: "Apps",
  //   moduleName: "",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin", "Employee"],
  //   submenu: [],
  // },
  // {
  //   path: "calendar",
  //   title: "Calendar",
  //   moduleName: "calendar",
  //   iconType: "feather",
  //   icon: "calendar",
  //   class: "",
  //   groupTitle: false,
  //   badge: "New",
  //   badgeClass: "badge bg-blue sidebar-badge float-end",
  //   role: ["Admin", "Employee"],
  //   submenu: [],
  // },
  // {
  //   path: "task",
  //   title: "Task",
  //   moduleName: "task",
  //   iconType: "feather",
  //   icon: "check-circle",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [],
  // },
  // {
  //   path: "contacts",
  //   title: "Contacts",
  //   moduleName: "contacts",
  //   iconType: "feather",
  //   icon: "user-plus",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin", "Employee"],
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Email",
  //   moduleName: "email",
  //   iconType: "feather",
  //   icon: "mail",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin", "Employee"],
  //   submenu: [
  //     {
  //       path: "/email/inbox",
  //       title: "Inbox",
  //       moduleName: "email",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/email/compose",
  //       title: "Compose",
  //       moduleName: "email",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/email/read-mail",
  //       title: "Read Email",
  //       moduleName: "email",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "More Apps",
  //   moduleName: "apps",
  //   iconType: "feather",
  //   icon: "star",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "4",
  //   badgeClass: "badge bg-orange sidebar-badge float-end",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/apps/chat",
  //       title: "Chat",
  //       moduleName: "apps",
  //       iconType: "feather",
  //       icon: "chat",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/apps/dragdrop",
  //       title: "Drag & Drop",
  //       moduleName: "apps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/apps/contact-grid",
  //       title: "Contact Grid",
  //       moduleName: "apps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/apps/support",
  //       title: "Support",
  //       moduleName: "apps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Widgets",
  //   moduleName: "widget",
  //   iconType: "feather",
  //   icon: "gift",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/widget/chart-widget",
  //       title: "Chart Widget",
  //       moduleName: "widget",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/widget/data-widget",
  //       title: "Data Widget",
  //       moduleName: "widget",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Components",
  //   moduleName: "",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "User Interface (UI)",
  //   moduleName: "ui",
  //   iconType: "feather",
  //   icon: "copy",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/ui/alerts",
  //       title: "Alerts",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/badges",
  //       title: "Badges",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/chips",
  //       title: "Chips",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/modal",
  //       title: "Modal",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/buttons",
  //       title: "Buttons",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/expansion-panel",
  //       title: "Expansion Panel",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/bottom-sheet",
  //       title: "Bottom Sheet",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/dialogs",
  //       title: "Dialogs",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/cards",
  //       title: "Cards",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/labels",
  //       title: "Labels",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/list-group",
  //       title: "List Group",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/snackbar",
  //       title: "Snackbar",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/preloaders",
  //       title: "Preloaders",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/progressbars",
  //       title: "Progress Bars",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/tabs",
  //       title: "Tabs",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/typography",
  //       title: "Typography",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/helper-classes",
  //       title: "Helper Classes",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Forms",
  //   moduleName: "forms",
  //   iconType: "feather",
  //   icon: "layout",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/forms/form-controls",
  //       title: "Form Controls",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/advance-controls",
  //       title: "Advanced Controls",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/form-example",
  //       title: "Form Examples",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/form-validation",
  //       title: "Form Validation",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/wizard",
  //       title: "Form Wizard",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/editors",
  //       title: "Editors",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Tables",
  //   moduleName: "tables",
  //   iconType: "feather",
  //   icon: "grid",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/tables/basic-tables",
  //       title: "Basic Tables",
  //       moduleName: "tables",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/tables/material-tables",
  //       title: "Material Tables",
  //       moduleName: "tables",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/tables/ngx-datatable",
  //       title: "ngx-datatable",
  //       moduleName: "tables",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Medias",
  //   moduleName: "media",
  //   iconType: "feather",
  //   icon: "image",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/media/gallery",
  //       moduleName: "media",
  //       title: "Image Gallery",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Charts",
  //   moduleName: "charts",
  //   iconType: "feather",
  //   icon: "pie-chart",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "7",
  //   badgeClass: "badge bg-green sidebar-badge float-end",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/charts/echart",
  //       title: "Echart",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/apex",
  //       title: "Apex",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/chartjs",
  //       title: "ChartJS",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/ngx-charts",
  //       title: "Ngx-Charts",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/gauge",
  //       title: "Gauge",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Timeline",
  //   moduleName: "timeline",
  //   iconType: "feather",
  //   icon: "git-merge",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/timeline/timeline1",
  //       title: "Timeline 1",
  //       moduleName: "timeline",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/timeline/timeline2",
  //       title: "Timeline 2",
  //       moduleName: "timeline",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Icons",
  //   moduleName: "icons",
  //   iconType: "feather",
  //   icon: "feather",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/icons/material",
  //       title: "Material Icons",
  //       moduleName: "icons",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/icons/font-awesome",
  //       title: "Font Awesome",
  //       moduleName: "icons",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Authentication",
  //   moduleName: "authentication",
  //   iconType: "feather",
  //   icon: "user-check",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/authentication/signin",
  //       title: "Sign In",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/signup",
  //       title: "Sign Up",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/forgot-password",
  //       title: "Forgot Password",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/locked",
  //       title: "Locked",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/page404",
  //       title: "404 - Not Found",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/page500",
  //       title: "500 - Server Error",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Extra Pages",
  //   moduleName: "extra-pages",
  //   iconType: "feather",
  //   icon: "anchor",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/extra-pages/profile",
  //       title: "Profile",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/pricing",
  //       title: "Pricing",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/invoice",
  //       title: "Invoice",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/faqs",
  //       title: "Faqs",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/blank",
  //       title: "Blank Page",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Maps",
  //   moduleName: "maps",
  //   iconType: "feather",
  //   icon: "map-pin",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/maps/google",
  //       title: "Google Map",
  //       moduleName: "maps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Multi level Menu",
  //   moduleName: "multilevel",
  //   iconType: "feather",
  //   icon: "chevrons-down",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/multilevel/first1",
  //       title: "First",
  //       moduleName: "multilevel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/",
  //       title: "Second",
  //       moduleName: "secondlevel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-sub-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [
  //         {
  //           path: "/multilevel/secondlevel/second1",
  //           title: "Second 1",
  //           moduleName: "secondlevel",
  //           iconType: "",
  //           icon: "",
  //           class: "ml-sub-sub-menu",
  //           groupTitle: false,
  //           badge: "",
  //           badgeClass: "",
  //           role: [""],
  //           submenu: [],
  //         },
  //         {
  //           path: "/multilevel/secondlevel/second2",
  //           title: "Second 2",
  //           moduleName: "secondlevel",
  //           iconType: "",
  //           icon: "",
  //           class: "ml-sub-sub-menu",
  //           groupTitle: false,
  //           badge: "",
  //           badgeClass: "",
  //           role: [""],
  //           submenu: [],
  //         },
  //       ],
  //     },
  //     {
  //       path: "/multilevel/first3",
  //       title: "Third",
  //       moduleName: "multilevel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
];
ngOnInit() {
  throw new Error("Function not implemented.");
}

}
