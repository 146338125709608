/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateLocationType: OnCreateLocationTypeSubscription;
  onUpdateLocationType: OnUpdateLocationTypeSubscription;
  onDeleteLocationType: OnDeleteLocationTypeSubscription;
  onCreateAssetLocations: OnCreateAssetLocationsSubscription;
  onUpdateAssetLocations: OnUpdateAssetLocationsSubscription;
  onDeleteAssetLocations: OnDeleteAssetLocationsSubscription;
  onCreateLocation: OnCreateLocationSubscription;
  onUpdateLocation: OnUpdateLocationSubscription;
  onDeleteLocation: OnDeleteLocationSubscription;
  onCreatePriority: OnCreatePrioritySubscription;
  onUpdatePriority: OnUpdatePrioritySubscription;
  onDeletePriority: OnDeletePrioritySubscription;
  onCreateProblem: OnCreateProblemSubscription;
  onUpdateProblem: OnUpdateProblemSubscription;
  onDeleteProblem: OnDeleteProblemSubscription;
  onCreateAmProblems: OnCreateAmProblemsSubscription;
  onUpdateAmProblems: OnUpdateAmProblemsSubscription;
  onDeleteAmProblems: OnDeleteAmProblemsSubscription;
  onCreateAssetMap: OnCreateAssetMapSubscription;
  onUpdateAssetMap: OnUpdateAssetMapSubscription;
  onDeleteAssetMap: OnDeleteAssetMapSubscription;
  onCreateManufacturer: OnCreateManufacturerSubscription;
  onUpdateManufacturer: OnUpdateManufacturerSubscription;
  onDeleteManufacturer: OnDeleteManufacturerSubscription;
  onCreateModel: OnCreateModelSubscription;
  onUpdateModel: OnUpdateModelSubscription;
  onDeleteModel: OnDeleteModelSubscription;
  onCreateDepartment: OnCreateDepartmentSubscription;
  onUpdateDepartment: OnUpdateDepartmentSubscription;
  onDeleteDepartment: OnDeleteDepartmentSubscription;
  onCreateSubDepartment: OnCreateSubDepartmentSubscription;
  onUpdateSubDepartment: OnUpdateSubDepartmentSubscription;
  onDeleteSubDepartment: OnDeleteSubDepartmentSubscription;
  onCreateVendorCompany: OnCreateVendorCompanySubscription;
  onUpdateVendorCompany: OnUpdateVendorCompanySubscription;
  onDeleteVendorCompany: OnDeleteVendorCompanySubscription;
  onCreateRole: OnCreateRoleSubscription;
  onUpdateRole: OnUpdateRoleSubscription;
  onDeleteRole: OnDeleteRoleSubscription;
  onCreateUserLocations: OnCreateUserLocationsSubscription;
  onUpdateUserLocations: OnUpdateUserLocationsSubscription;
  onDeleteUserLocations: OnDeleteUserLocationsSubscription;
  onCreateAssetUserNotify: OnCreateAssetUserNotifySubscription;
  onUpdateAssetUserNotify: OnUpdateAssetUserNotifySubscription;
  onDeleteAssetUserNotify: OnDeleteAssetUserNotifySubscription;
  onCreateAssetOwner: OnCreateAssetOwnerSubscription;
  onUpdateAssetOwner: OnUpdateAssetOwnerSubscription;
  onDeleteAssetOwner: OnDeleteAssetOwnerSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateAssetAddition: OnCreateAssetAdditionSubscription;
  onUpdateAssetAddition: OnUpdateAssetAdditionSubscription;
  onDeleteAssetAddition: OnDeleteAssetAdditionSubscription;
  onCreateTickets: OnCreateTicketsSubscription;
  onUpdateTickets: OnUpdateTicketsSubscription;
  onDeleteTickets: OnDeleteTicketsSubscription;
  onCreateSavedTags: OnCreateSavedTagsSubscription;
  onUpdateSavedTags: OnUpdateSavedTagsSubscription;
  onDeleteSavedTags: OnDeleteSavedTagsSubscription;
  onCreateTicketImages: OnCreateTicketImagesSubscription;
  onUpdateTicketImages: OnUpdateTicketImagesSubscription;
  onDeleteTicketImages: OnDeleteTicketImagesSubscription;
  onCreateFormParameter: OnCreateFormParameterSubscription;
  onUpdateFormParameter: OnUpdateFormParameterSubscription;
  onDeleteFormParameter: OnDeleteFormParameterSubscription;
  onCreateFormType: OnCreateFormTypeSubscription;
  onUpdateFormType: OnUpdateFormTypeSubscription;
  onDeleteFormType: OnDeleteFormTypeSubscription;
  onCreateTask: OnCreateTaskSubscription;
  onUpdateTask: OnUpdateTaskSubscription;
  onDeleteTask: OnDeleteTaskSubscription;
  onCreateMqttConfiguration: OnCreateMqttConfigurationSubscription;
  onUpdateMqttConfiguration: OnUpdateMqttConfigurationSubscription;
  onDeleteMqttConfiguration: OnDeleteMqttConfigurationSubscription;
  onCreateShiftTable: OnCreateShiftTableSubscription;
  onUpdateShiftTable: OnUpdateShiftTableSubscription;
  onDeleteShiftTable: OnDeleteShiftTableSubscription;
  onCreateDashboard: OnCreateDashboardSubscription;
  onUpdateDashboard: OnUpdateDashboardSubscription;
  onDeleteDashboard: OnDeleteDashboardSubscription;
  onCreateNotificationTable: OnCreateNotificationTableSubscription;
  onUpdateNotificationTable: OnUpdateNotificationTableSubscription;
  onDeleteNotificationTable: OnDeleteNotificationTableSubscription;
  onCreateLookupTableTicket: OnCreateLookupTableTicketSubscription;
  onUpdateLookupTableTicket: OnUpdateLookupTableTicketSubscription;
  onDeleteLookupTableTicket: OnDeleteLookupTableTicketSubscription;
  onCreateLookupTableTask: OnCreateLookupTableTaskSubscription;
  onUpdateLookupTableTask: OnUpdateLookupTableTaskSubscription;
  onDeleteLookupTableTask: OnDeleteLookupTableTaskSubscription;
  onCreateLookupTableNotification: OnCreateLookupTableNotificationSubscription;
  onUpdateLookupTableNotification: OnUpdateLookupTableNotificationSubscription;
  onDeleteLookupTableNotification: OnDeleteLookupTableNotificationSubscription;
  onCreateTaskTest: OnCreateTaskTestSubscription;
  onUpdateTaskTest: OnUpdateTaskTestSubscription;
  onDeleteTaskTest: OnDeleteTaskTestSubscription;
  onCreateLookupTaskTest: OnCreateLookupTaskTestSubscription;
  onUpdateLookupTaskTest: OnUpdateLookupTaskTestSubscription;
  onDeleteLookupTaskTest: OnDeleteLookupTaskTestSubscription;
  onCreateLookupTableProblem: OnCreateLookupTableProblemSubscription;
  onUpdateLookupTableProblem: OnUpdateLookupTableProblemSubscription;
  onDeleteLookupTableProblem: OnDeleteLookupTableProblemSubscription;
  onCreateLookupTableAssetMap: OnCreateLookupTableAssetMapSubscription;
  onUpdateLookupTableAssetMap: OnUpdateLookupTableAssetMapSubscription;
  onDeleteLookupTableAssetMap: OnDeleteLookupTableAssetMapSubscription;
  onCreateLookupTableAssetAddition: OnCreateLookupTableAssetAdditionSubscription;
  onUpdateLookupTableAssetAddition: OnUpdateLookupTableAssetAdditionSubscription;
  onDeleteLookupTableAssetAddition: OnDeleteLookupTableAssetAdditionSubscription;
  onCreateLookupTableRole: OnCreateLookupTableRoleSubscription;
  onUpdateLookupTableRole: OnUpdateLookupTableRoleSubscription;
  onDeleteLookupTableRole: OnDeleteLookupTableRoleSubscription;
  onCreateLookupTableDepartment: OnCreateLookupTableDepartmentSubscription;
  onUpdateLookupTableDepartment: OnUpdateLookupTableDepartmentSubscription;
  onDeleteLookupTableDepartment: OnDeleteLookupTableDepartmentSubscription;
  onCreateLookupTableSubDepartment: OnCreateLookupTableSubDepartmentSubscription;
  onUpdateLookupTableSubDepartment: OnUpdateLookupTableSubDepartmentSubscription;
  onDeleteLookupTableSubDepartment: OnDeleteLookupTableSubDepartmentSubscription;
  onCreateLookupTableLocation: OnCreateLookupTableLocationSubscription;
  onUpdateLookupTableLocation: OnUpdateLookupTableLocationSubscription;
  onDeleteLookupTableLocation: OnDeleteLookupTableLocationSubscription;
  onCreateLookupTableLocationType: OnCreateLookupTableLocationTypeSubscription;
  onUpdateLookupTableLocationType: OnUpdateLookupTableLocationTypeSubscription;
  onDeleteLookupTableLocationType: OnDeleteLookupTableLocationTypeSubscription;
  onCreateLookupTableManufacture: OnCreateLookupTableManufactureSubscription;
  onUpdateLookupTableManufacture: OnUpdateLookupTableManufactureSubscription;
  onDeleteLookupTableManufacture: OnDeleteLookupTableManufactureSubscription;
  onCreateLookupTableVender: OnCreateLookupTableVenderSubscription;
  onUpdateLookupTableVender: OnUpdateLookupTableVenderSubscription;
  onDeleteLookupTableVender: OnDeleteLookupTableVenderSubscription;
  onCreateLookupTableUser: OnCreateLookupTableUserSubscription;
  onUpdateLookupTableUser: OnUpdateLookupTableUserSubscription;
  onDeleteLookupTableUser: OnDeleteLookupTableUserSubscription;
  onCreateLookupTablePriority: OnCreateLookupTablePrioritySubscription;
  onUpdateLookupTablePriority: OnUpdateLookupTablePrioritySubscription;
  onDeleteLookupTablePriority: OnDeleteLookupTablePrioritySubscription;
  onCreateLookupTableFormParameter: OnCreateLookupTableFormParameterSubscription;
  onUpdateLookupTableFormParameter: OnUpdateLookupTableFormParameterSubscription;
  onDeleteLookupTableFormParameter: OnDeleteLookupTableFormParameterSubscription;
  onCreateLookupTableFormType: OnCreateLookupTableFormTypeSubscription;
  onUpdateLookupTableFormType: OnUpdateLookupTableFormTypeSubscription;
  onDeleteLookupTableFormType: OnDeleteLookupTableFormTypeSubscription;
  onCreateLookupTableShift: OnCreateLookupTableShiftSubscription;
  onUpdateLookupTableShift: OnUpdateLookupTableShiftSubscription;
  onDeleteLookupTableShift: OnDeleteLookupTableShiftSubscription;
  onCreateLookupTableModel: OnCreateLookupTableModelSubscription;
  onUpdateLookupTableModel: OnUpdateLookupTableModelSubscription;
  onDeleteLookupTableModel: OnDeleteLookupTableModelSubscription;
  onCreateDreamBoard: OnCreateDreamBoardSubscription;
  onUpdateDreamBoard: OnUpdateDreamBoardSubscription;
  onDeleteDreamBoard: OnDeleteDreamBoardSubscription;
  onCreateLookupTableDreamBoard: OnCreateLookupTableDreamBoardSubscription;
  onUpdateLookupTableDreamBoard: OnUpdateLookupTableDreamBoardSubscription;
  onDeleteLookupTableDreamBoard: OnDeleteLookupTableDreamBoardSubscription;
  onCreateLookupMasterTicketTable: OnCreateLookupMasterTicketTableSubscription;
  onUpdateLookupMasterTicketTable: OnUpdateLookupMasterTicketTableSubscription;
  onDeleteLookupMasterTicketTable: OnDeleteLookupMasterTicketTableSubscription;
  onCreateLookupCamcDates: OnCreateLookupCamcDatesSubscription;
  onUpdateLookupCamcDates: OnUpdateLookupCamcDatesSubscription;
  onDeleteLookupCamcDates: OnDeleteLookupCamcDatesSubscription;
  onCreateClientTable: OnCreateClientTableSubscription;
  onUpdateClientTable: OnUpdateClientTableSubscription;
  onDeleteClientTable: OnDeleteClientTableSubscription;
  onCreateAuditrail: OnCreateAuditrailSubscription;
  onUpdateAuditrail: OnUpdateAuditrailSubscription;
  onDeleteAuditrail: OnDeleteAuditrailSubscription;
};

export type CreateLocationTypeInput = {
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
};

export type ModelLocationTypeConditionInput = {
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelLocationTypeConditionInput | null> | null;
  or?: Array<ModelLocationTypeConditionInput | null> | null;
  not?: ModelLocationTypeConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type LocationType = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: ModelLocationConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection";
  items: Array<Location | null>;
  nextToken?: string | null;
};

export type Location = {
  __typename: "Location";
  client: string;
  assets?: ModelAssetAdditionConnection | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type ModelAssetAdditionConnection = {
  __typename: "ModelAssetAdditionConnection";
  items: Array<AssetAddition | null>;
  nextToken?: string | null;
};

export type AssetAddition = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: ModelTicketsConnection | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type ModelTicketsConnection = {
  __typename: "ModelTicketsConnection";
  items: Array<Tickets | null>;
  nextToken?: string | null;
};

export type Tickets = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type UpdateLocationTypeInput = {
  client: string;
  location_type_id: string;
  Description?: string | null;
  Metadata?: string | null;
};

export type DeleteLocationTypeInput = {
  location_type_id: string;
  client: string;
};

export type CreateAssetLocationsInput = {
  client: string;
  asset_id: string;
  location_id: string;
};

export type ModelAssetLocationsConditionInput = {
  and?: Array<ModelAssetLocationsConditionInput | null> | null;
  or?: Array<ModelAssetLocationsConditionInput | null> | null;
  not?: ModelAssetLocationsConditionInput | null;
};

export type AssetLocations = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetLocationsInput = {
  client: string;
  asset_id: string;
  location_id: string;
};

export type DeleteAssetLocationsInput = {
  asset_id: string;
  client: string;
  location_id: string;
};

export type CreateLocationInput = {
  client: string;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  gmt_diff: string;
  Metadata?: string | null;
  locationTypeLocationsId: string;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
};

export type ModelLocationConditionInput = {
  name?: ModelStringInput | null;
  location_type?: ModelStringInput | null;
  longitude?: ModelFloatInput | null;
  latitude?: ModelFloatInput | null;
  movable_latitude?: ModelIntInput | null;
  movable_longitude?: ModelIntInput | null;
  gmt_diff?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelLocationConditionInput | null> | null;
  or?: Array<ModelLocationConditionInput | null> | null;
  not?: ModelLocationConditionInput | null;
  locationTypeLocationsId?: ModelIDInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateLocationInput = {
  client: string;
  location_id: string;
  name?: string | null;
  location_type?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff?: string | null;
  Metadata?: string | null;
  locationTypeLocationsId?: string | null;
};

export type DeleteLocationInput = {
  location_id: string;
  client: string;
};

export type CreatePriorityInput = {
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
};

export type ModelPriorityConditionInput = {
  description?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelPriorityConditionInput | null> | null;
  or?: Array<ModelPriorityConditionInput | null> | null;
  not?: ModelPriorityConditionInput | null;
};

export type Priority = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: ModelProblemConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelProblemConnection = {
  __typename: "ModelProblemConnection";
  items: Array<Problem | null>;
  nextToken?: string | null;
};

export type Problem = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type UpdatePriorityInput = {
  client: string;
  priority_id: string;
  description?: string | null;
  metadata?: string | null;
};

export type DeletePriorityInput = {
  priority_id: string;
  client: string;
};

export type CreateProblemInput = {
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type ModelProblemConditionInput = {
  Origin_Status?: ModelStringInput | null;
  priority?: ModelStringInput | null;
  description?: ModelStringInput | null;
  prob_time?: ModelIntInput | null;
  notif_policy_rem?: ModelIntInput | null;
  escalation_lvl?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  comment_Header?: ModelStringInput | null;
  comment_Suffix?: ModelStringInput | null;
  comment_Prefix?: ModelStringInput | null;
  technician_Comment?: ModelBooleanInput | null;
  impact?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelProblemConditionInput | null> | null;
  or?: Array<ModelProblemConditionInput | null> | null;
  not?: ModelProblemConditionInput | null;
  priorityProblemsId?: ModelIDInput | null;
  items?: ModelIntInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateProblemInput = {
  client: string;
  problem_id: string;
  Origin_Status?: string | null;
  priority?: string | null;
  description?: string | null;
  detail?: string | null;
  prob_time?: number | null;
  notif_policy_rem?: number | null;
  escalation_lvl?: string | null;
  comment?: string | null;
  comment_Header?: string | null;
  comment_Suffix?: string | null;
  comment_Prefix?: string | null;
  technician_Comment?: boolean | null;
  impact?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  metadata?: string | null;
  priorityProblemsId?: string | null;
  items?: Array<number | null> | null;
};

export type DeleteProblemInput = {
  problem_id: string;
  client: string;
};

export type CreateAmProblemsInput = {
  client: string;
  assetmap_id: string;
  problem_id: string;
};

export type ModelAmProblemsConditionInput = {
  and?: Array<ModelAmProblemsConditionInput | null> | null;
  or?: Array<ModelAmProblemsConditionInput | null> | null;
  not?: ModelAmProblemsConditionInput | null;
};

export type AmProblems = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAmProblemsInput = {
  client: string;
  assetmap_id: string;
  problem_id: string;
};

export type DeleteAmProblemsInput = {
  assetmap_id: string;
  client: string;
  problem_id: string;
};

export type CreateAssetMapInput = {
  client: string;
  assetmap_id: string;
  Description: string;
  problems?: Array<string | null> | null;
  Metadata?: string | null;
};

export type ModelAssetMapConditionInput = {
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelAssetMapConditionInput | null> | null;
  or?: Array<ModelAssetMapConditionInput | null> | null;
  not?: ModelAssetMapConditionInput | null;
};

export type AssetMap = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: ModelAssetAdditionConnection | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetMapInput = {
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  Description?: string | null;
  Metadata?: string | null;
};

export type DeleteAssetMapInput = {
  assetmap_id: string;
  client: string;
};

export type CreateManufacturerInput = {
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
};

export type ModelManufacturerConditionInput = {
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelManufacturerConditionInput | null> | null;
  or?: Array<ModelManufacturerConditionInput | null> | null;
  not?: ModelManufacturerConditionInput | null;
};

export type Manufacturer = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: ModelAssetAdditionConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateManufacturerInput = {
  client: string;
  manufacturer_id: string;
  Description?: string | null;
  Metadata?: string | null;
};

export type DeleteManufacturerInput = {
  manufacturer_id: string;
  client: string;
};

export type CreateModelInput = {
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
};

export type ModelModelConditionInput = {
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelModelConditionInput | null> | null;
  or?: Array<ModelModelConditionInput | null> | null;
  not?: ModelModelConditionInput | null;
};

export type Model = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: ModelAssetAdditionConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateModelInput = {
  client: string;
  model_id: string;
  Description?: string | null;
  Metadata?: string | null;
};

export type DeleteModelInput = {
  model_id: string;
  client: string;
};

export type CreateDepartmentInput = {
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
};

export type ModelDepartmentConditionInput = {
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelDepartmentConditionInput | null> | null;
  or?: Array<ModelDepartmentConditionInput | null> | null;
  not?: ModelDepartmentConditionInput | null;
};

export type Department = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: ModelUserConnection | null;
  assets?: ModelAssetAdditionConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type User = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: ModelTicketsConnection | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type UpdateDepartmentInput = {
  client: string;
  dept_id: string;
  Description?: string | null;
  Metadata?: string | null;
};

export type DeleteDepartmentInput = {
  dept_id: string;
  client: string;
};

export type CreateSubDepartmentInput = {
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
};

export type ModelSubDepartmentConditionInput = {
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelSubDepartmentConditionInput | null> | null;
  or?: Array<ModelSubDepartmentConditionInput | null> | null;
  not?: ModelSubDepartmentConditionInput | null;
};

export type SubDepartment = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: ModelUserConnection | null;
  assets?: ModelAssetAdditionConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSubDepartmentInput = {
  client: string;
  sub_dept_id: string;
  Description?: string | null;
  Metadata?: string | null;
};

export type DeleteSubDepartmentInput = {
  sub_dept_id: string;
  client: string;
};

export type CreateVendorCompanyInput = {
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
};

export type ModelVendorCompanyConditionInput = {
  name?: ModelStringInput | null;
  Description?: ModelStringInput | null;
  email?: ModelStringInput | null;
  ph_no?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelVendorCompanyConditionInput | null> | null;
  or?: Array<ModelVendorCompanyConditionInput | null> | null;
  not?: ModelVendorCompanyConditionInput | null;
};

export type VendorCompany = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: ModelAssetAdditionConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVendorCompanyInput = {
  client: string;
  vendor_id: string;
  name?: string | null;
  Description?: string | null;
  email?: string | null;
  ph_no?: string | null;
  Metadata?: string | null;
};

export type DeleteVendorCompanyInput = {
  vendor_id: string;
  client: string;
};

export type CreateRoleInput = {
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
};

export type ModelRoleConditionInput = {
  Description?: ModelStringInput | null;
  time_mult?: ModelFloatInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelRoleConditionInput | null> | null;
  or?: Array<ModelRoleConditionInput | null> | null;
  not?: ModelRoleConditionInput | null;
};

export type Role = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: ModelUserConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRoleInput = {
  client: string;
  role_id: string;
  Description?: string | null;
  time_mult?: number | null;
  Metadata?: string | null;
};

export type DeleteRoleInput = {
  role_id: string;
  client: string;
};

export type CreateUserLocationsInput = {
  client: string;
  user_id: string;
  location_id: string;
};

export type ModelUserLocationsConditionInput = {
  and?: Array<ModelUserLocationsConditionInput | null> | null;
  or?: Array<ModelUserLocationsConditionInput | null> | null;
  not?: ModelUserLocationsConditionInput | null;
};

export type UserLocations = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserLocationsInput = {
  client: string;
  user_id: string;
  location_id: string;
};

export type DeleteUserLocationsInput = {
  user_id: string;
  client: string;
  location_id: string;
};

export type CreateAssetUserNotifyInput = {
  client: string;
  user_id: string;
  asset_id: string;
};

export type ModelAssetUserNotifyConditionInput = {
  and?: Array<ModelAssetUserNotifyConditionInput | null> | null;
  or?: Array<ModelAssetUserNotifyConditionInput | null> | null;
  not?: ModelAssetUserNotifyConditionInput | null;
};

export type AssetUserNotify = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetUserNotifyInput = {
  client: string;
  user_id: string;
  asset_id: string;
};

export type DeleteAssetUserNotifyInput = {
  asset_id: string;
  client: string;
  user_id: string;
};

export type CreateAssetOwnerInput = {
  client: string;
  user_id: string;
  asset_id: string;
};

export type ModelAssetOwnerConditionInput = {
  and?: Array<ModelAssetOwnerConditionInput | null> | null;
  or?: Array<ModelAssetOwnerConditionInput | null> | null;
  not?: ModelAssetOwnerConditionInput | null;
};

export type AssetOwner = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetOwnerInput = {
  client: string;
  user_id: string;
  asset_id: string;
};

export type DeleteAssetOwnerInput = {
  asset_id: string;
  client: string;
  user_id: string;
};

export type CreateUserInput = {
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  notification_token: string;
  formuserpermission: Array<string | null>;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null;
  username?: ModelStringInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  mobile?: ModelStringInput | null;
  email?: ModelStringInput | null;
  user_type?: ModelStringInput | null;
  mobile_privacy?: ModelBooleanInput | null;
  alert_email?: ModelBooleanInput | null;
  alert_sms?: ModelBooleanInput | null;
  esc_email?: ModelBooleanInput | null;
  esc_sms?: ModelBooleanInput | null;
  role?: ModelStringInput | null;
  department?: ModelStringInput | null;
  sub_department?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
  departmentUsersId?: ModelIDInput | null;
  subDepartmentUsersId?: ModelIDInput | null;
  roleUsersId?: ModelIDInput | null;
  userReporting_toId?: ModelIDInput | null;
  formcreate?: ModelBooleanInput | null;
  formread?: ModelBooleanInput | null;
  formupdate?: ModelBooleanInput | null;
  formdelete?: ModelBooleanInput | null;
  imageurl?: ModelStringInput | null;
  formuserpermission?: ModelStringInput | null;
  notification_token?: ModelStringInput | null;
  telegramID?: ModelStringInput | null;
  telegram?: ModelBooleanInput | null;
  push_notification?: ModelBooleanInput | null;
  sms?: ModelBooleanInput | null;
  permission?: ModelStringInput | null;
  chat_Bot?: ModelStringInput | null;
  mobile_permission?: ModelStringInput | null;
  man_per_hour_cost: string;
};

export type UpdateUserInput = {
  client: string;
  user_id: string;
  name?: string | null;
  username?: string | null;
  Description?: string | null;
  Metadata?: string | null;
  mobile?: string | null;
  email?: string | null;
  user_type?: string | null;
  mobile_privacy?: boolean | null;
  alert_email?: boolean | null;
  alert_sms?: boolean | null;
  esc_email?: boolean | null;
  esc_sms?: boolean | null;
  role?: string | null;
  department?: string | null;
  sub_department?: string | null;
  departmentUsersId?: string | null;
  subDepartmentUsersId?: string | null;
  roleUsersId?: string | null;
  userReporting_toId?: string | null;
  formcreate?: boolean | null;
  formread?: boolean | null;
  formupdate?: boolean | null;
  formdelete?: boolean | null;
  imageurl?: string | null;
  formuserpermission?: Array<string | null> | null;
  notification_token?: string | null;
  telegramID?: string | null;
  telegram?: boolean | null;
  push_notification?: boolean | null;
  sms?: boolean | null;
  permission?: Array<string | null> | null;
  chat_Bot?: string | null;
  mobile_permission?: Array<string | null> | null;
  man_per_hour_cost?: string | null;
};

export type DeleteUserInput = {
  user_id: string;
  client: string;
};

export type CreateAssetAdditionInput = {
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type ModelAssetAdditionConditionInput = {
  asset_map?: ModelStringInput | null;
  model?: ModelStringInput | null;
  sub_department?: ModelStringInput | null;
  department?: ModelStringInput | null;
  vendor_company?: ModelStringInput | null;
  manufacturer?: ModelStringInput | null;
  location?: ModelStringInput | null;
  next_service?: ModelStringInput | null;
  last_service?: ModelStringInput | null;
  installation_date?: ModelStringInput | null;
  amc_expiry?: ModelStringInput | null;
  group?: ModelStringInput | null;
  bought_price?: ModelFloatInput | null;
  current_price?: ModelFloatInput | null;
  asset_type?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  technician?: ModelStringInput | null;
  and?: Array<ModelAssetAdditionConditionInput | null> | null;
  or?: Array<ModelAssetAdditionConditionInput | null> | null;
  not?: ModelAssetAdditionConditionInput | null;
  locationAssetsId?: ModelIDInput | null;
  assetMapAssetsId?: ModelIDInput | null;
  manufacturerAssetsId?: ModelIDInput | null;
  modelAssetsId?: ModelIDInput | null;
  departmentAssetsId?: ModelIDInput | null;
  subDepartmentAssetsId?: ModelIDInput | null;
  vendorCompanyAssetsId?: ModelIDInput | null;
  formpermission?: ModelStringInput | null;
};

export type UpdateAssetAdditionInput = {
  client: string;
  tag_id: string;
  asset_map?: string | null;
  asset_id: string;
  user_notify_id?: Array<string | null> | null;
  user_notify?: Array<string | null> | null;
  tech_multi_id?: Array<string | null> | null;
  multiple_technician?: Array<string | null> | null;
  model?: string | null;
  sub_department?: string | null;
  department?: string | null;
  vendor_company?: string | null;
  manufacturer?: string | null;
  location?: string | null;
  next_service?: string | null;
  last_service?: string | null;
  installation_date?: string | null;
  amc_expiry?: string | null;
  group?: string | null;
  bought_price?: number | null;
  current_price?: number | null;
  asset_type?: string | null;
  metadata?: string | null;
  tech_id?: string | null;
  technician?: string | null;
  locationAssetsId?: string | null;
  assetMapAssetsId?: string | null;
  manufacturerAssetsId?: string | null;
  modelAssetsId?: string | null;
  departmentAssetsId?: string | null;
  subDepartmentAssetsId?: string | null;
  vendorCompanyAssetsId?: string | null;
  formpermission?: string | null;
};

export type DeleteAssetAdditionInput = {
  tag_id: string;
  client: string;
  asset_id: string;
};

export type CreateTicketsInput = {
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type ModelTicketsConditionInput = {
  tag_id?: ModelStringInput | null;
  asset_add?: ModelStringInput | null;
  asset_type_id?: ModelStringInput | null;
  problem_id?: ModelStringInput | null;
  problem?: ModelStringInput | null;
  user?: ModelStringInput | null;
  current_status?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  current_esc_lvl?: ModelIntInput | null;
  notification_sent_flag?: ModelBooleanInput | null;
  esc_users?: ModelStringInput | null;
  total_time?: ModelFloatInput | null;
  Problem_time?: ModelFloatInput | null;
  max_esc_reached?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  max_esc_lvl?: ModelIntInput | null;
  technician_id?: ModelStringInput | null;
  mutli_assign?: ModelStringInput | null;
  and?: Array<ModelTicketsConditionInput | null> | null;
  or?: Array<ModelTicketsConditionInput | null> | null;
  not?: ModelTicketsConditionInput | null;
  userTicketsId?: ModelIDInput | null;
  assetAdditionTicketsId?: ModelIDInput | null;
  moveble_latitude?: ModelFloatInput | null;
  moveble_longitude?: ModelFloatInput | null;
  other?: ModelStringInput | null;
  rating?: ModelIntInput | null;
  otp?: ModelIntInput | null;
};

export type UpdateTicketsInput = {
  client: string;
  ticket_id: string;
  tag_id?: string | null;
  asset_add?: string | null;
  asset_type_id?: string | null;
  problem_id?: string | null;
  problem?: string | null;
  user?: string | null;
  current_status?: string | null;
  metadata?: string | null;
  notification_sent_flag?: boolean | null;
  current_esc_lvl?: number | null;
  esc_users?: Array<string | null> | null;
  total_time?: number | null;
  Problem_time?: number | null;
  max_esc_reached?: boolean | null;
  createdAt?: string | null;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  max_esc_lvl?: number | null;
  technician_id?: string | null;
  mutli_assign?: Array<string | null> | null;
  userTicketsId?: string | null;
  updatedAt?: string | null;
  assetAdditionTicketsId?: string | null;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type DeleteTicketsInput = {
  ticket_id: string;
  client: string;
};

export type CreateSavedTagsInput = {
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type SavedTags = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type UpdateSavedTagsInput = {
  client: string;
  userIdTagId: string;
  userId?: string | null;
  tagId?: string | null;
  createdAt?: string | null;
};

export type DeleteSavedTagsInput = {
  client: string;
  userIdTagId: string;
};

export type CreateTicketImagesInput = {
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type TicketImages = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type UpdateTicketImagesInput = {
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type DeleteTicketImagesInput = {
  client: string;
  ticket_id: string;
};

export type CreateFormParameterInput = {
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type FormParameter = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type UpdateFormParameterInput = {
  client: string;
  parameterID: string;
  other?: string | null;
  formdefault?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parameterDescription?: string | null;
  parameterLable?: string | null;
  parameterType?: string | null;
  placeholder?: string | null;
  require?: boolean | null;
  value_1?: number | null;
  value_2?: number | null;
  list?: string | null;
  name?: Array<string | null> | null;
};

export type DeleteFormParameterInput = {
  client: string;
  parameterID: string;
};

export type CreateFormTypeInput = {
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type FormType = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type UpdateFormTypeInput = {
  client: string;
  typeID: string;
  typeLable?: string | null;
  typeDescription?: string | null;
  emailSubject?: string | null;
  emailHeader?: string | null;
  emailBody?: string | null;
  emailFooter?: string | null;
  other?: string | null;
  emailEnable?: boolean | null;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible?: boolean | null;
  lablevisible?: boolean | null;
  descriptionvisible?: boolean | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  mqtt_id?: string | null;
  iot_connect?: boolean | null;
  reserved?: boolean | null;
  block_time?: boolean | null;
  scan?: boolean | null;
};

export type DeleteFormTypeInput = {
  client: string;
  typeID: string;
};

export type CreateTaskInput = {
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type Task = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type UpdateTaskInput = {
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type DeleteTaskInput = {
  clientID: string;
  formID: string;
};

export type CreateMqttConfigurationInput = {
  client: string;
  mqtt_id: string;
  name?: string | null;
  host?: string | null;
  url?: string | null;
  port?: string | null;
  username?: string | null;
  password?: string | null;
  topic?: string | null;
};

export type MqttConfiguration = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type UpdateMqttConfigurationInput = {
  client: string;
  mqtt_id: string;
  name?: string | null;
  host?: string | null;
  url?: string | null;
  port?: string | null;
  username?: string | null;
  password?: string | null;
  topic?: string | null;
};

export type DeleteMqttConfigurationInput = {
  client: string;
  mqtt_id: string;
};

export type CreateShiftTableInput = {
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type ShiftTable = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type UpdateShiftTableInput = {
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type DeleteShiftTableInput = {
  client: string;
  shift_id: string;
};

export type CreateDashboardInput = {
  client_id: string;
  dashboard_id: string;
};

export type Dashboard = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type UpdateDashboardInput = {
  client_id: string;
  dashboard_id: string;
};

export type DeleteDashboardInput = {
  client_id: string;
  dashboard_id: string;
};

export type CreateNotificationTableInput = {
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type NotificationTable = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type UpdateNotificationTableInput = {
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type DeleteNotificationTableInput = {
  createdDate: string;
  username_client: string;
};

export type CreateLookupTableTicketInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableTicket = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableTicketInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableTicketInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableTaskInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableTask = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableTaskInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableTaskInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableNotificationInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableNotification = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableNotificationInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableNotificationInput = {
  PK: string;
  SK: number;
};

export type CreateTaskTestInput = {
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type TaskTest = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type UpdateTaskTestInput = {
  clientID: string;
  formID?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type DeleteTaskTestInput = {
  clientID: string;
  formID: string;
};

export type CreateLookupTaskTestInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTaskTest = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTaskTestInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTaskTestInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableProblemInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableProblem = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableProblemInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableProblemInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableAssetMapInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableAssetMap = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableAssetMapInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableAssetMapInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableAssetAdditionInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableAssetAddition = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableAssetAdditionInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableAssetAdditionInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableRoleInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableRole = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableRoleInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableRoleInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableDepartmentInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableDepartment = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableDepartmentInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableDepartmentInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableSubDepartmentInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableSubDepartment = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableSubDepartmentInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableSubDepartmentInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableLocationInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableLocation = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableLocationInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableLocationInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableLocationTypeInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableLocationType = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableLocationTypeInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableLocationTypeInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableManufactureInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableManufacture = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableManufactureInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableManufactureInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableVenderInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableVender = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableVenderInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableVenderInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableUserInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableUser = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableUserInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableUserInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTablePriorityInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTablePriority = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTablePriorityInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTablePriorityInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableFormParameterInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableFormParameter = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableFormParameterInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableFormParameterInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableFormTypeInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableFormType = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableFormTypeInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableFormTypeInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableShiftInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableShift = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableShiftInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableShiftInput = {
  PK: string;
  SK: number;
};

export type CreateLookupTableModelInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupTableModel = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableModelInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableModelInput = {
  PK: string;
  SK: number;
};

export type CreateDreamBoardInput = {
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type DreamBoard = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type UpdateDreamBoardInput = {
  PK: string;
  SK: string;
  name?: string | null;
  description?: string | null;
  board_type?: string | null;
  settings?: string | null;
  rdt?: string | null;
  devices?: string | null;
  type?: string | null;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML?: string | null;
  createdTime?: number | null;
  updatedTime?: number | null;
};

export type DeleteDreamBoardInput = {
  PK: string;
  SK: string;
};

export type CreateLookupTableDreamBoardInput = {
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type LookupTableDreamBoard = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type UpdateLookupTableDreamBoardInput = {
  PK: string;
  SK: string;
  Elements?: string | null;
  createdTime?: number | null;
  updatedTime?: number | null;
};

export type DeleteLookupTableDreamBoardInput = {
  PK: string;
  SK: string;
};

export type CreateLookupMasterTicketTableInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type LookupMasterTicketTable = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupMasterTicketTableInput = {
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupMasterTicketTableInput = {
  PK: string;
  SK: number;
};

export type CreateLookupCamcDatesInput = {
  clientid: string;
  id?: string | null;
  metadata?: string | null;
  options?: string | null;
};

export type LookupCamcDates = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type UpdateLookupCamcDatesInput = {
  clientid: string;
  id: string;
  metadata?: string | null;
  options?: string | null;
};

export type DeleteLookupCamcDatesInput = {
  clientid: string;
  id: string;
};

export type CreateClientTableInput = {
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type ClientTable = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type UpdateClientTableInput = {
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type DeleteClientTableInput = {
  clientid: string;
  id: string;
};

export type CreateAuditrailInput = {
  PK: string;
  SK: number;
};

export type Auditrail = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type UpdateAuditrailInput = {
  PK: string;
  SK: number;
};

export type DeleteAuditrailInput = {
  PK: string;
  SK: number;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelLocationTypeFilterInput = {
  client?: ModelStringInput | null;
  location_type_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelLocationTypeFilterInput | null> | null;
  or?: Array<ModelLocationTypeFilterInput | null> | null;
  not?: ModelLocationTypeFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelLocationTypeConnection = {
  __typename: "ModelLocationTypeConnection";
  items: Array<LocationType | null>;
  nextToken?: string | null;
};

export type ModelAssetLocationsPrimaryCompositeKeyConditionInput = {
  eq?: ModelAssetLocationsPrimaryCompositeKeyInput | null;
  le?: ModelAssetLocationsPrimaryCompositeKeyInput | null;
  lt?: ModelAssetLocationsPrimaryCompositeKeyInput | null;
  ge?: ModelAssetLocationsPrimaryCompositeKeyInput | null;
  gt?: ModelAssetLocationsPrimaryCompositeKeyInput | null;
  between?: Array<ModelAssetLocationsPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelAssetLocationsPrimaryCompositeKeyInput | null;
};

export type ModelAssetLocationsPrimaryCompositeKeyInput = {
  client?: string | null;
  location_id?: string | null;
};

export type ModelAssetLocationsFilterInput = {
  client?: ModelStringInput | null;
  asset_id?: ModelIDInput | null;
  location_id?: ModelIDInput | null;
  and?: Array<ModelAssetLocationsFilterInput | null> | null;
  or?: Array<ModelAssetLocationsFilterInput | null> | null;
  not?: ModelAssetLocationsFilterInput | null;
};

export type ModelAssetLocationsConnection = {
  __typename: "ModelAssetLocationsConnection";
  items: Array<AssetLocations | null>;
  nextToken?: string | null;
};

export type ModelLocationFilterInput = {
  client?: ModelStringInput | null;
  location_id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  location_type?: ModelStringInput | null;
  longitude?: ModelFloatInput | null;
  latitude?: ModelFloatInput | null;
  movable_latitude?: ModelIntInput | null;
  movable_longitude?: ModelIntInput | null;
  gmt_diff?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelLocationFilterInput | null> | null;
  or?: Array<ModelLocationFilterInput | null> | null;
  not?: ModelLocationFilterInput | null;
  locationTypeLocationsId?: ModelIDInput | null;
};

export type ModelPriorityFilterInput = {
  client?: ModelStringInput | null;
  priority_id?: ModelIDInput | null;
  description?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelPriorityFilterInput | null> | null;
  or?: Array<ModelPriorityFilterInput | null> | null;
  not?: ModelPriorityFilterInput | null;
};

export type ModelPriorityConnection = {
  __typename: "ModelPriorityConnection";
  items: Array<Priority | null>;
  nextToken?: string | null;
};

export type ModelProblemFilterInput = {
  client?: ModelStringInput | null;
  problem_id?: ModelIDInput | null;
  Origin_Status?: ModelStringInput | null;
  priority?: ModelStringInput | null;
  description?: ModelStringInput | null;
  prob_time?: ModelIntInput | null;
  notif_policy_rem?: ModelIntInput | null;
  escalation_lvl?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  comment_Header?: ModelStringInput | null;
  comment_Suffix?: ModelStringInput | null;
  comment_Prefix?: ModelStringInput | null;
  technician_Comment?: ModelBooleanInput | null;
  impact?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelProblemFilterInput | null> | null;
  or?: Array<ModelProblemFilterInput | null> | null;
  not?: ModelProblemFilterInput | null;
  priorityProblemsId?: ModelIDInput | null;
  items?: ModelIntInput | null;
};

export type ModelAmProblemsPrimaryCompositeKeyConditionInput = {
  eq?: ModelAmProblemsPrimaryCompositeKeyInput | null;
  le?: ModelAmProblemsPrimaryCompositeKeyInput | null;
  lt?: ModelAmProblemsPrimaryCompositeKeyInput | null;
  ge?: ModelAmProblemsPrimaryCompositeKeyInput | null;
  gt?: ModelAmProblemsPrimaryCompositeKeyInput | null;
  between?: Array<ModelAmProblemsPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelAmProblemsPrimaryCompositeKeyInput | null;
};

export type ModelAmProblemsPrimaryCompositeKeyInput = {
  client?: string | null;
  problem_id?: string | null;
};

export type ModelAmProblemsFilterInput = {
  client?: ModelStringInput | null;
  assetmap_id?: ModelIDInput | null;
  problem_id?: ModelIDInput | null;
  and?: Array<ModelAmProblemsFilterInput | null> | null;
  or?: Array<ModelAmProblemsFilterInput | null> | null;
  not?: ModelAmProblemsFilterInput | null;
};

export type ModelAmProblemsConnection = {
  __typename: "ModelAmProblemsConnection";
  items: Array<AmProblems | null>;
  nextToken?: string | null;
};

export type ModelAssetMapFilterInput = {
  client?: ModelStringInput | null;
  assetmap_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelAssetMapFilterInput | null> | null;
  or?: Array<ModelAssetMapFilterInput | null> | null;
  not?: ModelAssetMapFilterInput | null;
};

export type ModelAssetMapConnection = {
  __typename: "ModelAssetMapConnection";
  items: Array<AssetMap | null>;
  nextToken?: string | null;
};

export type ModelManufacturerFilterInput = {
  client?: ModelStringInput | null;
  manufacturer_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelManufacturerFilterInput | null> | null;
  or?: Array<ModelManufacturerFilterInput | null> | null;
  not?: ModelManufacturerFilterInput | null;
};

export type ModelManufacturerConnection = {
  __typename: "ModelManufacturerConnection";
  items: Array<Manufacturer | null>;
  nextToken?: string | null;
};

export type ModelModelFilterInput = {
  client?: ModelStringInput | null;
  model_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelModelFilterInput | null> | null;
  or?: Array<ModelModelFilterInput | null> | null;
  not?: ModelModelFilterInput | null;
};

export type ModelModelConnection = {
  __typename: "ModelModelConnection";
  items: Array<Model | null>;
  nextToken?: string | null;
};

export type ModelDepartmentFilterInput = {
  client?: ModelStringInput | null;
  dept_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelDepartmentFilterInput | null> | null;
  or?: Array<ModelDepartmentFilterInput | null> | null;
  not?: ModelDepartmentFilterInput | null;
};

export type ModelDepartmentConnection = {
  __typename: "ModelDepartmentConnection";
  items: Array<Department | null>;
  nextToken?: string | null;
};

export type ModelSubDepartmentFilterInput = {
  client?: ModelStringInput | null;
  sub_dept_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelSubDepartmentFilterInput | null> | null;
  or?: Array<ModelSubDepartmentFilterInput | null> | null;
  not?: ModelSubDepartmentFilterInput | null;
};

export type ModelSubDepartmentConnection = {
  __typename: "ModelSubDepartmentConnection";
  items: Array<SubDepartment | null>;
  nextToken?: string | null;
};

export type ModelVendorCompanyFilterInput = {
  client?: ModelStringInput | null;
  vendor_id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  Description?: ModelStringInput | null;
  email?: ModelStringInput | null;
  ph_no?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelVendorCompanyFilterInput | null> | null;
  or?: Array<ModelVendorCompanyFilterInput | null> | null;
  not?: ModelVendorCompanyFilterInput | null;
};

export type ModelVendorCompanyConnection = {
  __typename: "ModelVendorCompanyConnection";
  items: Array<VendorCompany | null>;
  nextToken?: string | null;
};

export type ModelRoleFilterInput = {
  client?: ModelStringInput | null;
  role_id?: ModelIDInput | null;
  Description?: ModelStringInput | null;
  time_mult?: ModelFloatInput | null;
  Metadata?: ModelStringInput | null;
  and?: Array<ModelRoleFilterInput | null> | null;
  or?: Array<ModelRoleFilterInput | null> | null;
  not?: ModelRoleFilterInput | null;
};

export type ModelRoleConnection = {
  __typename: "ModelRoleConnection";
  items: Array<Role | null>;
  nextToken?: string | null;
};

export type ModelUserLocationsPrimaryCompositeKeyConditionInput = {
  eq?: ModelUserLocationsPrimaryCompositeKeyInput | null;
  le?: ModelUserLocationsPrimaryCompositeKeyInput | null;
  lt?: ModelUserLocationsPrimaryCompositeKeyInput | null;
  ge?: ModelUserLocationsPrimaryCompositeKeyInput | null;
  gt?: ModelUserLocationsPrimaryCompositeKeyInput | null;
  between?: Array<ModelUserLocationsPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelUserLocationsPrimaryCompositeKeyInput | null;
};

export type ModelUserLocationsPrimaryCompositeKeyInput = {
  client?: string | null;
  location_id?: string | null;
};

export type ModelUserLocationsFilterInput = {
  client?: ModelStringInput | null;
  user_id?: ModelStringInput | null;
  location_id?: ModelStringInput | null;
  and?: Array<ModelUserLocationsFilterInput | null> | null;
  or?: Array<ModelUserLocationsFilterInput | null> | null;
  not?: ModelUserLocationsFilterInput | null;
};

export type ModelUserLocationsConnection = {
  __typename: "ModelUserLocationsConnection";
  items: Array<UserLocations | null>;
  nextToken?: string | null;
};

export type ModelAssetUserNotifyPrimaryCompositeKeyConditionInput = {
  eq?: ModelAssetUserNotifyPrimaryCompositeKeyInput | null;
  le?: ModelAssetUserNotifyPrimaryCompositeKeyInput | null;
  lt?: ModelAssetUserNotifyPrimaryCompositeKeyInput | null;
  ge?: ModelAssetUserNotifyPrimaryCompositeKeyInput | null;
  gt?: ModelAssetUserNotifyPrimaryCompositeKeyInput | null;
  between?: Array<ModelAssetUserNotifyPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelAssetUserNotifyPrimaryCompositeKeyInput | null;
};

export type ModelAssetUserNotifyPrimaryCompositeKeyInput = {
  client?: string | null;
  user_id?: string | null;
};

export type ModelAssetUserNotifyFilterInput = {
  client?: ModelStringInput | null;
  user_id?: ModelIDInput | null;
  asset_id?: ModelIDInput | null;
  and?: Array<ModelAssetUserNotifyFilterInput | null> | null;
  or?: Array<ModelAssetUserNotifyFilterInput | null> | null;
  not?: ModelAssetUserNotifyFilterInput | null;
};

export type ModelAssetUserNotifyConnection = {
  __typename: "ModelAssetUserNotifyConnection";
  items: Array<AssetUserNotify | null>;
  nextToken?: string | null;
};

export type ModelAssetOwnerPrimaryCompositeKeyConditionInput = {
  eq?: ModelAssetOwnerPrimaryCompositeKeyInput | null;
  le?: ModelAssetOwnerPrimaryCompositeKeyInput | null;
  lt?: ModelAssetOwnerPrimaryCompositeKeyInput | null;
  ge?: ModelAssetOwnerPrimaryCompositeKeyInput | null;
  gt?: ModelAssetOwnerPrimaryCompositeKeyInput | null;
  between?: Array<ModelAssetOwnerPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelAssetOwnerPrimaryCompositeKeyInput | null;
};

export type ModelAssetOwnerPrimaryCompositeKeyInput = {
  client?: string | null;
  user_id?: string | null;
};

export type ModelAssetOwnerFilterInput = {
  client?: ModelStringInput | null;
  user_id?: ModelIDInput | null;
  asset_id?: ModelIDInput | null;
  and?: Array<ModelAssetOwnerFilterInput | null> | null;
  or?: Array<ModelAssetOwnerFilterInput | null> | null;
  not?: ModelAssetOwnerFilterInput | null;
};

export type ModelAssetOwnerConnection = {
  __typename: "ModelAssetOwnerConnection";
  items: Array<AssetOwner | null>;
  nextToken?: string | null;
};

export type ModelUserFilterInput = {
  client?: ModelStringInput | null;
  user_id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  username?: ModelStringInput | null;
  Description?: ModelStringInput | null;
  Metadata?: ModelStringInput | null;
  mobile?: ModelStringInput | null;
  email?: ModelStringInput | null;
  user_type?: ModelStringInput | null;
  mobile_privacy?: ModelBooleanInput | null;
  alert_email?: ModelBooleanInput | null;
  alert_sms?: ModelBooleanInput | null;
  esc_email?: ModelBooleanInput | null;
  esc_sms?: ModelBooleanInput | null;
  role?: ModelStringInput | null;
  department?: ModelStringInput | null;
  sub_department?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
  departmentUsersId?: ModelIDInput | null;
  subDepartmentUsersId?: ModelIDInput | null;
  roleUsersId?: ModelIDInput | null;
  userReporting_toId?: ModelIDInput | null;
  formcreate?: ModelBooleanInput | null;
  formread?: ModelBooleanInput | null;
  formupdate?: ModelBooleanInput | null;
  formdelete?: ModelBooleanInput | null;
  imageurl?: ModelStringInput | null;
  formuserpermission?: ModelStringInput | null;
  notification_token?: ModelStringInput | null;
  telegramID?: ModelStringInput | null;
  telegram?: ModelBooleanInput | null;
  push_notification?: ModelBooleanInput | null;
  sms?: ModelBooleanInput | null;
  permission?: ModelStringInput | null;
  chat_Bot?: ModelStringInput | null;
  mobile_permission?: ModelStringInput | null;
  man_per_hour_cost?: ModelStringInput | null;
};

export type ModelAssetAdditionPrimaryCompositeKeyConditionInput = {
  eq?: ModelAssetAdditionPrimaryCompositeKeyInput | null;
  le?: ModelAssetAdditionPrimaryCompositeKeyInput | null;
  lt?: ModelAssetAdditionPrimaryCompositeKeyInput | null;
  ge?: ModelAssetAdditionPrimaryCompositeKeyInput | null;
  gt?: ModelAssetAdditionPrimaryCompositeKeyInput | null;
  between?: Array<ModelAssetAdditionPrimaryCompositeKeyInput | null> | null;
  beginsWith?: ModelAssetAdditionPrimaryCompositeKeyInput | null;
};

export type ModelAssetAdditionPrimaryCompositeKeyInput = {
  client?: string | null;
  asset_id?: string | null;
};

export type ModelAssetAdditionFilterInput = {
  client?: ModelStringInput | null;
  tag_id?: ModelIDInput | null;
  asset_map?: ModelStringInput | null;
  asset_id?: ModelIDInput | null;
  model?: ModelStringInput | null;
  user_notify_id?: ModelStringInput | null;
  tech_multi_id?: ModelStringInput | null;
  tech_id?: ModelStringInput | null;
  sub_department?: ModelStringInput | null;
  department?: ModelStringInput | null;
  vendor_company?: ModelStringInput | null;
  manufacturer?: ModelStringInput | null;
  location?: ModelStringInput | null;
  next_service?: ModelStringInput | null;
  last_service?: ModelStringInput | null;
  installation_date?: ModelStringInput | null;
  amc_expiry?: ModelStringInput | null;
  group?: ModelStringInput | null;
  bought_price?: ModelFloatInput | null;
  current_price?: ModelFloatInput | null;
  asset_type?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  technician?: ModelStringInput | null;
  and?: Array<ModelAssetAdditionFilterInput | null> | null;
  or?: Array<ModelAssetAdditionFilterInput | null> | null;
  not?: ModelAssetAdditionFilterInput | null;
  locationAssetsId?: ModelIDInput | null;
  assetMapAssetsId?: ModelIDInput | null;
  manufacturerAssetsId?: ModelIDInput | null;
  modelAssetsId?: ModelIDInput | null;
  departmentAssetsId?: ModelIDInput | null;
  subDepartmentAssetsId?: ModelIDInput | null;
  vendorCompanyAssetsId?: ModelIDInput | null;
  formpermission?: ModelStringInput | null;
};

export type ModelTicketsFilterInput = {
  client?: ModelStringInput | null;
  ticket_id?: ModelIDInput | null;
  tag_id?: ModelStringInput | null;
  asset_add?: ModelStringInput | null;
  asset_type_id?: ModelStringInput | null;
  problem_id?: ModelStringInput | null;
  problem?: ModelStringInput | null;
  user?: ModelStringInput | null;
  current_status?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  current_esc_lvl?: ModelIntInput | null;
  notification_sent_flag?: ModelBooleanInput | null;
  esc_users?: ModelStringInput | null;
  total_time?: ModelFloatInput | null;
  Problem_time?: ModelFloatInput | null;
  max_esc_reached?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  max_esc_lvl?: ModelIntInput | null;
  technician_id?: ModelStringInput | null;
  mutli_assign?: ModelStringInput | null;
  and?: Array<ModelTicketsFilterInput | null> | null;
  or?: Array<ModelTicketsFilterInput | null> | null;
  not?: ModelTicketsFilterInput | null;
  userTicketsId?: ModelIDInput | null;
  assetAdditionTicketsId?: ModelIDInput | null;
  moveble_latitude?: ModelFloatInput | null;
  moveble_longitude?: ModelFloatInput | null;
  other?: ModelStringInput | null;
  rating?: ModelIntInput | null;
  otp?: ModelIntInput | null;
};

export type TableSavedTagsFilterInput = {
  client?: TableStringFilterInput | null;
  userIdTagId?: TableStringFilterInput | null;
  userId?: TableStringFilterInput | null;
  tagId?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
};

export type TableStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  nin?: Array<string | null> | null;
};

export type SavedTagsConnection = {
  __typename: "SavedTagsConnection";
  items?: Array<SavedTags | null> | null;
  nextToken?: string | null;
};

export type TableTicketImagesFilterInput = {
  client?: TableStringFilterInput | null;
  ticket_id?: TableStringFilterInput | null;
  images?: TableStringFilterInput | null;
};

export type TicketImagesConnection = {
  __typename: "TicketImagesConnection";
  items?: Array<TicketImages | null> | null;
  nextToken?: string | null;
};

export type TableFormParameterFilterInput = {
  client?: TableStringFilterInput | null;
  parameterID?: TableStringFilterInput | null;
  other?: TableStringFilterInput | null;
  formdefault?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  updatedAt?: TableStringFilterInput | null;
  parameterDescription?: TableStringFilterInput | null;
  parameterLable?: TableStringFilterInput | null;
  parameterType?: TableStringFilterInput | null;
  placeholder?: TableStringFilterInput | null;
  require?: TableBooleanFilterInput | null;
  value_1?: TableIntFilterInput | null;
  value_2?: TableIntFilterInput | null;
  list?: TableStringFilterInput | null;
  name?: TableStringFilterInput | null;
};

export type TableBooleanFilterInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type TableIntFilterInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  contains?: number | null;
  notContains?: number | null;
  between?: Array<number | null> | null;
};

export type FormParameterConnection = {
  __typename: "FormParameterConnection";
  items?: Array<FormParameter | null> | null;
  nextToken?: string | null;
};

export type TableFormTypeFilterInput = {
  client?: TableStringFilterInput | null;
  typeID?: TableStringFilterInput | null;
  typeLable?: TableStringFilterInput | null;
  typeDescription?: TableStringFilterInput | null;
  emailSubject?: TableStringFilterInput | null;
  emailHeader?: TableStringFilterInput | null;
  emailBody?: TableStringFilterInput | null;
  emailFooter?: TableStringFilterInput | null;
  other?: TableStringFilterInput | null;
  emailEnable?: TableBooleanFilterInput | null;
  emailRecipent?: TableStringFilterInput | null;
  typeparameterID?: TableStringFilterInput | null;
  formIDvisible?: TableBooleanFilterInput | null;
  lablevisible?: TableBooleanFilterInput | null;
  descriptionvisible?: TableBooleanFilterInput | null;
  updatedAt?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  mqtt_id?: TableStringFilterInput | null;
  iot_connect?: TableBooleanFilterInput | null;
  reserved?: TableBooleanFilterInput | null;
  block_time?: TableBooleanFilterInput | null;
  scan?: TableBooleanFilterInput | null;
};

export type FormTypeConnection = {
  __typename: "FormTypeConnection";
  items?: Array<FormType | null> | null;
  nextToken?: string | null;
};

export type TableTaskFilterInput = {
  clientID?: TableStringFilterInput | null;
  formID?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  updatedAt?: TableStringFilterInput | null;
  created_Username?: TableStringFilterInput | null;
  updated_Username?: TableStringFilterInput | null;
  typeDes?: TableStringFilterInput | null;
  typelable?: TableStringFilterInput | null;
  parameters_data?: TableStringFilterInput | null;
  formDescription_visibilty?: TableStringFilterInput | null;
  formID_visibility?: TableStringFilterInput | null;
  formlable_visibility?: TableStringFilterInput | null;
  formTypeID?: TableStringFilterInput | null;
  label?: TableStringFilterInput | null;
};

export type TaskConnection = {
  __typename: "TaskConnection";
  items?: Array<Task | null> | null;
  nextToken?: string | null;
};

export type TableMqttConfigurationFilterInput = {
  client?: TableStringFilterInput | null;
  mqtt_id?: TableStringFilterInput | null;
};

export type MqttConfigurationConnection = {
  __typename: "MqttConfigurationConnection";
  items?: Array<MqttConfiguration | null> | null;
  nextToken?: string | null;
};

export type TableShiftTableFilterInput = {
  client?: TableStringFilterInput | null;
  shift_id?: TableStringFilterInput | null;
  form_time?: TableStringFilterInput | null;
  to_time?: TableStringFilterInput | null;
  shifts?: TableStringFilterInput | null;
};

export type ShiftTableConnection = {
  __typename: "ShiftTableConnection";
  items?: Array<ShiftTable | null> | null;
  nextToken?: string | null;
};

export type TableDashboardFilterInput = {
  client_id?: TableStringFilterInput | null;
  dashboard_id?: TableStringFilterInput | null;
};

export type DashboardConnection = {
  __typename: "DashboardConnection";
  items?: Array<Dashboard | null> | null;
  nextToken?: string | null;
};

export type TableNotificationTableFilterInput = {
  createdDate?: TableStringFilterInput | null;
  username_client?: TableStringFilterInput | null;
  main_notification_seen_flag?: TableBooleanFilterInput | null;
  message?: TableStringFilterInput | null;
  type?: TableStringFilterInput | null;
  ticket_id?: TableStringFilterInput | null;
  icon?: TableStringFilterInput | null;
  color?: TableStringFilterInput | null;
  task_id?: TableStringFilterInput | null;
  Problem?: TableStringFilterInput | null;
  formtype?: TableStringFilterInput | null;
  created_time?: TableStringFilterInput | null;
  update_time?: TableStringFilterInput | null;
  seen_flag?: TableBooleanFilterInput | null;
  ticket_info?: TableStringFilterInput | null;
  task_info?: TableStringFilterInput | null;
};

export type NotificationTableConnection = {
  __typename: "NotificationTableConnection";
  items?: Array<NotificationTable | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableTicketFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableTicketConnection = {
  __typename: "LookupTableTicketConnection";
  items?: Array<LookupTableTicket | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableTaskFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableTaskConnection = {
  __typename: "LookupTableTaskConnection";
  items?: Array<LookupTableTask | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableNotificationFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableNotificationConnection = {
  __typename: "LookupTableNotificationConnection";
  items?: Array<LookupTableNotification | null> | null;
  nextToken?: string | null;
};

export type TableTaskTestFilterInput = {
  clientID?: TableStringFilterInput | null;
  formID?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  updatedAt?: TableStringFilterInput | null;
  created_Username?: TableStringFilterInput | null;
  updated_Username?: TableStringFilterInput | null;
  typeDes?: TableStringFilterInput | null;
  typelable?: TableStringFilterInput | null;
  parameters_data?: TableStringFilterInput | null;
  formDescription_visibilty?: TableStringFilterInput | null;
  formID_visibility?: TableStringFilterInput | null;
  formlable_visibility?: TableStringFilterInput | null;
  formTypeID?: TableStringFilterInput | null;
  iotDateTime?: TableStringFilterInput | null;
  startDateTime?: TableStringFilterInput | null;
  endDateTime?: TableStringFilterInput | null;
  blockDateTime?: TableStringFilterInput | null;
  label?: TableStringFilterInput | null;
  device?: TableStringFilterInput | null;
};

export type TaskTestConnection = {
  __typename: "TaskTestConnection";
  items?: Array<TaskTest | null> | null;
  nextToken?: string | null;
};

export type TableLookupTaskTestFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTaskTestConnection = {
  __typename: "LookupTaskTestConnection";
  items?: Array<LookupTaskTest | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableProblemFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableProblemConnection = {
  __typename: "LookupTableProblemConnection";
  items?: Array<LookupTableProblem | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableAssetMapFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableAssetMapConnection = {
  __typename: "LookupTableAssetMapConnection";
  items?: Array<LookupTableAssetMap | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableAssetAdditionFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableAssetAdditionConnection = {
  __typename: "LookupTableAssetAdditionConnection";
  items?: Array<LookupTableAssetAddition | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableRoleFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableRoleConnection = {
  __typename: "LookupTableRoleConnection";
  items?: Array<LookupTableRole | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableDepartmentFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableDepartmentConnection = {
  __typename: "LookupTableDepartmentConnection";
  items?: Array<LookupTableDepartment | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableSubDepartmentFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableSubDepartmentConnection = {
  __typename: "LookupTableSubDepartmentConnection";
  items?: Array<LookupTableSubDepartment | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableLocationFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableLocationConnection = {
  __typename: "LookupTableLocationConnection";
  items?: Array<LookupTableLocation | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableLocationTypeFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableLocationTypeConnection = {
  __typename: "LookupTableLocationTypeConnection";
  items?: Array<LookupTableLocationType | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableManufactureFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableManufactureConnection = {
  __typename: "LookupTableManufactureConnection";
  items?: Array<LookupTableManufacture | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableVenderFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableVenderConnection = {
  __typename: "LookupTableVenderConnection";
  items?: Array<LookupTableVender | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableUserFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableUserConnection = {
  __typename: "LookupTableUserConnection";
  items?: Array<LookupTableUser | null> | null;
  nextToken?: string | null;
};

export type TableLookupTablePriorityFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTablePriorityConnection = {
  __typename: "LookupTablePriorityConnection";
  items?: Array<LookupTablePriority | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableFormParameterFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableFormParameterConnection = {
  __typename: "LookupTableFormParameterConnection";
  items?: Array<LookupTableFormParameter | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableFormTypeFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableFormTypeConnection = {
  __typename: "LookupTableFormTypeConnection";
  items?: Array<LookupTableFormType | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableShiftFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableShiftConnection = {
  __typename: "LookupTableShiftConnection";
  items?: Array<LookupTableShift | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableModelFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupTableModelConnection = {
  __typename: "LookupTableModelConnection";
  items?: Array<LookupTableModel | null> | null;
  nextToken?: string | null;
};

export type TableDreamBoardFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableStringFilterInput | null;
  name?: TableStringFilterInput | null;
  description?: TableStringFilterInput | null;
  board_type?: TableStringFilterInput | null;
  settings?: TableStringFilterInput | null;
  rdt?: TableStringFilterInput | null;
  devices?: TableStringFilterInput | null;
  type?: TableStringFilterInput | null;
  onLoadJS?: TableStringFilterInput | null;
  onUpdateJS?: TableStringFilterInput | null;
  HTML?: TableStringFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
};

export type DreamBoardConnection = {
  __typename: "DreamBoardConnection";
  items?: Array<DreamBoard | null> | null;
  nextToken?: string | null;
};

export type TableLookupTableDreamBoardFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableStringFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
};

export type LookupTableDreamBoardConnection = {
  __typename: "LookupTableDreamBoardConnection";
  Elements?: Array<LookupTableDreamBoard | null> | null;
  nextToken?: string | null;
};

export type TableLookupMasterTicketTableFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  createdTime?: TableIntFilterInput | null;
  updatedTime?: TableIntFilterInput | null;
  Items?: TableStringFilterInput | null;
};

export type LookupMasterTicketTableConnection = {
  __typename: "LookupMasterTicketTableConnection";
  items?: Array<LookupMasterTicketTable | null> | null;
  nextToken?: string | null;
};

export type TableLookupCamcDatesFilterInput = {
  clientid?: TableStringFilterInput | null;
  id?: TableStringFilterInput | null;
};

export type LookupCamcDatesConnection = {
  __typename: "LookupCamcDatesConnection";
  items?: Array<LookupCamcDates | null> | null;
  nextToken?: string | null;
};

export type TableClientTableFilterInput = {
  clientid?: TableStringFilterInput | null;
  id?: TableStringFilterInput | null;
};

export type ClientTableConnection = {
  __typename: "ClientTableConnection";
  items?: Array<ClientTable | null> | null;
  nextToken?: string | null;
};

export type TableAuditrailFilterInput = {
  PK?: TableStringFilterInput | null;
  SK?: TableIntFilterInput | null;
  module?: TableStringFilterInput | null;
  action?: TableStringFilterInput | null;
  description?: TableStringFilterInput | null;
};

export type AuditrailConnection = {
  __typename: "AuditrailConnection";
  items?: Array<Auditrail | null> | null;
  nextToken?: string | null;
};

export type CreateLocationTypeMutation = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateLocationTypeMutation = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteLocationTypeMutation = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAssetLocationsMutation = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetLocationsMutation = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAssetLocationsMutation = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateLocationMutation = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type UpdateLocationMutation = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type DeleteLocationMutation = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type CreatePriorityMutation = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePriorityMutation = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeletePriorityMutation = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateProblemMutation = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type UpdateProblemMutation = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type DeleteProblemMutation = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type CreateAmProblemsMutation = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAmProblemsMutation = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAmProblemsMutation = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateAssetMapMutation = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetMapMutation = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAssetMapMutation = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateManufacturerMutation = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateManufacturerMutation = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteManufacturerMutation = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateModelMutation = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateModelMutation = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteModelMutation = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateDepartmentMutation = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDepartmentMutation = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDepartmentMutation = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSubDepartmentMutation = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSubDepartmentMutation = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSubDepartmentMutation = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateVendorCompanyMutation = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVendorCompanyMutation = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVendorCompanyMutation = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRoleMutation = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRoleMutation = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRoleMutation = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserLocationsMutation = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserLocationsMutation = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserLocationsMutation = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateAssetUserNotifyMutation = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetUserNotifyMutation = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAssetUserNotifyMutation = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateAssetOwnerMutation = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssetOwnerMutation = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAssetOwnerMutation = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type CreateAssetAdditionMutation = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type UpdateAssetAdditionMutation = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type DeleteAssetAdditionMutation = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type CreateTicketsMutation = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type UpdateTicketsMutation = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type DeleteTicketsMutation = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type CreateSavedTagsMutation = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type UpdateSavedTagsMutation = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type DeleteSavedTagsMutation = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type CreateTicketImagesMutation = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type UpdateTicketImagesMutation = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type DeleteTicketImagesMutation = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type CreateFormParameterMutation = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type UpdateFormParameterMutation = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type DeleteFormParameterMutation = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type CreateFormTypeMutation = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type UpdateFormTypeMutation = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type DeleteFormTypeMutation = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type CreateTaskMutation = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type UpdateTaskMutation = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type DeleteTaskMutation = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type CreateMqttConfigurationMutation = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type UpdateMqttConfigurationMutation = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type DeleteMqttConfigurationMutation = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type CreateShiftTableMutation = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type UpdateShiftTableMutation = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type DeleteShiftTableMutation = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type CreateDashboardMutation = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type UpdateDashboardMutation = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type DeleteDashboardMutation = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type CreateNotificationTableMutation = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type UpdateNotificationTableMutation = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type DeleteNotificationTableMutation = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type CreateLookupTableTicketMutation = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableTicketMutation = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableTicketMutation = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableTaskMutation = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableTaskMutation = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableTaskMutation = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableNotificationMutation = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableNotificationMutation = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableNotificationMutation = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateTaskTestMutation = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type UpdateTaskTestMutation = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type DeleteTaskTestMutation = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type CreateLookupTaskTestMutation = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTaskTestMutation = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTaskTestMutation = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableProblemMutation = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableProblemMutation = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableProblemMutation = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableAssetMapMutation = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableAssetMapMutation = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableAssetMapMutation = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableAssetAdditionMutation = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableAssetAdditionMutation = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableAssetAdditionMutation = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableRoleMutation = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableRoleMutation = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableRoleMutation = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableDepartmentMutation = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableDepartmentMutation = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableDepartmentMutation = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableSubDepartmentMutation = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableSubDepartmentMutation = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableSubDepartmentMutation = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableLocationMutation = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableLocationMutation = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableLocationMutation = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableLocationTypeMutation = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableLocationTypeMutation = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableLocationTypeMutation = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableManufactureMutation = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableManufactureMutation = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableManufactureMutation = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableVenderMutation = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableVenderMutation = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableVenderMutation = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableUserMutation = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableUserMutation = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableUserMutation = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTablePriorityMutation = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTablePriorityMutation = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTablePriorityMutation = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableFormParameterMutation = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableFormParameterMutation = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableFormParameterMutation = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableFormTypeMutation = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableFormTypeMutation = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableFormTypeMutation = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableShiftMutation = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableShiftMutation = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableShiftMutation = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupTableModelMutation = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupTableModelMutation = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupTableModelMutation = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateDreamBoardMutation = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type UpdateDreamBoardMutation = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type DeleteDreamBoardMutation = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type CreateLookupTableDreamBoardMutation = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type UpdateLookupTableDreamBoardMutation = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type DeleteLookupTableDreamBoardMutation = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type CreateLookupMasterTicketTableMutation = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type UpdateLookupMasterTicketTableMutation = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type DeleteLookupMasterTicketTableMutation = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type CreateLookupCamcDatesMutation = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type UpdateLookupCamcDatesMutation = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type DeleteLookupCamcDatesMutation = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type CreateClientTableMutation = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type UpdateClientTableMutation = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type DeleteClientTableMutation = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type CreateAuditrailMutation = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type UpdateAuditrailMutation = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type DeleteAuditrailMutation = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type GetLocationTypeQuery = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListLocationTypesQuery = {
  __typename: "ModelLocationTypeConnection";
  items: Array<{
    __typename: "LocationType";
    client: string;
    location_type_id: string;
    Description: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssetLocationsQuery = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAssetLocationsQuery = {
  __typename: "ModelAssetLocationsConnection";
  items: Array<{
    __typename: "AssetLocations";
    client: string;
    asset_id: string;
    location_id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetLocationQuery = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type ListLocationsQuery = {
  __typename: "ModelLocationConnection";
  items: Array<{
    __typename: "Location";
    client: string;
    location_id: string;
    name: string;
    location_type: string;
    longitude: number;
    latitude: number;
    movable_latitude?: number | null;
    movable_longitude?: number | null;
    gmt_diff: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
    locationTypeLocationsId: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPriorityQuery = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListPrioritiesQuery = {
  __typename: "ModelPriorityConnection";
  items: Array<{
    __typename: "Priority";
    client: string;
    priority_id: string;
    description: string;
    metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetProblemQuery = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type ListProblemsQuery = {
  __typename: "ModelProblemConnection";
  items: Array<{
    __typename: "Problem";
    client: string;
    problem_id: string;
    Origin_Status: string;
    priority: string;
    description: string;
    detail: string;
    prob_time: number;
    notif_policy_rem: number;
    escalation_lvl: string;
    comment: string;
    comment_Header: string;
    comment_Suffix: string;
    comment_Prefix: string;
    technician_Comment: boolean;
    impact: string;
    createdAt: string;
    updatedAt: string;
    metadata?: string | null;
    priorityProblemsId: string;
    items?: Array<number | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type GetAmProblemsQuery = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAmProblemsQuery = {
  __typename: "ModelAmProblemsConnection";
  items: Array<{
    __typename: "AmProblems";
    client: string;
    assetmap_id: string;
    problem_id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssetMapQuery = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAssetMapsQuery = {
  __typename: "ModelAssetMapConnection";
  items: Array<{
    __typename: "AssetMap";
    client: string;
    assetmap_id: string;
    problems?: Array<string | null> | null;
    Description: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetManufacturerQuery = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListManufacturersQuery = {
  __typename: "ModelManufacturerConnection";
  items: Array<{
    __typename: "Manufacturer";
    client: string;
    manufacturer_id: string;
    Description: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetModelQuery = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListModelsQuery = {
  __typename: "ModelModelConnection";
  items: Array<{
    __typename: "Model";
    client: string;
    model_id: string;
    Description: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDepartmentQuery = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListDepartmentsQuery = {
  __typename: "ModelDepartmentConnection";
  items: Array<{
    __typename: "Department";
    client: string;
    dept_id: string;
    Description: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSubDepartmentQuery = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSubDepartmentsQuery = {
  __typename: "ModelSubDepartmentConnection";
  items: Array<{
    __typename: "SubDepartment";
    client: string;
    sub_dept_id: string;
    Description: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVendorCompanyQuery = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListVendorCompaniesQuery = {
  __typename: "ModelVendorCompanyConnection";
  items: Array<{
    __typename: "VendorCompany";
    client: string;
    vendor_id: string;
    name: string;
    Description: string;
    email: string;
    ph_no: string;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRoleQuery = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRolesQuery = {
  __typename: "ModelRoleConnection";
  items: Array<{
    __typename: "Role";
    client: string;
    role_id: string;
    Description: string;
    time_mult: number;
    Metadata?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserLocationsQuery = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListUserLocationsQuery = {
  __typename: "ModelUserLocationsConnection";
  items: Array<{
    __typename: "UserLocations";
    client: string;
    user_id: string;
    location_id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssetUserNotifyQuery = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAssetUserNotifiesQuery = {
  __typename: "ModelAssetUserNotifyConnection";
  items: Array<{
    __typename: "AssetUserNotify";
    client: string;
    user_id: string;
    asset_id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssetOwnerQuery = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAssetOwnersQuery = {
  __typename: "ModelAssetOwnerConnection";
  items: Array<{
    __typename: "AssetOwner";
    client: string;
    user_id: string;
    asset_id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    client: string;
    user_id: string;
    name: string;
    username: string;
    Description: string;
    Metadata?: string | null;
    mobile: string;
    email: string;
    user_type: string;
    mobile_privacy: boolean;
    alert_email: boolean;
    alert_sms: boolean;
    esc_email: boolean;
    esc_sms: boolean;
    role: string;
    department: string;
    sub_department: string;
    createdAt: string;
    updatedAt: string;
    departmentUsersId: string;
    subDepartmentUsersId: string;
    roleUsersId: string;
    userReporting_toId: string;
    formcreate: boolean;
    formread: boolean;
    formupdate: boolean;
    formdelete: boolean;
    imageurl: string;
    formuserpermission: Array<string | null>;
    notification_token: string;
    telegramID: string;
    telegram: boolean;
    push_notification: boolean;
    sms: boolean;
    permission: Array<string | null>;
    chat_Bot?: string | null;
    mobile_permission: Array<string | null>;
    man_per_hour_cost: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssetAdditionQuery = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type ListAssetAdditionsQuery = {
  __typename: "ModelAssetAdditionConnection";
  items: Array<{
    __typename: "AssetAddition";
    client: string;
    tag_id: string;
    asset_map: string;
    user_notify_id: Array<string | null>;
    user_notify: Array<string | null>;
    tech_multi_id: Array<string | null>;
    multiple_technician: Array<string | null>;
    asset_id: string;
    model: string;
    sub_department: string;
    department: string;
    vendor_company: string;
    manufacturer: string;
    location: string;
    next_service: string;
    last_service: string;
    installation_date: string;
    amc_expiry: string;
    group: string;
    bought_price: number;
    current_price: number;
    asset_type: string;
    metadata?: string | null;
    tech_id: string;
    technician: string;
    createdAt: string;
    updatedAt: string;
    locationAssetsId: string;
    assetMapAssetsId: string;
    manufacturerAssetsId: string;
    modelAssetsId: string;
    departmentAssetsId: string;
    subDepartmentAssetsId: string;
    vendorCompanyAssetsId: string;
    formpermission?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTicketsQuery = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type ListTicketsQuery = {
  __typename: "ModelTicketsConnection";
  items: Array<{
    __typename: "Tickets";
    client: string;
    ticket_id: string;
    tag_id: string;
    asset_add: string;
    asset_type_id: string;
    problem_id: string;
    problem: string;
    user: string;
    current_status: string;
    metadata?: string | null;
    current_esc_lvl: number;
    comment?: Array<string | null> | null;
    notification_sent_flag: boolean;
    esc_users: Array<string | null>;
    total_time: number;
    Problem_time: number;
    max_esc_reached: boolean;
    createdAt: string;
    max_esc_lvl: number;
    technician_id: string;
    mutli_assign: Array<string | null>;
    updatedAt: string;
    progress_update?: string | null;
    solved_update?: string | null;
    closed_update?: string | null;
    userTicketsId: string;
    assetAdditionTicketsId: string;
    moveble_latitude?: number | null;
    moveble_longitude?: number | null;
    status_progress?: string | null;
    status_solved?: string | null;
    status_closed?: string | null;
    other?: string | null;
    rating?: number | null;
    otp?: number | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSavedTagsQuery = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type ListSavedTagsQuery = {
  __typename: "SavedTagsConnection";
  items?: Array<{
    __typename: "SavedTags";
    client: string;
    userIdTagId: string;
    userId: string;
    tagId: string;
    createdAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTicketImagesQuery = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type ListTicketImagesQuery = {
  __typename: "TicketImagesConnection";
  items?: Array<{
    __typename: "TicketImages";
    client: string;
    ticket_id: string;
    images?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetFormParameterQuery = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type ListFormParametersQuery = {
  __typename: "FormParameterConnection";
  items?: Array<{
    __typename: "FormParameter";
    client: string;
    parameterID: string;
    other: string;
    formdefault: string;
    createdAt: string;
    updatedAt: string;
    parameterDescription: string;
    parameterLable: string;
    parameterType: string;
    placeholder: string;
    require: boolean;
    value_1: number;
    value_2: number;
    list: string;
    name?: Array<string | null> | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetFormTypeQuery = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type ListFormTypesQuery = {
  __typename: "FormTypeConnection";
  items?: Array<{
    __typename: "FormType";
    client: string;
    typeID: string;
    typeLable: string;
    typeDescription: string;
    emailSubject: string;
    emailHeader: string;
    emailBody: string;
    emailFooter: string;
    other: string;
    emailEnable: boolean;
    emailRecipent?: Array<string | null> | null;
    typeparameterID?: Array<string | null> | null;
    formIDvisible: boolean;
    lablevisible: boolean;
    descriptionvisible: boolean;
    updatedAt: string;
    createdAt: string;
    mqtt_id: string;
    iot_connect: boolean;
    reserved: boolean;
    block_time: boolean;
    scan: boolean;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTaskQuery = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type ListTasksQuery = {
  __typename: "TaskConnection";
  items?: Array<{
    __typename: "Task";
    clientID: string;
    formID: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    created_Username?: string | null;
    updated_Username?: string | null;
    typeDes?: string | null;
    typelable?: string | null;
    parameters_data?: string | null;
    formDescription_visibilty?: string | null;
    formID_visibility?: string | null;
    formlable_visibility?: string | null;
    formTypeID?: string | null;
    label?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMqttConfigurationQuery = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type ListMqttConfigurationsQuery = {
  __typename: "MqttConfigurationConnection";
  items?: Array<{
    __typename: "MqttConfiguration";
    client: string;
    mqtt_id: string;
    name: string;
    host: string;
    url: string;
    port: string;
    username: string;
    password: string;
    topic: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetShiftTableQuery = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type ListShiftTablesQuery = {
  __typename: "ShiftTableConnection";
  items?: Array<{
    __typename: "ShiftTable";
    client: string;
    shift_id: string;
    form_time: string;
    to_time: string;
    shifts: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetDashboardQuery = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type ListDashboardsQuery = {
  __typename: "DashboardConnection";
  items?: Array<{
    __typename: "Dashboard";
    client_id: string;
    dashboard_id: string;
    tile?: string | null;
    latest_10_tickets?: string | null;
    latest_10_user_ratings?: string | null;
    upcoming_event?: string | null;
    Graph_data?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetNotificationTableQuery = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type ListNotificationTablesQuery = {
  __typename: "NotificationTableConnection";
  items?: Array<{
    __typename: "NotificationTable";
    createdDate: string;
    username_client: string;
    main_notification_seen_flag?: boolean | null;
    message?: string | null;
    type?: string | null;
    ticket_id?: string | null;
    icon?: string | null;
    color?: string | null;
    task_id?: string | null;
    Problem?: string | null;
    formtype?: string | null;
    created_time?: string | null;
    update_time?: string | null;
    seen_flag?: boolean | null;
    ticket_info?: string | null;
    task_info?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableTicketQuery = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableTicketsQuery = {
  __typename: "LookupTableTicketConnection";
  items?: Array<{
    __typename: "LookupTableTicket";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableTaskQuery = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableTasksQuery = {
  __typename: "LookupTableTaskConnection";
  items?: Array<{
    __typename: "LookupTableTask";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableNotificationQuery = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableNotificationsQuery = {
  __typename: "LookupTableNotificationConnection";
  items?: Array<{
    __typename: "LookupTableNotification";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTaskTestQuery = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type ListTaskTestsQuery = {
  __typename: "TaskTestConnection";
  items?: Array<{
    __typename: "TaskTest";
    clientID: string;
    formID: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    created_Username?: string | null;
    updated_Username?: string | null;
    typeDes?: string | null;
    typelable?: string | null;
    parameters_data?: string | null;
    formDescription_visibilty?: string | null;
    formID_visibility?: string | null;
    formlable_visibility?: string | null;
    formTypeID?: string | null;
    iotDateTime?: string | null;
    startDateTime?: string | null;
    endDateTime?: string | null;
    blockDateTime?: string | null;
    label?: string | null;
    device?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTaskTestQuery = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTaskTestsQuery = {
  __typename: "LookupTaskTestConnection";
  items?: Array<{
    __typename: "LookupTaskTest";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableProblemQuery = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableProblemsQuery = {
  __typename: "LookupTableProblemConnection";
  items?: Array<{
    __typename: "LookupTableProblem";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableAssetMapQuery = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableAssetMapsQuery = {
  __typename: "LookupTableAssetMapConnection";
  items?: Array<{
    __typename: "LookupTableAssetMap";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableAssetAdditionQuery = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableAssetAdditionsQuery = {
  __typename: "LookupTableAssetAdditionConnection";
  items?: Array<{
    __typename: "LookupTableAssetAddition";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableRoleQuery = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableRolesQuery = {
  __typename: "LookupTableRoleConnection";
  items?: Array<{
    __typename: "LookupTableRole";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableDepartmentQuery = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableDepartmentsQuery = {
  __typename: "LookupTableDepartmentConnection";
  items?: Array<{
    __typename: "LookupTableDepartment";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableSubDepartmentQuery = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableSubDepartmentsQuery = {
  __typename: "LookupTableSubDepartmentConnection";
  items?: Array<{
    __typename: "LookupTableSubDepartment";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableLocationQuery = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableLocationsQuery = {
  __typename: "LookupTableLocationConnection";
  items?: Array<{
    __typename: "LookupTableLocation";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableLocationTypeQuery = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableLocationTypesQuery = {
  __typename: "LookupTableLocationTypeConnection";
  items?: Array<{
    __typename: "LookupTableLocationType";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableManufactureQuery = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableManufacturesQuery = {
  __typename: "LookupTableManufactureConnection";
  items?: Array<{
    __typename: "LookupTableManufacture";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableVenderQuery = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableVendersQuery = {
  __typename: "LookupTableVenderConnection";
  items?: Array<{
    __typename: "LookupTableVender";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableUserQuery = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableUsersQuery = {
  __typename: "LookupTableUserConnection";
  items?: Array<{
    __typename: "LookupTableUser";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTablePriorityQuery = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTablePrioritiesQuery = {
  __typename: "LookupTablePriorityConnection";
  items?: Array<{
    __typename: "LookupTablePriority";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableFormParameterQuery = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableFormParametersQuery = {
  __typename: "LookupTableFormParameterConnection";
  items?: Array<{
    __typename: "LookupTableFormParameter";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableFormTypeQuery = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableFormTypesQuery = {
  __typename: "LookupTableFormTypeConnection";
  items?: Array<{
    __typename: "LookupTableFormType";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableShiftQuery = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableShiftsQuery = {
  __typename: "LookupTableShiftConnection";
  items?: Array<{
    __typename: "LookupTableShift";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableModelQuery = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupTableModelsQuery = {
  __typename: "LookupTableModelConnection";
  items?: Array<{
    __typename: "LookupTableModel";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetDreamBoardQuery = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type ListDreamBoardsQuery = {
  __typename: "DreamBoardConnection";
  items?: Array<{
    __typename: "DreamBoard";
    PK: string;
    SK: string;
    name: string;
    description: string;
    board_type: string;
    settings: string;
    rdt: string;
    devices: string;
    type: string;
    onLoadJS?: string | null;
    onUpdateJS?: string | null;
    HTML: string;
    createdTime: number;
    updatedTime: number;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupTableDreamBoardQuery = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type ListLookupTableDreamBoardsQuery = {
  __typename: "LookupTableDreamBoardConnection";
  Elements?: Array<{
    __typename: "LookupTableDreamBoard";
    PK: string;
    SK: string;
    Elements: string;
    createdTime: number;
    updatedTime: number;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupMasterTicketTableQuery = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type ListLookupMasterTicketTablesQuery = {
  __typename: "LookupMasterTicketTableConnection";
  items?: Array<{
    __typename: "LookupMasterTicketTable";
    PK: string;
    SK: number;
    createdTime?: number | null;
    updatedTime?: number | null;
    Items?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLookupCamcDatesQuery = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type ListLookupCamcDatesQuery = {
  __typename: "LookupCamcDatesConnection";
  items?: Array<{
    __typename: "LookupCamcDates";
    clientid: string;
    id: string;
    metadata: string;
    options: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetClientTableQuery = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type ListClientTablesQuery = {
  __typename: "ClientTableConnection";
  items?: Array<{
    __typename: "ClientTable";
    clientid: string;
    id: string;
    metadata?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetAuditrailQuery = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type ListAuditrailsQuery = {
  __typename: "AuditrailConnection";
  items?: Array<{
    __typename: "Auditrail";
    PK: string;
    SK: number;
    action?: string | null;
    appName?: string | null;
    appVersion?: string | null;
    client?: string | null;
    description?: string | null;
    language?: string | null;
    module?: string | null;
    module_version?: string | null;
    platform?: string | null;
    records?: number | null;
    screenHeight?: number | null;
    screenWidth?: number | null;
    url?: string | null;
    userAgent?: string | null;
    username?: string | null;
    user_id?: string | null;
    web_version?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateLocationTypeSubscription = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateLocationTypeSubscription = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteLocationTypeSubscription = {
  __typename: "LocationType";
  client: string;
  location_type_id: string;
  Description: string;
  Metadata?: string | null;
  locations?: {
    __typename: "ModelLocationConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAssetLocationsSubscription = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAssetLocationsSubscription = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAssetLocationsSubscription = {
  __typename: "AssetLocations";
  client: string;
  asset_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateLocationSubscription = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type OnUpdateLocationSubscription = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type OnDeleteLocationSubscription = {
  __typename: "Location";
  client: string;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  location_id: string;
  name: string;
  location_type: string;
  longitude: number;
  latitude: number;
  movable_latitude?: number | null;
  movable_longitude?: number | null;
  gmt_diff: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
  locationTypeLocationsId: string;
};

export type OnCreatePrioritySubscription = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePrioritySubscription = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePrioritySubscription = {
  __typename: "Priority";
  client: string;
  priority_id: string;
  description: string;
  metadata?: string | null;
  problems?: {
    __typename: "ModelProblemConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProblemSubscription = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type OnUpdateProblemSubscription = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type OnDeleteProblemSubscription = {
  __typename: "Problem";
  client: string;
  problem_id: string;
  Origin_Status: string;
  priority: string;
  description: string;
  detail: string;
  prob_time: number;
  notif_policy_rem: number;
  escalation_lvl: string;
  comment: string;
  comment_Header: string;
  comment_Suffix: string;
  comment_Prefix: string;
  technician_Comment: boolean;
  impact: string;
  createdAt: string;
  updatedAt: string;
  metadata?: string | null;
  priorityProblemsId: string;
  items?: Array<number | null> | null;
};

export type OnCreateAmProblemsSubscription = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAmProblemsSubscription = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAmProblemsSubscription = {
  __typename: "AmProblems";
  client: string;
  assetmap_id: string;
  problem_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAssetMapSubscription = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAssetMapSubscription = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAssetMapSubscription = {
  __typename: "AssetMap";
  client: string;
  assetmap_id: string;
  problems?: Array<string | null> | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  Description: string;
  Metadata?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateManufacturerSubscription = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateManufacturerSubscription = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteManufacturerSubscription = {
  __typename: "Manufacturer";
  client: string;
  manufacturer_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateModelSubscription = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateModelSubscription = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteModelSubscription = {
  __typename: "Model";
  client: string;
  model_id: string;
  Description: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDepartmentSubscription = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDepartmentSubscription = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDepartmentSubscription = {
  __typename: "Department";
  client: string;
  dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSubDepartmentSubscription = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSubDepartmentSubscription = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSubDepartmentSubscription = {
  __typename: "SubDepartment";
  client: string;
  sub_dept_id: string;
  Description: string;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVendorCompanySubscription = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVendorCompanySubscription = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVendorCompanySubscription = {
  __typename: "VendorCompany";
  client: string;
  vendor_id: string;
  name: string;
  Description: string;
  email: string;
  ph_no: string;
  Metadata?: string | null;
  assets?: {
    __typename: "ModelAssetAdditionConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRoleSubscription = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRoleSubscription = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRoleSubscription = {
  __typename: "Role";
  client: string;
  role_id: string;
  Description: string;
  time_mult: number;
  Metadata?: string | null;
  users?: {
    __typename: "ModelUserConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserLocationsSubscription = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserLocationsSubscription = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserLocationsSubscription = {
  __typename: "UserLocations";
  client: string;
  user_id: string;
  location_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAssetUserNotifySubscription = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAssetUserNotifySubscription = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAssetUserNotifySubscription = {
  __typename: "AssetUserNotify";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAssetOwnerSubscription = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAssetOwnerSubscription = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAssetOwnerSubscription = {
  __typename: "AssetOwner";
  client: string;
  user_id: string;
  asset_id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  client: string;
  user_id: string;
  name: string;
  username: string;
  Description: string;
  Metadata?: string | null;
  mobile: string;
  email: string;
  user_type: string;
  mobile_privacy: boolean;
  alert_email: boolean;
  alert_sms: boolean;
  esc_email: boolean;
  esc_sms: boolean;
  role: string;
  department: string;
  sub_department: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  departmentUsersId: string;
  subDepartmentUsersId: string;
  roleUsersId: string;
  userReporting_toId: string;
  formcreate: boolean;
  formread: boolean;
  formupdate: boolean;
  formdelete: boolean;
  imageurl: string;
  formuserpermission: Array<string | null>;
  notification_token: string;
  telegramID: string;
  telegram: boolean;
  push_notification: boolean;
  sms: boolean;
  permission: Array<string | null>;
  chat_Bot?: string | null;
  mobile_permission: Array<string | null>;
  man_per_hour_cost: string;
};

export type OnCreateAssetAdditionSubscription = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type OnUpdateAssetAdditionSubscription = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type OnDeleteAssetAdditionSubscription = {
  __typename: "AssetAddition";
  client: string;
  tag_id: string;
  asset_map: string;
  user_notify_id: Array<string | null>;
  user_notify: Array<string | null>;
  tech_multi_id: Array<string | null>;
  multiple_technician: Array<string | null>;
  asset_id: string;
  model: string;
  sub_department: string;
  department: string;
  vendor_company: string;
  manufacturer: string;
  location: string;
  tickets?: {
    __typename: "ModelTicketsConnection";
    nextToken?: string | null;
  } | null;
  next_service: string;
  last_service: string;
  installation_date: string;
  amc_expiry: string;
  group: string;
  bought_price: number;
  current_price: number;
  asset_type: string;
  metadata?: string | null;
  tech_id: string;
  technician: string;
  createdAt: string;
  updatedAt: string;
  locationAssetsId: string;
  assetMapAssetsId: string;
  manufacturerAssetsId: string;
  modelAssetsId: string;
  departmentAssetsId: string;
  subDepartmentAssetsId: string;
  vendorCompanyAssetsId: string;
  formpermission?: string | null;
};

export type OnCreateTicketsSubscription = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type OnUpdateTicketsSubscription = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type OnDeleteTicketsSubscription = {
  __typename: "Tickets";
  client: string;
  ticket_id: string;
  tag_id: string;
  asset_add: string;
  asset_type_id: string;
  problem_id: string;
  problem: string;
  user: string;
  current_status: string;
  metadata?: string | null;
  current_esc_lvl: number;
  comment?: Array<string | null> | null;
  notification_sent_flag: boolean;
  esc_users: Array<string | null>;
  total_time: number;
  Problem_time: number;
  max_esc_reached: boolean;
  createdAt: string;
  max_esc_lvl: number;
  technician_id: string;
  mutli_assign: Array<string | null>;
  updatedAt: string;
  progress_update?: string | null;
  solved_update?: string | null;
  closed_update?: string | null;
  userTicketsId: string;
  assetAdditionTicketsId: string;
  moveble_latitude?: number | null;
  moveble_longitude?: number | null;
  status_progress?: string | null;
  status_solved?: string | null;
  status_closed?: string | null;
  other?: string | null;
  rating?: number | null;
  otp?: number | null;
};

export type OnCreateSavedTagsSubscription = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type OnUpdateSavedTagsSubscription = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type OnDeleteSavedTagsSubscription = {
  __typename: "SavedTags";
  client: string;
  userIdTagId: string;
  userId: string;
  tagId: string;
  createdAt: string;
};

export type OnCreateTicketImagesSubscription = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type OnUpdateTicketImagesSubscription = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type OnDeleteTicketImagesSubscription = {
  __typename: "TicketImages";
  client: string;
  ticket_id: string;
  images?: string | null;
};

export type OnCreateFormParameterSubscription = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type OnUpdateFormParameterSubscription = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type OnDeleteFormParameterSubscription = {
  __typename: "FormParameter";
  client: string;
  parameterID: string;
  other: string;
  formdefault: string;
  createdAt: string;
  updatedAt: string;
  parameterDescription: string;
  parameterLable: string;
  parameterType: string;
  placeholder: string;
  require: boolean;
  value_1: number;
  value_2: number;
  list: string;
  name?: Array<string | null> | null;
};

export type OnCreateFormTypeSubscription = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type OnUpdateFormTypeSubscription = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type OnDeleteFormTypeSubscription = {
  __typename: "FormType";
  client: string;
  typeID: string;
  typeLable: string;
  typeDescription: string;
  emailSubject: string;
  emailHeader: string;
  emailBody: string;
  emailFooter: string;
  other: string;
  emailEnable: boolean;
  emailRecipent?: Array<string | null> | null;
  typeparameterID?: Array<string | null> | null;
  formIDvisible: boolean;
  lablevisible: boolean;
  descriptionvisible: boolean;
  updatedAt: string;
  createdAt: string;
  mqtt_id: string;
  iot_connect: boolean;
  reserved: boolean;
  block_time: boolean;
  scan: boolean;
};

export type OnCreateTaskSubscription = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type OnUpdateTaskSubscription = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type OnDeleteTaskSubscription = {
  __typename: "Task";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  label?: string | null;
};

export type OnCreateMqttConfigurationSubscription = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type OnUpdateMqttConfigurationSubscription = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type OnDeleteMqttConfigurationSubscription = {
  __typename: "MqttConfiguration";
  client: string;
  mqtt_id: string;
  name: string;
  host: string;
  url: string;
  port: string;
  username: string;
  password: string;
  topic: string;
};

export type OnCreateShiftTableSubscription = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type OnUpdateShiftTableSubscription = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type OnDeleteShiftTableSubscription = {
  __typename: "ShiftTable";
  client: string;
  shift_id: string;
  form_time: string;
  to_time: string;
  shifts: string;
};

export type OnCreateDashboardSubscription = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type OnUpdateDashboardSubscription = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type OnDeleteDashboardSubscription = {
  __typename: "Dashboard";
  client_id: string;
  dashboard_id: string;
  tile?: string | null;
  latest_10_tickets?: string | null;
  latest_10_user_ratings?: string | null;
  upcoming_event?: string | null;
  Graph_data?: string | null;
};

export type OnCreateNotificationTableSubscription = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type OnUpdateNotificationTableSubscription = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type OnDeleteNotificationTableSubscription = {
  __typename: "NotificationTable";
  createdDate: string;
  username_client: string;
  main_notification_seen_flag?: boolean | null;
  message?: string | null;
  type?: string | null;
  ticket_id?: string | null;
  icon?: string | null;
  color?: string | null;
  task_id?: string | null;
  Problem?: string | null;
  formtype?: string | null;
  created_time?: string | null;
  update_time?: string | null;
  seen_flag?: boolean | null;
  ticket_info?: string | null;
  task_info?: string | null;
};

export type OnCreateLookupTableTicketSubscription = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableTicketSubscription = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableTicketSubscription = {
  __typename: "LookupTableTicket";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableTaskSubscription = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableTaskSubscription = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableTaskSubscription = {
  __typename: "LookupTableTask";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableNotificationSubscription = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableNotificationSubscription = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableNotificationSubscription = {
  __typename: "LookupTableNotification";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateTaskTestSubscription = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type OnUpdateTaskTestSubscription = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type OnDeleteTaskTestSubscription = {
  __typename: "TaskTest";
  clientID: string;
  formID: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  created_Username?: string | null;
  updated_Username?: string | null;
  typeDes?: string | null;
  typelable?: string | null;
  parameters_data?: string | null;
  formDescription_visibilty?: string | null;
  formID_visibility?: string | null;
  formlable_visibility?: string | null;
  formTypeID?: string | null;
  iotDateTime?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  blockDateTime?: string | null;
  label?: string | null;
  device?: string | null;
};

export type OnCreateLookupTaskTestSubscription = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTaskTestSubscription = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTaskTestSubscription = {
  __typename: "LookupTaskTest";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableProblemSubscription = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableProblemSubscription = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableProblemSubscription = {
  __typename: "LookupTableProblem";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableAssetMapSubscription = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableAssetMapSubscription = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableAssetMapSubscription = {
  __typename: "LookupTableAssetMap";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableAssetAdditionSubscription = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableAssetAdditionSubscription = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableAssetAdditionSubscription = {
  __typename: "LookupTableAssetAddition";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableRoleSubscription = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableRoleSubscription = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableRoleSubscription = {
  __typename: "LookupTableRole";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableDepartmentSubscription = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableDepartmentSubscription = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableDepartmentSubscription = {
  __typename: "LookupTableDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableSubDepartmentSubscription = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableSubDepartmentSubscription = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableSubDepartmentSubscription = {
  __typename: "LookupTableSubDepartment";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableLocationSubscription = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableLocationSubscription = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableLocationSubscription = {
  __typename: "LookupTableLocation";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableLocationTypeSubscription = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableLocationTypeSubscription = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableLocationTypeSubscription = {
  __typename: "LookupTableLocationType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableManufactureSubscription = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableManufactureSubscription = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableManufactureSubscription = {
  __typename: "LookupTableManufacture";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableVenderSubscription = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableVenderSubscription = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableVenderSubscription = {
  __typename: "LookupTableVender";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableUserSubscription = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableUserSubscription = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableUserSubscription = {
  __typename: "LookupTableUser";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTablePrioritySubscription = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTablePrioritySubscription = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTablePrioritySubscription = {
  __typename: "LookupTablePriority";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableFormParameterSubscription = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableFormParameterSubscription = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableFormParameterSubscription = {
  __typename: "LookupTableFormParameter";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableFormTypeSubscription = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableFormTypeSubscription = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableFormTypeSubscription = {
  __typename: "LookupTableFormType";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableShiftSubscription = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableShiftSubscription = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableShiftSubscription = {
  __typename: "LookupTableShift";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupTableModelSubscription = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupTableModelSubscription = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupTableModelSubscription = {
  __typename: "LookupTableModel";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateDreamBoardSubscription = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type OnUpdateDreamBoardSubscription = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type OnDeleteDreamBoardSubscription = {
  __typename: "DreamBoard";
  PK: string;
  SK: string;
  name: string;
  description: string;
  board_type: string;
  settings: string;
  rdt: string;
  devices: string;
  type: string;
  onLoadJS?: string | null;
  onUpdateJS?: string | null;
  HTML: string;
  createdTime: number;
  updatedTime: number;
};

export type OnCreateLookupTableDreamBoardSubscription = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type OnUpdateLookupTableDreamBoardSubscription = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type OnDeleteLookupTableDreamBoardSubscription = {
  __typename: "LookupTableDreamBoard";
  PK: string;
  SK: string;
  Elements: string;
  createdTime: number;
  updatedTime: number;
};

export type OnCreateLookupMasterTicketTableSubscription = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnUpdateLookupMasterTicketTableSubscription = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnDeleteLookupMasterTicketTableSubscription = {
  __typename: "LookupMasterTicketTable";
  PK: string;
  SK: number;
  createdTime?: number | null;
  updatedTime?: number | null;
  Items?: string | null;
};

export type OnCreateLookupCamcDatesSubscription = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type OnUpdateLookupCamcDatesSubscription = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type OnDeleteLookupCamcDatesSubscription = {
  __typename: "LookupCamcDates";
  clientid: string;
  id: string;
  metadata: string;
  options: string;
};

export type OnCreateClientTableSubscription = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type OnUpdateClientTableSubscription = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type OnDeleteClientTableSubscription = {
  __typename: "ClientTable";
  clientid: string;
  id: string;
  metadata?: string | null;
};

export type OnCreateAuditrailSubscription = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type OnUpdateAuditrailSubscription = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

export type OnDeleteAuditrailSubscription = {
  __typename: "Auditrail";
  PK: string;
  SK: number;
  action?: string | null;
  appName?: string | null;
  appVersion?: string | null;
  client?: string | null;
  description?: string | null;
  language?: string | null;
  module?: string | null;
  module_version?: string | null;
  platform?: string | null;
  records?: number | null;
  screenHeight?: number | null;
  screenWidth?: number | null;
  url?: string | null;
  userAgent?: string | null;
  username?: string | null;
  user_id?: string | null;
  web_version?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateLocationType(
    input: CreateLocationTypeInput,
    condition?: ModelLocationTypeConditionInput
  ): Promise<CreateLocationTypeMutation> {
    const statement = `mutation CreateLocationType($input: CreateLocationTypeInput!, $condition: ModelLocationTypeConditionInput) {
        createLocationType(input: $input, condition: $condition) {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLocationTypeMutation>response.data.createLocationType;
  }
  async UpdateLocationType(
    input: UpdateLocationTypeInput,
    condition?: ModelLocationTypeConditionInput
  ): Promise<UpdateLocationTypeMutation> {
    const statement = `mutation UpdateLocationType($input: UpdateLocationTypeInput!, $condition: ModelLocationTypeConditionInput) {
        updateLocationType(input: $input, condition: $condition) {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLocationTypeMutation>response.data.updateLocationType;
  }
  async DeleteLocationType(
    input: DeleteLocationTypeInput,
    condition?: ModelLocationTypeConditionInput
  ): Promise<DeleteLocationTypeMutation> {
    const statement = `mutation DeleteLocationType($input: DeleteLocationTypeInput!, $condition: ModelLocationTypeConditionInput) {
        deleteLocationType(input: $input, condition: $condition) {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLocationTypeMutation>response.data.deleteLocationType;
  }
  async CreateAssetLocations(
    input: CreateAssetLocationsInput,
    condition?: ModelAssetLocationsConditionInput
  ): Promise<CreateAssetLocationsMutation> {
    const statement = `mutation CreateAssetLocations($input: CreateAssetLocationsInput!, $condition: ModelAssetLocationsConditionInput) {
        createAssetLocations(input: $input, condition: $condition) {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssetLocationsMutation>response.data.createAssetLocations;
  }
  async UpdateAssetLocations(
    input: UpdateAssetLocationsInput,
    condition?: ModelAssetLocationsConditionInput
  ): Promise<UpdateAssetLocationsMutation> {
    const statement = `mutation UpdateAssetLocations($input: UpdateAssetLocationsInput!, $condition: ModelAssetLocationsConditionInput) {
        updateAssetLocations(input: $input, condition: $condition) {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssetLocationsMutation>response.data.updateAssetLocations;
  }
  async DeleteAssetLocations(
    input: DeleteAssetLocationsInput,
    condition?: ModelAssetLocationsConditionInput
  ): Promise<DeleteAssetLocationsMutation> {
    const statement = `mutation DeleteAssetLocations($input: DeleteAssetLocationsInput!, $condition: ModelAssetLocationsConditionInput) {
        deleteAssetLocations(input: $input, condition: $condition) {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssetLocationsMutation>response.data.deleteAssetLocations;
  }
  async CreateLocation(
    input: CreateLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<CreateLocationMutation> {
    const statement = `mutation CreateLocation($input: CreateLocationInput!, $condition: ModelLocationConditionInput) {
        createLocation(input: $input, condition: $condition) {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLocationMutation>response.data.createLocation;
  }
  async UpdateLocation(
    input: UpdateLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<UpdateLocationMutation> {
    const statement = `mutation UpdateLocation($input: UpdateLocationInput!, $condition: ModelLocationConditionInput) {
        updateLocation(input: $input, condition: $condition) {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLocationMutation>response.data.updateLocation;
  }
  async DeleteLocation(
    input: DeleteLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<DeleteLocationMutation> {
    const statement = `mutation DeleteLocation($input: DeleteLocationInput!, $condition: ModelLocationConditionInput) {
        deleteLocation(input: $input, condition: $condition) {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLocationMutation>response.data.deleteLocation;
  }
  async CreatePriority(
    input: CreatePriorityInput,
    condition?: ModelPriorityConditionInput
  ): Promise<CreatePriorityMutation> {
    const statement = `mutation CreatePriority($input: CreatePriorityInput!, $condition: ModelPriorityConditionInput) {
        createPriority(input: $input, condition: $condition) {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePriorityMutation>response.data.createPriority;
  }
  async UpdatePriority(
    input: UpdatePriorityInput,
    condition?: ModelPriorityConditionInput
  ): Promise<UpdatePriorityMutation> {
    const statement = `mutation UpdatePriority($input: UpdatePriorityInput!, $condition: ModelPriorityConditionInput) {
        updatePriority(input: $input, condition: $condition) {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePriorityMutation>response.data.updatePriority;
  }
  async DeletePriority(
    input: DeletePriorityInput,
    condition?: ModelPriorityConditionInput
  ): Promise<DeletePriorityMutation> {
    const statement = `mutation DeletePriority($input: DeletePriorityInput!, $condition: ModelPriorityConditionInput) {
        deletePriority(input: $input, condition: $condition) {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePriorityMutation>response.data.deletePriority;
  }
  async CreateProblem(
    input: CreateProblemInput,
    condition?: ModelProblemConditionInput
  ): Promise<CreateProblemMutation> {
    const statement = `mutation CreateProblem($input: CreateProblemInput!, $condition: ModelProblemConditionInput) {
        createProblem(input: $input, condition: $condition) {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProblemMutation>response.data.createProblem;
  }
  async UpdateProblem(
    input: UpdateProblemInput,
    condition?: ModelProblemConditionInput
  ): Promise<UpdateProblemMutation> {
    const statement = `mutation UpdateProblem($input: UpdateProblemInput!, $condition: ModelProblemConditionInput) {
        updateProblem(input: $input, condition: $condition) {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProblemMutation>response.data.updateProblem;
  }
  async DeleteProblem(
    input: DeleteProblemInput,
    condition?: ModelProblemConditionInput
  ): Promise<DeleteProblemMutation> {
    const statement = `mutation DeleteProblem($input: DeleteProblemInput!, $condition: ModelProblemConditionInput) {
        deleteProblem(input: $input, condition: $condition) {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProblemMutation>response.data.deleteProblem;
  }
  async CreateAmProblems(
    input: CreateAmProblemsInput,
    condition?: ModelAmProblemsConditionInput
  ): Promise<CreateAmProblemsMutation> {
    const statement = `mutation CreateAmProblems($input: CreateAmProblemsInput!, $condition: ModelAmProblemsConditionInput) {
        createAmProblems(input: $input, condition: $condition) {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAmProblemsMutation>response.data.createAmProblems;
  }
  async UpdateAmProblems(
    input: UpdateAmProblemsInput,
    condition?: ModelAmProblemsConditionInput
  ): Promise<UpdateAmProblemsMutation> {
    const statement = `mutation UpdateAmProblems($input: UpdateAmProblemsInput!, $condition: ModelAmProblemsConditionInput) {
        updateAmProblems(input: $input, condition: $condition) {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAmProblemsMutation>response.data.updateAmProblems;
  }
  async DeleteAmProblems(
    input: DeleteAmProblemsInput,
    condition?: ModelAmProblemsConditionInput
  ): Promise<DeleteAmProblemsMutation> {
    const statement = `mutation DeleteAmProblems($input: DeleteAmProblemsInput!, $condition: ModelAmProblemsConditionInput) {
        deleteAmProblems(input: $input, condition: $condition) {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAmProblemsMutation>response.data.deleteAmProblems;
  }
  async CreateAssetMap(
    input: CreateAssetMapInput,
    condition?: ModelAssetMapConditionInput
  ): Promise<CreateAssetMapMutation> {
    const statement = `mutation CreateAssetMap($input: CreateAssetMapInput!, $condition: ModelAssetMapConditionInput) {
        createAssetMap(input: $input, condition: $condition) {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssetMapMutation>response.data.createAssetMap;
  }
  async UpdateAssetMap(
    input: UpdateAssetMapInput,
    condition?: ModelAssetMapConditionInput
  ): Promise<UpdateAssetMapMutation> {
    const statement = `mutation UpdateAssetMap($input: UpdateAssetMapInput!, $condition: ModelAssetMapConditionInput) {
        updateAssetMap(input: $input, condition: $condition) {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssetMapMutation>response.data.updateAssetMap;
  }
  async DeleteAssetMap(
    input: DeleteAssetMapInput,
    condition?: ModelAssetMapConditionInput
  ): Promise<DeleteAssetMapMutation> {
    const statement = `mutation DeleteAssetMap($input: DeleteAssetMapInput!, $condition: ModelAssetMapConditionInput) {
        deleteAssetMap(input: $input, condition: $condition) {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssetMapMutation>response.data.deleteAssetMap;
  }
  async CreateManufacturer(
    input: CreateManufacturerInput,
    condition?: ModelManufacturerConditionInput
  ): Promise<CreateManufacturerMutation> {
    const statement = `mutation CreateManufacturer($input: CreateManufacturerInput!, $condition: ModelManufacturerConditionInput) {
        createManufacturer(input: $input, condition: $condition) {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateManufacturerMutation>response.data.createManufacturer;
  }
  async UpdateManufacturer(
    input: UpdateManufacturerInput,
    condition?: ModelManufacturerConditionInput
  ): Promise<UpdateManufacturerMutation> {
    const statement = `mutation UpdateManufacturer($input: UpdateManufacturerInput!, $condition: ModelManufacturerConditionInput) {
        updateManufacturer(input: $input, condition: $condition) {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateManufacturerMutation>response.data.updateManufacturer;
  }
  async DeleteManufacturer(
    input: DeleteManufacturerInput,
    condition?: ModelManufacturerConditionInput
  ): Promise<DeleteManufacturerMutation> {
    const statement = `mutation DeleteManufacturer($input: DeleteManufacturerInput!, $condition: ModelManufacturerConditionInput) {
        deleteManufacturer(input: $input, condition: $condition) {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteManufacturerMutation>response.data.deleteManufacturer;
  }
  async CreateModel(
    input: CreateModelInput,
    condition?: ModelModelConditionInput
  ): Promise<CreateModelMutation> {
    const statement = `mutation CreateModel($input: CreateModelInput!, $condition: ModelModelConditionInput) {
        createModel(input: $input, condition: $condition) {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateModelMutation>response.data.createModel;
  }
  async UpdateModel(
    input: UpdateModelInput,
    condition?: ModelModelConditionInput
  ): Promise<UpdateModelMutation> {
    const statement = `mutation UpdateModel($input: UpdateModelInput!, $condition: ModelModelConditionInput) {
        updateModel(input: $input, condition: $condition) {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateModelMutation>response.data.updateModel;
  }
  async DeleteModel(
    input: DeleteModelInput,
    condition?: ModelModelConditionInput
  ): Promise<DeleteModelMutation> {
    const statement = `mutation DeleteModel($input: DeleteModelInput!, $condition: ModelModelConditionInput) {
        deleteModel(input: $input, condition: $condition) {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteModelMutation>response.data.deleteModel;
  }
  async CreateDepartment(
    input: CreateDepartmentInput,
    condition?: ModelDepartmentConditionInput
  ): Promise<CreateDepartmentMutation> {
    const statement = `mutation CreateDepartment($input: CreateDepartmentInput!, $condition: ModelDepartmentConditionInput) {
        createDepartment(input: $input, condition: $condition) {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDepartmentMutation>response.data.createDepartment;
  }
  async UpdateDepartment(
    input: UpdateDepartmentInput,
    condition?: ModelDepartmentConditionInput
  ): Promise<UpdateDepartmentMutation> {
    const statement = `mutation UpdateDepartment($input: UpdateDepartmentInput!, $condition: ModelDepartmentConditionInput) {
        updateDepartment(input: $input, condition: $condition) {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDepartmentMutation>response.data.updateDepartment;
  }
  async DeleteDepartment(
    input: DeleteDepartmentInput,
    condition?: ModelDepartmentConditionInput
  ): Promise<DeleteDepartmentMutation> {
    const statement = `mutation DeleteDepartment($input: DeleteDepartmentInput!, $condition: ModelDepartmentConditionInput) {
        deleteDepartment(input: $input, condition: $condition) {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDepartmentMutation>response.data.deleteDepartment;
  }
  async CreateSubDepartment(
    input: CreateSubDepartmentInput,
    condition?: ModelSubDepartmentConditionInput
  ): Promise<CreateSubDepartmentMutation> {
    const statement = `mutation CreateSubDepartment($input: CreateSubDepartmentInput!, $condition: ModelSubDepartmentConditionInput) {
        createSubDepartment(input: $input, condition: $condition) {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubDepartmentMutation>response.data.createSubDepartment;
  }
  async UpdateSubDepartment(
    input: UpdateSubDepartmentInput,
    condition?: ModelSubDepartmentConditionInput
  ): Promise<UpdateSubDepartmentMutation> {
    const statement = `mutation UpdateSubDepartment($input: UpdateSubDepartmentInput!, $condition: ModelSubDepartmentConditionInput) {
        updateSubDepartment(input: $input, condition: $condition) {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubDepartmentMutation>response.data.updateSubDepartment;
  }
  async DeleteSubDepartment(
    input: DeleteSubDepartmentInput,
    condition?: ModelSubDepartmentConditionInput
  ): Promise<DeleteSubDepartmentMutation> {
    const statement = `mutation DeleteSubDepartment($input: DeleteSubDepartmentInput!, $condition: ModelSubDepartmentConditionInput) {
        deleteSubDepartment(input: $input, condition: $condition) {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubDepartmentMutation>response.data.deleteSubDepartment;
  }
  async CreateVendorCompany(
    input: CreateVendorCompanyInput,
    condition?: ModelVendorCompanyConditionInput
  ): Promise<CreateVendorCompanyMutation> {
    const statement = `mutation CreateVendorCompany($input: CreateVendorCompanyInput!, $condition: ModelVendorCompanyConditionInput) {
        createVendorCompany(input: $input, condition: $condition) {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVendorCompanyMutation>response.data.createVendorCompany;
  }
  async UpdateVendorCompany(
    input: UpdateVendorCompanyInput,
    condition?: ModelVendorCompanyConditionInput
  ): Promise<UpdateVendorCompanyMutation> {
    const statement = `mutation UpdateVendorCompany($input: UpdateVendorCompanyInput!, $condition: ModelVendorCompanyConditionInput) {
        updateVendorCompany(input: $input, condition: $condition) {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVendorCompanyMutation>response.data.updateVendorCompany;
  }
  async DeleteVendorCompany(
    input: DeleteVendorCompanyInput,
    condition?: ModelVendorCompanyConditionInput
  ): Promise<DeleteVendorCompanyMutation> {
    const statement = `mutation DeleteVendorCompany($input: DeleteVendorCompanyInput!, $condition: ModelVendorCompanyConditionInput) {
        deleteVendorCompany(input: $input, condition: $condition) {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVendorCompanyMutation>response.data.deleteVendorCompany;
  }
  async CreateRole(
    input: CreateRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<CreateRoleMutation> {
    const statement = `mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
        createRole(input: $input, condition: $condition) {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRoleMutation>response.data.createRole;
  }
  async UpdateRole(
    input: UpdateRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<UpdateRoleMutation> {
    const statement = `mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
        updateRole(input: $input, condition: $condition) {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRoleMutation>response.data.updateRole;
  }
  async DeleteRole(
    input: DeleteRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<DeleteRoleMutation> {
    const statement = `mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
        deleteRole(input: $input, condition: $condition) {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRoleMutation>response.data.deleteRole;
  }
  async CreateUserLocations(
    input: CreateUserLocationsInput,
    condition?: ModelUserLocationsConditionInput
  ): Promise<CreateUserLocationsMutation> {
    const statement = `mutation CreateUserLocations($input: CreateUserLocationsInput!, $condition: ModelUserLocationsConditionInput) {
        createUserLocations(input: $input, condition: $condition) {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserLocationsMutation>response.data.createUserLocations;
  }
  async UpdateUserLocations(
    input: UpdateUserLocationsInput,
    condition?: ModelUserLocationsConditionInput
  ): Promise<UpdateUserLocationsMutation> {
    const statement = `mutation UpdateUserLocations($input: UpdateUserLocationsInput!, $condition: ModelUserLocationsConditionInput) {
        updateUserLocations(input: $input, condition: $condition) {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserLocationsMutation>response.data.updateUserLocations;
  }
  async DeleteUserLocations(
    input: DeleteUserLocationsInput,
    condition?: ModelUserLocationsConditionInput
  ): Promise<DeleteUserLocationsMutation> {
    const statement = `mutation DeleteUserLocations($input: DeleteUserLocationsInput!, $condition: ModelUserLocationsConditionInput) {
        deleteUserLocations(input: $input, condition: $condition) {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserLocationsMutation>response.data.deleteUserLocations;
  }
  async CreateAssetUserNotify(
    input: CreateAssetUserNotifyInput,
    condition?: ModelAssetUserNotifyConditionInput
  ): Promise<CreateAssetUserNotifyMutation> {
    const statement = `mutation CreateAssetUserNotify($input: CreateAssetUserNotifyInput!, $condition: ModelAssetUserNotifyConditionInput) {
        createAssetUserNotify(input: $input, condition: $condition) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssetUserNotifyMutation>response.data.createAssetUserNotify;
  }
  async UpdateAssetUserNotify(
    input: UpdateAssetUserNotifyInput,
    condition?: ModelAssetUserNotifyConditionInput
  ): Promise<UpdateAssetUserNotifyMutation> {
    const statement = `mutation UpdateAssetUserNotify($input: UpdateAssetUserNotifyInput!, $condition: ModelAssetUserNotifyConditionInput) {
        updateAssetUserNotify(input: $input, condition: $condition) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssetUserNotifyMutation>response.data.updateAssetUserNotify;
  }
  async DeleteAssetUserNotify(
    input: DeleteAssetUserNotifyInput,
    condition?: ModelAssetUserNotifyConditionInput
  ): Promise<DeleteAssetUserNotifyMutation> {
    const statement = `mutation DeleteAssetUserNotify($input: DeleteAssetUserNotifyInput!, $condition: ModelAssetUserNotifyConditionInput) {
        deleteAssetUserNotify(input: $input, condition: $condition) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssetUserNotifyMutation>response.data.deleteAssetUserNotify;
  }
  async CreateAssetOwner(
    input: CreateAssetOwnerInput,
    condition?: ModelAssetOwnerConditionInput
  ): Promise<CreateAssetOwnerMutation> {
    const statement = `mutation CreateAssetOwner($input: CreateAssetOwnerInput!, $condition: ModelAssetOwnerConditionInput) {
        createAssetOwner(input: $input, condition: $condition) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssetOwnerMutation>response.data.createAssetOwner;
  }
  async UpdateAssetOwner(
    input: UpdateAssetOwnerInput,
    condition?: ModelAssetOwnerConditionInput
  ): Promise<UpdateAssetOwnerMutation> {
    const statement = `mutation UpdateAssetOwner($input: UpdateAssetOwnerInput!, $condition: ModelAssetOwnerConditionInput) {
        updateAssetOwner(input: $input, condition: $condition) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssetOwnerMutation>response.data.updateAssetOwner;
  }
  async DeleteAssetOwner(
    input: DeleteAssetOwnerInput,
    condition?: ModelAssetOwnerConditionInput
  ): Promise<DeleteAssetOwnerMutation> {
    const statement = `mutation DeleteAssetOwner($input: DeleteAssetOwnerInput!, $condition: ModelAssetOwnerConditionInput) {
        deleteAssetOwner(input: $input, condition: $condition) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssetOwnerMutation>response.data.deleteAssetOwner;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateAssetAddition(
    input: CreateAssetAdditionInput,
    condition?: ModelAssetAdditionConditionInput
  ): Promise<CreateAssetAdditionMutation> {
    const statement = `mutation CreateAssetAddition($input: CreateAssetAdditionInput!, $condition: ModelAssetAdditionConditionInput) {
        createAssetAddition(input: $input, condition: $condition) {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssetAdditionMutation>response.data.createAssetAddition;
  }
  async UpdateAssetAddition(
    input: UpdateAssetAdditionInput,
    condition?: ModelAssetAdditionConditionInput
  ): Promise<UpdateAssetAdditionMutation> {
    const statement = `mutation UpdateAssetAddition($input: UpdateAssetAdditionInput!, $condition: ModelAssetAdditionConditionInput) {
        updateAssetAddition(input: $input, condition: $condition) {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssetAdditionMutation>response.data.updateAssetAddition;
  }
  async DeleteAssetAddition(
    input: DeleteAssetAdditionInput,
    condition?: ModelAssetAdditionConditionInput
  ): Promise<DeleteAssetAdditionMutation> {
    const statement = `mutation DeleteAssetAddition($input: DeleteAssetAdditionInput!, $condition: ModelAssetAdditionConditionInput) {
        deleteAssetAddition(input: $input, condition: $condition) {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssetAdditionMutation>response.data.deleteAssetAddition;
  }
  async CreateTickets(
    input: CreateTicketsInput,
    condition?: ModelTicketsConditionInput
  ): Promise<CreateTicketsMutation> {
    const statement = `mutation CreateTickets($input: CreateTicketsInput!, $condition: ModelTicketsConditionInput) {
        createTickets(input: $input, condition: $condition) {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketsMutation>response.data.createTickets;
  }
  async UpdateTickets(
    input: UpdateTicketsInput,
    condition?: ModelTicketsConditionInput
  ): Promise<UpdateTicketsMutation> {
    const statement = `mutation UpdateTickets($input: UpdateTicketsInput!, $condition: ModelTicketsConditionInput) {
        updateTickets(input: $input, condition: $condition) {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketsMutation>response.data.updateTickets;
  }
  async DeleteTickets(
    input: DeleteTicketsInput,
    condition?: ModelTicketsConditionInput
  ): Promise<DeleteTicketsMutation> {
    const statement = `mutation DeleteTickets($input: DeleteTicketsInput!, $condition: ModelTicketsConditionInput) {
        deleteTickets(input: $input, condition: $condition) {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketsMutation>response.data.deleteTickets;
  }
  async CreateSavedTags(
    input: CreateSavedTagsInput
  ): Promise<CreateSavedTagsMutation> {
    const statement = `mutation CreateSavedTags($input: CreateSavedTagsInput!) {
        createSavedTags(input: $input) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSavedTagsMutation>response.data.createSavedTags;
  }
  async UpdateSavedTags(
    input: UpdateSavedTagsInput
  ): Promise<UpdateSavedTagsMutation> {
    const statement = `mutation UpdateSavedTags($input: UpdateSavedTagsInput!) {
        updateSavedTags(input: $input) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSavedTagsMutation>response.data.updateSavedTags;
  }
  async DeleteSavedTags(
    input: DeleteSavedTagsInput
  ): Promise<DeleteSavedTagsMutation> {
    const statement = `mutation DeleteSavedTags($input: DeleteSavedTagsInput!) {
        deleteSavedTags(input: $input) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSavedTagsMutation>response.data.deleteSavedTags;
  }
  async CreateTicketImages(
    input: CreateTicketImagesInput
  ): Promise<CreateTicketImagesMutation> {
    const statement = `mutation CreateTicketImages($input: CreateTicketImagesInput!) {
        createTicketImages(input: $input) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTicketImagesMutation>response.data.createTicketImages;
  }
  async UpdateTicketImages(
    input: UpdateTicketImagesInput
  ): Promise<UpdateTicketImagesMutation> {
    const statement = `mutation UpdateTicketImages($input: UpdateTicketImagesInput!) {
        updateTicketImages(input: $input) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTicketImagesMutation>response.data.updateTicketImages;
  }
  async DeleteTicketImages(
    input: DeleteTicketImagesInput
  ): Promise<DeleteTicketImagesMutation> {
    const statement = `mutation DeleteTicketImages($input: DeleteTicketImagesInput!) {
        deleteTicketImages(input: $input) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTicketImagesMutation>response.data.deleteTicketImages;
  }
  async CreateFormParameter(
    input: CreateFormParameterInput
  ): Promise<CreateFormParameterMutation> {
    const statement = `mutation CreateFormParameter($input: CreateFormParameterInput!) {
        createFormParameter(input: $input) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFormParameterMutation>response.data.createFormParameter;
  }
  async UpdateFormParameter(
    input: UpdateFormParameterInput
  ): Promise<UpdateFormParameterMutation> {
    const statement = `mutation UpdateFormParameter($input: UpdateFormParameterInput!) {
        updateFormParameter(input: $input) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFormParameterMutation>response.data.updateFormParameter;
  }
  async DeleteFormParameter(
    input: DeleteFormParameterInput
  ): Promise<DeleteFormParameterMutation> {
    const statement = `mutation DeleteFormParameter($input: DeleteFormParameterInput!) {
        deleteFormParameter(input: $input) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFormParameterMutation>response.data.deleteFormParameter;
  }
  async CreateFormType(
    input: CreateFormTypeInput
  ): Promise<CreateFormTypeMutation> {
    const statement = `mutation CreateFormType($input: CreateFormTypeInput!) {
        createFormType(input: $input) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFormTypeMutation>response.data.createFormType;
  }
  async UpdateFormType(
    input: UpdateFormTypeInput
  ): Promise<UpdateFormTypeMutation> {
    const statement = `mutation UpdateFormType($input: UpdateFormTypeInput!) {
        updateFormType(input: $input) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFormTypeMutation>response.data.updateFormType;
  }
  async DeleteFormType(
    input: DeleteFormTypeInput
  ): Promise<DeleteFormTypeMutation> {
    const statement = `mutation DeleteFormType($input: DeleteFormTypeInput!) {
        deleteFormType(input: $input) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFormTypeMutation>response.data.deleteFormType;
  }
  async CreateTask(input: CreateTaskInput): Promise<CreateTaskMutation> {
    const statement = `mutation CreateTask($input: CreateTaskInput!) {
        createTask(input: $input) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaskMutation>response.data.createTask;
  }
  async UpdateTask(input: UpdateTaskInput): Promise<UpdateTaskMutation> {
    const statement = `mutation UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaskMutation>response.data.updateTask;
  }
  async DeleteTask(input: DeleteTaskInput): Promise<DeleteTaskMutation> {
    const statement = `mutation DeleteTask($input: DeleteTaskInput!) {
        deleteTask(input: $input) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaskMutation>response.data.deleteTask;
  }
  async CreateMqttConfiguration(
    input: CreateMqttConfigurationInput
  ): Promise<CreateMqttConfigurationMutation> {
    const statement = `mutation CreateMqttConfiguration($input: CreateMqttConfigurationInput!) {
        createMqttConfiguration(input: $input) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMqttConfigurationMutation>(
      response.data.createMqttConfiguration
    );
  }
  async UpdateMqttConfiguration(
    input: UpdateMqttConfigurationInput
  ): Promise<UpdateMqttConfigurationMutation> {
    const statement = `mutation UpdateMqttConfiguration($input: UpdateMqttConfigurationInput!) {
        updateMqttConfiguration(input: $input) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMqttConfigurationMutation>(
      response.data.updateMqttConfiguration
    );
  }
  async DeleteMqttConfiguration(
    input: DeleteMqttConfigurationInput
  ): Promise<DeleteMqttConfigurationMutation> {
    const statement = `mutation DeleteMqttConfiguration($input: DeleteMqttConfigurationInput!) {
        deleteMqttConfiguration(input: $input) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMqttConfigurationMutation>(
      response.data.deleteMqttConfiguration
    );
  }
  async CreateShiftTable(
    input: CreateShiftTableInput
  ): Promise<CreateShiftTableMutation> {
    const statement = `mutation CreateShiftTable($input: CreateShiftTableInput!) {
        createShiftTable(input: $input) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateShiftTableMutation>response.data.createShiftTable;
  }
  async UpdateShiftTable(
    input: UpdateShiftTableInput
  ): Promise<UpdateShiftTableMutation> {
    const statement = `mutation UpdateShiftTable($input: UpdateShiftTableInput!) {
        updateShiftTable(input: $input) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateShiftTableMutation>response.data.updateShiftTable;
  }
  async DeleteShiftTable(
    input: DeleteShiftTableInput
  ): Promise<DeleteShiftTableMutation> {
    const statement = `mutation DeleteShiftTable($input: DeleteShiftTableInput!) {
        deleteShiftTable(input: $input) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteShiftTableMutation>response.data.deleteShiftTable;
  }
  async CreateDashboard(
    input: CreateDashboardInput
  ): Promise<CreateDashboardMutation> {
    const statement = `mutation CreateDashboard($input: CreateDashboardInput!) {
        createDashboard(input: $input) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDashboardMutation>response.data.createDashboard;
  }
  async UpdateDashboard(
    input: UpdateDashboardInput
  ): Promise<UpdateDashboardMutation> {
    const statement = `mutation UpdateDashboard($input: UpdateDashboardInput!) {
        updateDashboard(input: $input) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDashboardMutation>response.data.updateDashboard;
  }
  async DeleteDashboard(
    input: DeleteDashboardInput
  ): Promise<DeleteDashboardMutation> {
    const statement = `mutation DeleteDashboard($input: DeleteDashboardInput!) {
        deleteDashboard(input: $input) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDashboardMutation>response.data.deleteDashboard;
  }
  async CreateNotificationTable(
    input: CreateNotificationTableInput
  ): Promise<CreateNotificationTableMutation> {
    const statement = `mutation CreateNotificationTable($input: CreateNotificationTableInput!) {
        createNotificationTable(input: $input) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationTableMutation>(
      response.data.createNotificationTable
    );
  }
  async UpdateNotificationTable(
    input: UpdateNotificationTableInput
  ): Promise<UpdateNotificationTableMutation> {
    const statement = `mutation UpdateNotificationTable($input: UpdateNotificationTableInput!) {
        updateNotificationTable(input: $input) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationTableMutation>(
      response.data.updateNotificationTable
    );
  }
  async DeleteNotificationTable(
    input: DeleteNotificationTableInput
  ): Promise<DeleteNotificationTableMutation> {
    const statement = `mutation DeleteNotificationTable($input: DeleteNotificationTableInput!) {
        deleteNotificationTable(input: $input) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationTableMutation>(
      response.data.deleteNotificationTable
    );
  }
  async CreateLookupTableTicket(
    input: CreateLookupTableTicketInput
  ): Promise<CreateLookupTableTicketMutation> {
    const statement = `mutation CreateLookupTableTicket($input: CreateLookupTableTicketInput!) {
        createLookupTableTicket(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableTicketMutation>(
      response.data.createLookupTableTicket
    );
  }
  async UpdateLookupTableTicket(
    input: UpdateLookupTableTicketInput
  ): Promise<UpdateLookupTableTicketMutation> {
    const statement = `mutation UpdateLookupTableTicket($input: UpdateLookupTableTicketInput!) {
        updateLookupTableTicket(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableTicketMutation>(
      response.data.updateLookupTableTicket
    );
  }
  async DeleteLookupTableTicket(
    input: DeleteLookupTableTicketInput
  ): Promise<DeleteLookupTableTicketMutation> {
    const statement = `mutation DeleteLookupTableTicket($input: DeleteLookupTableTicketInput!) {
        deleteLookupTableTicket(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableTicketMutation>(
      response.data.deleteLookupTableTicket
    );
  }
  async CreateLookupTableTask(
    input: CreateLookupTableTaskInput
  ): Promise<CreateLookupTableTaskMutation> {
    const statement = `mutation CreateLookupTableTask($input: CreateLookupTableTaskInput!) {
        createLookupTableTask(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableTaskMutation>response.data.createLookupTableTask;
  }
  async UpdateLookupTableTask(
    input: UpdateLookupTableTaskInput
  ): Promise<UpdateLookupTableTaskMutation> {
    const statement = `mutation UpdateLookupTableTask($input: UpdateLookupTableTaskInput!) {
        updateLookupTableTask(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableTaskMutation>response.data.updateLookupTableTask;
  }
  async DeleteLookupTableTask(
    input: DeleteLookupTableTaskInput
  ): Promise<DeleteLookupTableTaskMutation> {
    const statement = `mutation DeleteLookupTableTask($input: DeleteLookupTableTaskInput!) {
        deleteLookupTableTask(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableTaskMutation>response.data.deleteLookupTableTask;
  }
  async CreateLookupTableNotification(
    input: CreateLookupTableNotificationInput
  ): Promise<CreateLookupTableNotificationMutation> {
    const statement = `mutation CreateLookupTableNotification($input: CreateLookupTableNotificationInput!) {
        createLookupTableNotification(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableNotificationMutation>(
      response.data.createLookupTableNotification
    );
  }
  async UpdateLookupTableNotification(
    input: UpdateLookupTableNotificationInput
  ): Promise<UpdateLookupTableNotificationMutation> {
    const statement = `mutation UpdateLookupTableNotification($input: UpdateLookupTableNotificationInput!) {
        updateLookupTableNotification(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableNotificationMutation>(
      response.data.updateLookupTableNotification
    );
  }
  async DeleteLookupTableNotification(
    input: DeleteLookupTableNotificationInput
  ): Promise<DeleteLookupTableNotificationMutation> {
    const statement = `mutation DeleteLookupTableNotification($input: DeleteLookupTableNotificationInput!) {
        deleteLookupTableNotification(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableNotificationMutation>(
      response.data.deleteLookupTableNotification
    );
  }
  async CreateTaskTest(
    input: CreateTaskTestInput
  ): Promise<CreateTaskTestMutation> {
    const statement = `mutation CreateTaskTest($input: CreateTaskTestInput!) {
        createTaskTest(input: $input) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaskTestMutation>response.data.createTaskTest;
  }
  async UpdateTaskTest(
    input: UpdateTaskTestInput
  ): Promise<UpdateTaskTestMutation> {
    const statement = `mutation UpdateTaskTest($input: UpdateTaskTestInput!) {
        updateTaskTest(input: $input) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaskTestMutation>response.data.updateTaskTest;
  }
  async DeleteTaskTest(
    input: DeleteTaskTestInput
  ): Promise<DeleteTaskTestMutation> {
    const statement = `mutation DeleteTaskTest($input: DeleteTaskTestInput!) {
        deleteTaskTest(input: $input) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaskTestMutation>response.data.deleteTaskTest;
  }
  async CreateLookupTaskTest(
    input: CreateLookupTaskTestInput
  ): Promise<CreateLookupTaskTestMutation> {
    const statement = `mutation CreateLookupTaskTest($input: CreateLookupTaskTestInput!) {
        createLookupTaskTest(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTaskTestMutation>response.data.createLookupTaskTest;
  }
  async UpdateLookupTaskTest(
    input: UpdateLookupTaskTestInput
  ): Promise<UpdateLookupTaskTestMutation> {
    const statement = `mutation UpdateLookupTaskTest($input: UpdateLookupTaskTestInput!) {
        updateLookupTaskTest(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTaskTestMutation>response.data.updateLookupTaskTest;
  }
  async DeleteLookupTaskTest(
    input: DeleteLookupTaskTestInput
  ): Promise<DeleteLookupTaskTestMutation> {
    const statement = `mutation DeleteLookupTaskTest($input: DeleteLookupTaskTestInput!) {
        deleteLookupTaskTest(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTaskTestMutation>response.data.deleteLookupTaskTest;
  }
  async CreateLookupTableProblem(
    input: CreateLookupTableProblemInput
  ): Promise<CreateLookupTableProblemMutation> {
    const statement = `mutation CreateLookupTableProblem($input: CreateLookupTableProblemInput!) {
        createLookupTableProblem(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableProblemMutation>(
      response.data.createLookupTableProblem
    );
  }
  async UpdateLookupTableProblem(
    input: UpdateLookupTableProblemInput
  ): Promise<UpdateLookupTableProblemMutation> {
    const statement = `mutation UpdateLookupTableProblem($input: UpdateLookupTableProblemInput!) {
        updateLookupTableProblem(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableProblemMutation>(
      response.data.updateLookupTableProblem
    );
  }
  async DeleteLookupTableProblem(
    input: DeleteLookupTableProblemInput
  ): Promise<DeleteLookupTableProblemMutation> {
    const statement = `mutation DeleteLookupTableProblem($input: DeleteLookupTableProblemInput!) {
        deleteLookupTableProblem(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableProblemMutation>(
      response.data.deleteLookupTableProblem
    );
  }
  async CreateLookupTableAssetMap(
    input: CreateLookupTableAssetMapInput
  ): Promise<CreateLookupTableAssetMapMutation> {
    const statement = `mutation CreateLookupTableAssetMap($input: CreateLookupTableAssetMapInput!) {
        createLookupTableAssetMap(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableAssetMapMutation>(
      response.data.createLookupTableAssetMap
    );
  }
  async UpdateLookupTableAssetMap(
    input: UpdateLookupTableAssetMapInput
  ): Promise<UpdateLookupTableAssetMapMutation> {
    const statement = `mutation UpdateLookupTableAssetMap($input: UpdateLookupTableAssetMapInput!) {
        updateLookupTableAssetMap(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableAssetMapMutation>(
      response.data.updateLookupTableAssetMap
    );
  }
  async DeleteLookupTableAssetMap(
    input: DeleteLookupTableAssetMapInput
  ): Promise<DeleteLookupTableAssetMapMutation> {
    const statement = `mutation DeleteLookupTableAssetMap($input: DeleteLookupTableAssetMapInput!) {
        deleteLookupTableAssetMap(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableAssetMapMutation>(
      response.data.deleteLookupTableAssetMap
    );
  }
  async CreateLookupTableAssetAddition(
    input: CreateLookupTableAssetAdditionInput
  ): Promise<CreateLookupTableAssetAdditionMutation> {
    const statement = `mutation CreateLookupTableAssetAddition($input: CreateLookupTableAssetAdditionInput!) {
        createLookupTableAssetAddition(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableAssetAdditionMutation>(
      response.data.createLookupTableAssetAddition
    );
  }
  async UpdateLookupTableAssetAddition(
    input: UpdateLookupTableAssetAdditionInput
  ): Promise<UpdateLookupTableAssetAdditionMutation> {
    const statement = `mutation UpdateLookupTableAssetAddition($input: UpdateLookupTableAssetAdditionInput!) {
        updateLookupTableAssetAddition(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableAssetAdditionMutation>(
      response.data.updateLookupTableAssetAddition
    );
  }
  async DeleteLookupTableAssetAddition(
    input: DeleteLookupTableAssetAdditionInput
  ): Promise<DeleteLookupTableAssetAdditionMutation> {
    const statement = `mutation DeleteLookupTableAssetAddition($input: DeleteLookupTableAssetAdditionInput!) {
        deleteLookupTableAssetAddition(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableAssetAdditionMutation>(
      response.data.deleteLookupTableAssetAddition
    );
  }
  async CreateLookupTableRole(
    input: CreateLookupTableRoleInput
  ): Promise<CreateLookupTableRoleMutation> {
    const statement = `mutation CreateLookupTableRole($input: CreateLookupTableRoleInput!) {
        createLookupTableRole(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableRoleMutation>response.data.createLookupTableRole;
  }
  async UpdateLookupTableRole(
    input: UpdateLookupTableRoleInput
  ): Promise<UpdateLookupTableRoleMutation> {
    const statement = `mutation UpdateLookupTableRole($input: UpdateLookupTableRoleInput!) {
        updateLookupTableRole(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableRoleMutation>response.data.updateLookupTableRole;
  }
  async DeleteLookupTableRole(
    input: DeleteLookupTableRoleInput
  ): Promise<DeleteLookupTableRoleMutation> {
    const statement = `mutation DeleteLookupTableRole($input: DeleteLookupTableRoleInput!) {
        deleteLookupTableRole(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableRoleMutation>response.data.deleteLookupTableRole;
  }
  async CreateLookupTableDepartment(
    input: CreateLookupTableDepartmentInput
  ): Promise<CreateLookupTableDepartmentMutation> {
    const statement = `mutation CreateLookupTableDepartment($input: CreateLookupTableDepartmentInput!) {
        createLookupTableDepartment(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableDepartmentMutation>(
      response.data.createLookupTableDepartment
    );
  }
  async UpdateLookupTableDepartment(
    input: UpdateLookupTableDepartmentInput
  ): Promise<UpdateLookupTableDepartmentMutation> {
    const statement = `mutation UpdateLookupTableDepartment($input: UpdateLookupTableDepartmentInput!) {
        updateLookupTableDepartment(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableDepartmentMutation>(
      response.data.updateLookupTableDepartment
    );
  }
  async DeleteLookupTableDepartment(
    input: DeleteLookupTableDepartmentInput
  ): Promise<DeleteLookupTableDepartmentMutation> {
    const statement = `mutation DeleteLookupTableDepartment($input: DeleteLookupTableDepartmentInput!) {
        deleteLookupTableDepartment(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableDepartmentMutation>(
      response.data.deleteLookupTableDepartment
    );
  }
  async CreateLookupTableSubDepartment(
    input: CreateLookupTableSubDepartmentInput
  ): Promise<CreateLookupTableSubDepartmentMutation> {
    const statement = `mutation CreateLookupTableSubDepartment($input: CreateLookupTableSubDepartmentInput!) {
        createLookupTableSubDepartment(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableSubDepartmentMutation>(
      response.data.createLookupTableSubDepartment
    );
  }
  async UpdateLookupTableSubDepartment(
    input: UpdateLookupTableSubDepartmentInput
  ): Promise<UpdateLookupTableSubDepartmentMutation> {
    const statement = `mutation UpdateLookupTableSubDepartment($input: UpdateLookupTableSubDepartmentInput!) {
        updateLookupTableSubDepartment(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableSubDepartmentMutation>(
      response.data.updateLookupTableSubDepartment
    );
  }
  async DeleteLookupTableSubDepartment(
    input: DeleteLookupTableSubDepartmentInput
  ): Promise<DeleteLookupTableSubDepartmentMutation> {
    const statement = `mutation DeleteLookupTableSubDepartment($input: DeleteLookupTableSubDepartmentInput!) {
        deleteLookupTableSubDepartment(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableSubDepartmentMutation>(
      response.data.deleteLookupTableSubDepartment
    );
  }
  async CreateLookupTableLocation(
    input: CreateLookupTableLocationInput
  ): Promise<CreateLookupTableLocationMutation> {
    const statement = `mutation CreateLookupTableLocation($input: CreateLookupTableLocationInput!) {
        createLookupTableLocation(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableLocationMutation>(
      response.data.createLookupTableLocation
    );
  }
  async UpdateLookupTableLocation(
    input: UpdateLookupTableLocationInput
  ): Promise<UpdateLookupTableLocationMutation> {
    const statement = `mutation UpdateLookupTableLocation($input: UpdateLookupTableLocationInput!) {
        updateLookupTableLocation(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableLocationMutation>(
      response.data.updateLookupTableLocation
    );
  }
  async DeleteLookupTableLocation(
    input: DeleteLookupTableLocationInput
  ): Promise<DeleteLookupTableLocationMutation> {
    const statement = `mutation DeleteLookupTableLocation($input: DeleteLookupTableLocationInput!) {
        deleteLookupTableLocation(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableLocationMutation>(
      response.data.deleteLookupTableLocation
    );
  }
  async CreateLookupTableLocationType(
    input: CreateLookupTableLocationTypeInput
  ): Promise<CreateLookupTableLocationTypeMutation> {
    const statement = `mutation CreateLookupTableLocationType($input: CreateLookupTableLocationTypeInput!) {
        createLookupTableLocationType(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableLocationTypeMutation>(
      response.data.createLookupTableLocationType
    );
  }
  async UpdateLookupTableLocationType(
    input: UpdateLookupTableLocationTypeInput
  ): Promise<UpdateLookupTableLocationTypeMutation> {
    const statement = `mutation UpdateLookupTableLocationType($input: UpdateLookupTableLocationTypeInput!) {
        updateLookupTableLocationType(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableLocationTypeMutation>(
      response.data.updateLookupTableLocationType
    );
  }
  async DeleteLookupTableLocationType(
    input: DeleteLookupTableLocationTypeInput
  ): Promise<DeleteLookupTableLocationTypeMutation> {
    const statement = `mutation DeleteLookupTableLocationType($input: DeleteLookupTableLocationTypeInput!) {
        deleteLookupTableLocationType(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableLocationTypeMutation>(
      response.data.deleteLookupTableLocationType
    );
  }
  async CreateLookupTableManufacture(
    input: CreateLookupTableManufactureInput
  ): Promise<CreateLookupTableManufactureMutation> {
    const statement = `mutation CreateLookupTableManufacture($input: CreateLookupTableManufactureInput!) {
        createLookupTableManufacture(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableManufactureMutation>(
      response.data.createLookupTableManufacture
    );
  }
  async UpdateLookupTableManufacture(
    input: UpdateLookupTableManufactureInput
  ): Promise<UpdateLookupTableManufactureMutation> {
    const statement = `mutation UpdateLookupTableManufacture($input: UpdateLookupTableManufactureInput!) {
        updateLookupTableManufacture(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableManufactureMutation>(
      response.data.updateLookupTableManufacture
    );
  }
  async DeleteLookupTableManufacture(
    input: DeleteLookupTableManufactureInput
  ): Promise<DeleteLookupTableManufactureMutation> {
    const statement = `mutation DeleteLookupTableManufacture($input: DeleteLookupTableManufactureInput!) {
        deleteLookupTableManufacture(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableManufactureMutation>(
      response.data.deleteLookupTableManufacture
    );
  }
  async CreateLookupTableVender(
    input: CreateLookupTableVenderInput
  ): Promise<CreateLookupTableVenderMutation> {
    const statement = `mutation CreateLookupTableVender($input: CreateLookupTableVenderInput!) {
        createLookupTableVender(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableVenderMutation>(
      response.data.createLookupTableVender
    );
  }
  async UpdateLookupTableVender(
    input: UpdateLookupTableVenderInput
  ): Promise<UpdateLookupTableVenderMutation> {
    const statement = `mutation UpdateLookupTableVender($input: UpdateLookupTableVenderInput!) {
        updateLookupTableVender(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableVenderMutation>(
      response.data.updateLookupTableVender
    );
  }
  async DeleteLookupTableVender(
    input: DeleteLookupTableVenderInput
  ): Promise<DeleteLookupTableVenderMutation> {
    const statement = `mutation DeleteLookupTableVender($input: DeleteLookupTableVenderInput!) {
        deleteLookupTableVender(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableVenderMutation>(
      response.data.deleteLookupTableVender
    );
  }
  async CreateLookupTableUser(
    input: CreateLookupTableUserInput
  ): Promise<CreateLookupTableUserMutation> {
    const statement = `mutation CreateLookupTableUser($input: CreateLookupTableUserInput!) {
        createLookupTableUser(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableUserMutation>response.data.createLookupTableUser;
  }
  async UpdateLookupTableUser(
    input: UpdateLookupTableUserInput
  ): Promise<UpdateLookupTableUserMutation> {
    const statement = `mutation UpdateLookupTableUser($input: UpdateLookupTableUserInput!) {
        updateLookupTableUser(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableUserMutation>response.data.updateLookupTableUser;
  }
  async DeleteLookupTableUser(
    input: DeleteLookupTableUserInput
  ): Promise<DeleteLookupTableUserMutation> {
    const statement = `mutation DeleteLookupTableUser($input: DeleteLookupTableUserInput!) {
        deleteLookupTableUser(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableUserMutation>response.data.deleteLookupTableUser;
  }
  async CreateLookupTablePriority(
    input: CreateLookupTablePriorityInput
  ): Promise<CreateLookupTablePriorityMutation> {
    const statement = `mutation CreateLookupTablePriority($input: CreateLookupTablePriorityInput!) {
        createLookupTablePriority(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTablePriorityMutation>(
      response.data.createLookupTablePriority
    );
  }
  async UpdateLookupTablePriority(
    input: UpdateLookupTablePriorityInput
  ): Promise<UpdateLookupTablePriorityMutation> {
    const statement = `mutation UpdateLookupTablePriority($input: UpdateLookupTablePriorityInput!) {
        updateLookupTablePriority(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTablePriorityMutation>(
      response.data.updateLookupTablePriority
    );
  }
  async DeleteLookupTablePriority(
    input: DeleteLookupTablePriorityInput
  ): Promise<DeleteLookupTablePriorityMutation> {
    const statement = `mutation DeleteLookupTablePriority($input: DeleteLookupTablePriorityInput!) {
        deleteLookupTablePriority(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTablePriorityMutation>(
      response.data.deleteLookupTablePriority
    );
  }
  async CreateLookupTableFormParameter(
    input: CreateLookupTableFormParameterInput
  ): Promise<CreateLookupTableFormParameterMutation> {
    const statement = `mutation CreateLookupTableFormParameter($input: CreateLookupTableFormParameterInput!) {
        createLookupTableFormParameter(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableFormParameterMutation>(
      response.data.createLookupTableFormParameter
    );
  }
  async UpdateLookupTableFormParameter(
    input: UpdateLookupTableFormParameterInput
  ): Promise<UpdateLookupTableFormParameterMutation> {
    const statement = `mutation UpdateLookupTableFormParameter($input: UpdateLookupTableFormParameterInput!) {
        updateLookupTableFormParameter(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableFormParameterMutation>(
      response.data.updateLookupTableFormParameter
    );
  }
  async DeleteLookupTableFormParameter(
    input: DeleteLookupTableFormParameterInput
  ): Promise<DeleteLookupTableFormParameterMutation> {
    const statement = `mutation DeleteLookupTableFormParameter($input: DeleteLookupTableFormParameterInput!) {
        deleteLookupTableFormParameter(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableFormParameterMutation>(
      response.data.deleteLookupTableFormParameter
    );
  }
  async CreateLookupTableFormType(
    input: CreateLookupTableFormTypeInput
  ): Promise<CreateLookupTableFormTypeMutation> {
    const statement = `mutation CreateLookupTableFormType($input: CreateLookupTableFormTypeInput!) {
        createLookupTableFormType(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableFormTypeMutation>(
      response.data.createLookupTableFormType
    );
  }
  async UpdateLookupTableFormType(
    input: UpdateLookupTableFormTypeInput
  ): Promise<UpdateLookupTableFormTypeMutation> {
    const statement = `mutation UpdateLookupTableFormType($input: UpdateLookupTableFormTypeInput!) {
        updateLookupTableFormType(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableFormTypeMutation>(
      response.data.updateLookupTableFormType
    );
  }
  async DeleteLookupTableFormType(
    input: DeleteLookupTableFormTypeInput
  ): Promise<DeleteLookupTableFormTypeMutation> {
    const statement = `mutation DeleteLookupTableFormType($input: DeleteLookupTableFormTypeInput!) {
        deleteLookupTableFormType(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableFormTypeMutation>(
      response.data.deleteLookupTableFormType
    );
  }
  async CreateLookupTableShift(
    input: CreateLookupTableShiftInput
  ): Promise<CreateLookupTableShiftMutation> {
    const statement = `mutation CreateLookupTableShift($input: CreateLookupTableShiftInput!) {
        createLookupTableShift(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableShiftMutation>response.data.createLookupTableShift;
  }
  async UpdateLookupTableShift(
    input: UpdateLookupTableShiftInput
  ): Promise<UpdateLookupTableShiftMutation> {
    const statement = `mutation UpdateLookupTableShift($input: UpdateLookupTableShiftInput!) {
        updateLookupTableShift(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableShiftMutation>response.data.updateLookupTableShift;
  }
  async DeleteLookupTableShift(
    input: DeleteLookupTableShiftInput
  ): Promise<DeleteLookupTableShiftMutation> {
    const statement = `mutation DeleteLookupTableShift($input: DeleteLookupTableShiftInput!) {
        deleteLookupTableShift(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableShiftMutation>response.data.deleteLookupTableShift;
  }
  async CreateLookupTableModel(
    input: CreateLookupTableModelInput
  ): Promise<CreateLookupTableModelMutation> {
    const statement = `mutation CreateLookupTableModel($input: CreateLookupTableModelInput!) {
        createLookupTableModel(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableModelMutation>response.data.createLookupTableModel;
  }
  async UpdateLookupTableModel(
    input: UpdateLookupTableModelInput
  ): Promise<UpdateLookupTableModelMutation> {
    const statement = `mutation UpdateLookupTableModel($input: UpdateLookupTableModelInput!) {
        updateLookupTableModel(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableModelMutation>response.data.updateLookupTableModel;
  }
  async DeleteLookupTableModel(
    input: DeleteLookupTableModelInput
  ): Promise<DeleteLookupTableModelMutation> {
    const statement = `mutation DeleteLookupTableModel($input: DeleteLookupTableModelInput!) {
        deleteLookupTableModel(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableModelMutation>response.data.deleteLookupTableModel;
  }
  async CreateDreamBoard(
    input: CreateDreamBoardInput
  ): Promise<CreateDreamBoardMutation> {
    const statement = `mutation CreateDreamBoard($input: CreateDreamBoardInput!) {
        createDreamBoard(input: $input) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDreamBoardMutation>response.data.createDreamBoard;
  }
  async UpdateDreamBoard(
    input: UpdateDreamBoardInput
  ): Promise<UpdateDreamBoardMutation> {
    const statement = `mutation UpdateDreamBoard($input: UpdateDreamBoardInput!) {
        updateDreamBoard(input: $input) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDreamBoardMutation>response.data.updateDreamBoard;
  }
  async DeleteDreamBoard(
    input: DeleteDreamBoardInput
  ): Promise<DeleteDreamBoardMutation> {
    const statement = `mutation DeleteDreamBoard($input: DeleteDreamBoardInput!) {
        deleteDreamBoard(input: $input) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDreamBoardMutation>response.data.deleteDreamBoard;
  }
  async CreateLookupTableDreamBoard(
    input: CreateLookupTableDreamBoardInput
  ): Promise<CreateLookupTableDreamBoardMutation> {
    const statement = `mutation CreateLookupTableDreamBoard($input: CreateLookupTableDreamBoardInput!) {
        createLookupTableDreamBoard(input: $input) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupTableDreamBoardMutation>(
      response.data.createLookupTableDreamBoard
    );
  }
  async UpdateLookupTableDreamBoard(
    input: UpdateLookupTableDreamBoardInput
  ): Promise<UpdateLookupTableDreamBoardMutation> {
    const statement = `mutation UpdateLookupTableDreamBoard($input: UpdateLookupTableDreamBoardInput!) {
        updateLookupTableDreamBoard(input: $input) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupTableDreamBoardMutation>(
      response.data.updateLookupTableDreamBoard
    );
  }
  async DeleteLookupTableDreamBoard(
    input: DeleteLookupTableDreamBoardInput
  ): Promise<DeleteLookupTableDreamBoardMutation> {
    const statement = `mutation DeleteLookupTableDreamBoard($input: DeleteLookupTableDreamBoardInput!) {
        deleteLookupTableDreamBoard(input: $input) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupTableDreamBoardMutation>(
      response.data.deleteLookupTableDreamBoard
    );
  }
  async CreateLookupMasterTicketTable(
    input: CreateLookupMasterTicketTableInput
  ): Promise<CreateLookupMasterTicketTableMutation> {
    const statement = `mutation CreateLookupMasterTicketTable($input: CreateLookupMasterTicketTableInput!) {
        createLookupMasterTicketTable(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupMasterTicketTableMutation>(
      response.data.createLookupMasterTicketTable
    );
  }
  async UpdateLookupMasterTicketTable(
    input: UpdateLookupMasterTicketTableInput
  ): Promise<UpdateLookupMasterTicketTableMutation> {
    const statement = `mutation UpdateLookupMasterTicketTable($input: UpdateLookupMasterTicketTableInput!) {
        updateLookupMasterTicketTable(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupMasterTicketTableMutation>(
      response.data.updateLookupMasterTicketTable
    );
  }
  async DeleteLookupMasterTicketTable(
    input: DeleteLookupMasterTicketTableInput
  ): Promise<DeleteLookupMasterTicketTableMutation> {
    const statement = `mutation DeleteLookupMasterTicketTable($input: DeleteLookupMasterTicketTableInput!) {
        deleteLookupMasterTicketTable(input: $input) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupMasterTicketTableMutation>(
      response.data.deleteLookupMasterTicketTable
    );
  }
  async CreateLookupCamcDates(
    input: CreateLookupCamcDatesInput
  ): Promise<CreateLookupCamcDatesMutation> {
    const statement = `mutation CreateLookupCamcDates($input: CreateLookupCamcDatesInput!) {
        createLookupCamcDates(input: $input) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLookupCamcDatesMutation>response.data.createLookupCamcDates;
  }
  async UpdateLookupCamcDates(
    input: UpdateLookupCamcDatesInput
  ): Promise<UpdateLookupCamcDatesMutation> {
    const statement = `mutation UpdateLookupCamcDates($input: UpdateLookupCamcDatesInput!) {
        updateLookupCamcDates(input: $input) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLookupCamcDatesMutation>response.data.updateLookupCamcDates;
  }
  async DeleteLookupCamcDates(
    input: DeleteLookupCamcDatesInput
  ): Promise<DeleteLookupCamcDatesMutation> {
    const statement = `mutation DeleteLookupCamcDates($input: DeleteLookupCamcDatesInput!) {
        deleteLookupCamcDates(input: $input) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLookupCamcDatesMutation>response.data.deleteLookupCamcDates;
  }
  async CreateClientTable(
    input: CreateClientTableInput
  ): Promise<CreateClientTableMutation> {
    const statement = `mutation CreateClientTable($input: CreateClientTableInput!) {
        createClientTable(input: $input) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateClientTableMutation>response.data.createClientTable;
  }
  async UpdateClientTable(
    input: UpdateClientTableInput
  ): Promise<UpdateClientTableMutation> {
    const statement = `mutation UpdateClientTable($input: UpdateClientTableInput!) {
        updateClientTable(input: $input) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateClientTableMutation>response.data.updateClientTable;
  }
  async DeleteClientTable(
    input: DeleteClientTableInput
  ): Promise<DeleteClientTableMutation> {
    const statement = `mutation DeleteClientTable($input: DeleteClientTableInput!) {
        deleteClientTable(input: $input) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteClientTableMutation>response.data.deleteClientTable;
  }
  async CreateAuditrail(
    input: CreateAuditrailInput
  ): Promise<CreateAuditrailMutation> {
    const statement = `mutation CreateAuditrail($input: CreateAuditrailInput!) {
        createAuditrail(input: $input) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAuditrailMutation>response.data.createAuditrail;
  }
  async UpdateAuditrail(
    input: UpdateAuditrailInput
  ): Promise<UpdateAuditrailMutation> {
    const statement = `mutation UpdateAuditrail($input: UpdateAuditrailInput!) {
        updateAuditrail(input: $input) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAuditrailMutation>response.data.updateAuditrail;
  }
  async DeleteAuditrail(
    input: DeleteAuditrailInput
  ): Promise<DeleteAuditrailMutation> {
    const statement = `mutation DeleteAuditrail($input: DeleteAuditrailInput!) {
        deleteAuditrail(input: $input) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAuditrailMutation>response.data.deleteAuditrail;
  }
  async GetLocationType(
    location_type_id: string,
    client: string
  ): Promise<GetLocationTypeQuery> {
    const statement = `query GetLocationType($location_type_id: ID!, $client: String!) {
        getLocationType(location_type_id: $location_type_id, client: $client) {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      location_type_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLocationTypeQuery>response.data.getLocationType;
  }
  async ListLocationTypes(
    location_type_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelLocationTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListLocationTypesQuery> {
    const statement = `query ListLocationTypes($location_type_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelLocationTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listLocationTypes(
          location_type_id: $location_type_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            location_type_id
            Description
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (location_type_id) {
      gqlAPIServiceArguments.location_type_id = location_type_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocationTypesQuery>response.data.listLocationTypes;
  }
  async GetAssetLocations(
    asset_id: string,
    client: string,
    location_id: string
  ): Promise<GetAssetLocationsQuery> {
    const statement = `query GetAssetLocations($asset_id: ID!, $client: String!, $location_id: ID!) {
        getAssetLocations(
          asset_id: $asset_id
          client: $client
          location_id: $location_id
        ) {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      asset_id,
      client,
      location_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssetLocationsQuery>response.data.getAssetLocations;
  }
  async ListAssetLocations(
    asset_id?: string,
    clientLocation_id?: ModelAssetLocationsPrimaryCompositeKeyConditionInput,
    filter?: ModelAssetLocationsFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAssetLocationsQuery> {
    const statement = `query ListAssetLocations($asset_id: ID, $clientLocation_id: ModelAssetLocationsPrimaryCompositeKeyConditionInput, $filter: ModelAssetLocationsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAssetLocations(
          asset_id: $asset_id
          clientLocation_id: $clientLocation_id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            asset_id
            location_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (asset_id) {
      gqlAPIServiceArguments.asset_id = asset_id;
    }
    if (clientLocation_id) {
      gqlAPIServiceArguments.clientLocation_id = clientLocation_id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssetLocationsQuery>response.data.listAssetLocations;
  }
  async GetLocation(
    location_id: string,
    client: string
  ): Promise<GetLocationQuery> {
    const statement = `query GetLocation($location_id: ID!, $client: String!) {
        getLocation(location_id: $location_id, client: $client) {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      location_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLocationQuery>response.data.getLocation;
  }
  async ListLocations(
    location_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelLocationFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListLocationsQuery> {
    const statement = `query ListLocations($location_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelLocationFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listLocations(
          location_id: $location_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            location_id
            name
            location_type
            longitude
            latitude
            movable_latitude
            movable_longitude
            gmt_diff
            Metadata
            createdAt
            updatedAt
            locationTypeLocationsId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (location_id) {
      gqlAPIServiceArguments.location_id = location_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocationsQuery>response.data.listLocations;
  }
  async GetPriority(
    priority_id: string,
    client: string
  ): Promise<GetPriorityQuery> {
    const statement = `query GetPriority($priority_id: ID!, $client: String!) {
        getPriority(priority_id: $priority_id, client: $client) {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      priority_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPriorityQuery>response.data.getPriority;
  }
  async ListPriorities(
    priority_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelPriorityFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListPrioritiesQuery> {
    const statement = `query ListPriorities($priority_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelPriorityFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listPriorities(
          priority_id: $priority_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            priority_id
            description
            metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (priority_id) {
      gqlAPIServiceArguments.priority_id = priority_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPrioritiesQuery>response.data.listPriorities;
  }
  async GetProblem(
    problem_id: string,
    client: string
  ): Promise<GetProblemQuery> {
    const statement = `query GetProblem($problem_id: ID!, $client: String!) {
        getProblem(problem_id: $problem_id, client: $client) {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      problem_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProblemQuery>response.data.getProblem;
  }
  async ListProblems(
    problem_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelProblemFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListProblemsQuery> {
    const statement = `query ListProblems($problem_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelProblemFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listProblems(
          problem_id: $problem_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            problem_id
            Origin_Status
            priority
            description
            detail
            prob_time
            notif_policy_rem
            escalation_lvl
            comment
            comment_Header
            comment_Suffix
            comment_Prefix
            technician_Comment
            impact
            createdAt
            updatedAt
            metadata
            priorityProblemsId
            items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (problem_id) {
      gqlAPIServiceArguments.problem_id = problem_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProblemsQuery>response.data.listProblems;
  }
  async GetAmProblems(
    assetmap_id: string,
    client: string,
    problem_id: string
  ): Promise<GetAmProblemsQuery> {
    const statement = `query GetAmProblems($assetmap_id: ID!, $client: String!, $problem_id: ID!) {
        getAmProblems(
          assetmap_id: $assetmap_id
          client: $client
          problem_id: $problem_id
        ) {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assetmap_id,
      client,
      problem_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAmProblemsQuery>response.data.getAmProblems;
  }
  async ListAmProblems(
    assetmap_id?: string,
    clientProblem_id?: ModelAmProblemsPrimaryCompositeKeyConditionInput,
    filter?: ModelAmProblemsFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAmProblemsQuery> {
    const statement = `query ListAmProblems($assetmap_id: ID, $clientProblem_id: ModelAmProblemsPrimaryCompositeKeyConditionInput, $filter: ModelAmProblemsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAmProblems(
          assetmap_id: $assetmap_id
          clientProblem_id: $clientProblem_id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            assetmap_id
            problem_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assetmap_id) {
      gqlAPIServiceArguments.assetmap_id = assetmap_id;
    }
    if (clientProblem_id) {
      gqlAPIServiceArguments.clientProblem_id = clientProblem_id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAmProblemsQuery>response.data.listAmProblems;
  }
  async GetAssetMap(
    assetmap_id: string,
    client: string
  ): Promise<GetAssetMapQuery> {
    const statement = `query GetAssetMap($assetmap_id: ID!, $client: String!) {
        getAssetMap(assetmap_id: $assetmap_id, client: $client) {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assetmap_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssetMapQuery>response.data.getAssetMap;
  }
  async ListAssetMaps(
    assetmap_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelAssetMapFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAssetMapsQuery> {
    const statement = `query ListAssetMaps($assetmap_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelAssetMapFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAssetMaps(
          assetmap_id: $assetmap_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            assetmap_id
            problems
            Description
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assetmap_id) {
      gqlAPIServiceArguments.assetmap_id = assetmap_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssetMapsQuery>response.data.listAssetMaps;
  }
  async GetManufacturer(
    manufacturer_id: string,
    client: string
  ): Promise<GetManufacturerQuery> {
    const statement = `query GetManufacturer($manufacturer_id: ID!, $client: String!) {
        getManufacturer(manufacturer_id: $manufacturer_id, client: $client) {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      manufacturer_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetManufacturerQuery>response.data.getManufacturer;
  }
  async ListManufacturers(
    manufacturer_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelManufacturerFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListManufacturersQuery> {
    const statement = `query ListManufacturers($manufacturer_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelManufacturerFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listManufacturers(
          manufacturer_id: $manufacturer_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            manufacturer_id
            Description
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (manufacturer_id) {
      gqlAPIServiceArguments.manufacturer_id = manufacturer_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListManufacturersQuery>response.data.listManufacturers;
  }
  async GetModel(model_id: string, client: string): Promise<GetModelQuery> {
    const statement = `query GetModel($model_id: ID!, $client: String!) {
        getModel(model_id: $model_id, client: $client) {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      model_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetModelQuery>response.data.getModel;
  }
  async ListModels(
    model_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelModelFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListModelsQuery> {
    const statement = `query ListModels($model_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelModelFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listModels(
          model_id: $model_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            model_id
            Description
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (model_id) {
      gqlAPIServiceArguments.model_id = model_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListModelsQuery>response.data.listModels;
  }
  async GetDepartment(
    dept_id: string,
    client: string
  ): Promise<GetDepartmentQuery> {
    const statement = `query GetDepartment($dept_id: ID!, $client: String!) {
        getDepartment(dept_id: $dept_id, client: $client) {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dept_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDepartmentQuery>response.data.getDepartment;
  }
  async ListDepartments(
    dept_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelDepartmentFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDepartmentsQuery> {
    const statement = `query ListDepartments($dept_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelDepartmentFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDepartments(
          dept_id: $dept_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            dept_id
            Description
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (dept_id) {
      gqlAPIServiceArguments.dept_id = dept_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDepartmentsQuery>response.data.listDepartments;
  }
  async GetSubDepartment(
    sub_dept_id: string,
    client: string
  ): Promise<GetSubDepartmentQuery> {
    const statement = `query GetSubDepartment($sub_dept_id: ID!, $client: String!) {
        getSubDepartment(sub_dept_id: $sub_dept_id, client: $client) {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      sub_dept_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubDepartmentQuery>response.data.getSubDepartment;
  }
  async ListSubDepartments(
    sub_dept_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelSubDepartmentFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListSubDepartmentsQuery> {
    const statement = `query ListSubDepartments($sub_dept_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelSubDepartmentFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listSubDepartments(
          sub_dept_id: $sub_dept_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            sub_dept_id
            Description
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (sub_dept_id) {
      gqlAPIServiceArguments.sub_dept_id = sub_dept_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubDepartmentsQuery>response.data.listSubDepartments;
  }
  async GetVendorCompany(
    vendor_id: string,
    client: string
  ): Promise<GetVendorCompanyQuery> {
    const statement = `query GetVendorCompany($vendor_id: ID!, $client: String!) {
        getVendorCompany(vendor_id: $vendor_id, client: $client) {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendor_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVendorCompanyQuery>response.data.getVendorCompany;
  }
  async ListVendorCompanies(
    vendor_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelVendorCompanyFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListVendorCompaniesQuery> {
    const statement = `query ListVendorCompanies($vendor_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelVendorCompanyFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listVendorCompanies(
          vendor_id: $vendor_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            vendor_id
            name
            Description
            email
            ph_no
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vendor_id) {
      gqlAPIServiceArguments.vendor_id = vendor_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVendorCompaniesQuery>response.data.listVendorCompanies;
  }
  async GetRole(role_id: string, client: string): Promise<GetRoleQuery> {
    const statement = `query GetRole($role_id: ID!, $client: String!) {
        getRole(role_id: $role_id, client: $client) {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      role_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRoleQuery>response.data.getRole;
  }
  async ListRoles(
    role_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelRoleFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListRolesQuery> {
    const statement = `query ListRoles($role_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelRoleFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listRoles(
          role_id: $role_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            role_id
            Description
            time_mult
            Metadata
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (role_id) {
      gqlAPIServiceArguments.role_id = role_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRolesQuery>response.data.listRoles;
  }
  async GetUserLocations(
    user_id: string,
    client: string,
    location_id: string
  ): Promise<GetUserLocationsQuery> {
    const statement = `query GetUserLocations($user_id: String!, $client: String!, $location_id: String!) {
        getUserLocations(user_id: $user_id, client: $client, location_id: $location_id) {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      user_id,
      client,
      location_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserLocationsQuery>response.data.getUserLocations;
  }
  async ListUserLocations(
    user_id?: string,
    clientLocation_id?: ModelUserLocationsPrimaryCompositeKeyConditionInput,
    filter?: ModelUserLocationsFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListUserLocationsQuery> {
    const statement = `query ListUserLocations($user_id: String, $clientLocation_id: ModelUserLocationsPrimaryCompositeKeyConditionInput, $filter: ModelUserLocationsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listUserLocations(
          user_id: $user_id
          clientLocation_id: $clientLocation_id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            user_id
            location_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (user_id) {
      gqlAPIServiceArguments.user_id = user_id;
    }
    if (clientLocation_id) {
      gqlAPIServiceArguments.clientLocation_id = clientLocation_id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserLocationsQuery>response.data.listUserLocations;
  }
  async GetAssetUserNotify(
    asset_id: string,
    client: string,
    user_id: string
  ): Promise<GetAssetUserNotifyQuery> {
    const statement = `query GetAssetUserNotify($asset_id: ID!, $client: String!, $user_id: ID!) {
        getAssetUserNotify(asset_id: $asset_id, client: $client, user_id: $user_id) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      asset_id,
      client,
      user_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssetUserNotifyQuery>response.data.getAssetUserNotify;
  }
  async ListAssetUserNotifies(
    asset_id?: string,
    clientUser_id?: ModelAssetUserNotifyPrimaryCompositeKeyConditionInput,
    filter?: ModelAssetUserNotifyFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAssetUserNotifiesQuery> {
    const statement = `query ListAssetUserNotifies($asset_id: ID, $clientUser_id: ModelAssetUserNotifyPrimaryCompositeKeyConditionInput, $filter: ModelAssetUserNotifyFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAssetUserNotifies(
          asset_id: $asset_id
          clientUser_id: $clientUser_id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            user_id
            asset_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (asset_id) {
      gqlAPIServiceArguments.asset_id = asset_id;
    }
    if (clientUser_id) {
      gqlAPIServiceArguments.clientUser_id = clientUser_id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssetUserNotifiesQuery>response.data.listAssetUserNotifies;
  }
  async GetAssetOwner(
    asset_id: string,
    client: string,
    user_id: string
  ): Promise<GetAssetOwnerQuery> {
    const statement = `query GetAssetOwner($asset_id: ID!, $client: String!, $user_id: ID!) {
        getAssetOwner(asset_id: $asset_id, client: $client, user_id: $user_id) {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      asset_id,
      client,
      user_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssetOwnerQuery>response.data.getAssetOwner;
  }
  async ListAssetOwners(
    asset_id?: string,
    clientUser_id?: ModelAssetOwnerPrimaryCompositeKeyConditionInput,
    filter?: ModelAssetOwnerFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAssetOwnersQuery> {
    const statement = `query ListAssetOwners($asset_id: ID, $clientUser_id: ModelAssetOwnerPrimaryCompositeKeyConditionInput, $filter: ModelAssetOwnerFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAssetOwners(
          asset_id: $asset_id
          clientUser_id: $clientUser_id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            user_id
            asset_id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (asset_id) {
      gqlAPIServiceArguments.asset_id = asset_id;
    }
    if (clientUser_id) {
      gqlAPIServiceArguments.clientUser_id = clientUser_id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssetOwnersQuery>response.data.listAssetOwners;
  }
  async GetUser(user_id: string, client: string): Promise<GetUserQuery> {
    const statement = `query GetUser($user_id: ID!, $client: String!) {
        getUser(user_id: $user_id, client: $client) {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`;
    const gqlAPIServiceArguments: any = {
      user_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    user_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($user_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listUsers(
          user_id: $user_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            user_id
            name
            username
            Description
            Metadata
            mobile
            email
            user_type
            mobile_privacy
            alert_email
            alert_sms
            esc_email
            esc_sms
            role
            department
            sub_department
            createdAt
            updatedAt
            departmentUsersId
            subDepartmentUsersId
            roleUsersId
            userReporting_toId
            formcreate
            formread
            formupdate
            formdelete
            imageurl
            formuserpermission
            notification_token
            telegramID
            telegram
            push_notification
            sms
            permission
            chat_Bot
            mobile_permission
            man_per_hour_cost
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (user_id) {
      gqlAPIServiceArguments.user_id = user_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetAssetAddition(
    tag_id: string,
    client: string,
    asset_id: string
  ): Promise<GetAssetAdditionQuery> {
    const statement = `query GetAssetAddition($tag_id: ID!, $client: String!, $asset_id: ID!) {
        getAssetAddition(tag_id: $tag_id, client: $client, asset_id: $asset_id) {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`;
    const gqlAPIServiceArguments: any = {
      tag_id,
      client,
      asset_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssetAdditionQuery>response.data.getAssetAddition;
  }
  async ListAssetAdditions(
    tag_id?: string,
    clientAsset_id?: ModelAssetAdditionPrimaryCompositeKeyConditionInput,
    filter?: ModelAssetAdditionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListAssetAdditionsQuery> {
    const statement = `query ListAssetAdditions($tag_id: ID, $clientAsset_id: ModelAssetAdditionPrimaryCompositeKeyConditionInput, $filter: ModelAssetAdditionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listAssetAdditions(
          tag_id: $tag_id
          clientAsset_id: $clientAsset_id
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            tag_id
            asset_map
            user_notify_id
            user_notify
            tech_multi_id
            multiple_technician
            asset_id
            model
            sub_department
            department
            vendor_company
            manufacturer
            location
            next_service
            last_service
            installation_date
            amc_expiry
            group
            bought_price
            current_price
            asset_type
            metadata
            tech_id
            technician
            createdAt
            updatedAt
            locationAssetsId
            assetMapAssetsId
            manufacturerAssetsId
            modelAssetsId
            departmentAssetsId
            subDepartmentAssetsId
            vendorCompanyAssetsId
            formpermission
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tag_id) {
      gqlAPIServiceArguments.tag_id = tag_id;
    }
    if (clientAsset_id) {
      gqlAPIServiceArguments.clientAsset_id = clientAsset_id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssetAdditionsQuery>response.data.listAssetAdditions;
  }
  async GetTickets(
    ticket_id: string,
    client: string
  ): Promise<GetTicketsQuery> {
    const statement = `query GetTickets($ticket_id: ID!, $client: String!) {
        getTickets(ticket_id: $ticket_id, client: $client) {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`;
    const gqlAPIServiceArguments: any = {
      ticket_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketsQuery>response.data.getTickets;
  }
  async ListTickets(
    ticket_id?: string,
    client?: ModelStringKeyConditionInput,
    filter?: ModelTicketsFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTicketsQuery> {
    const statement = `query ListTickets($ticket_id: ID, $client: ModelStringKeyConditionInput, $filter: ModelTicketsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTickets(
          ticket_id: $ticket_id
          client: $client
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            client
            ticket_id
            tag_id
            asset_add
            asset_type_id
            problem_id
            problem
            user
            current_status
            metadata
            current_esc_lvl
            comment
            notification_sent_flag
            esc_users
            total_time
            Problem_time
            max_esc_reached
            createdAt
            max_esc_lvl
            technician_id
            mutli_assign
            updatedAt
            progress_update
            solved_update
            closed_update
            userTicketsId
            assetAdditionTicketsId
            moveble_latitude
            moveble_longitude
            status_progress
            status_solved
            status_closed
            other
            rating
            otp
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (ticket_id) {
      gqlAPIServiceArguments.ticket_id = ticket_id;
    }
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketsQuery>response.data.listTickets;
  }
  async GetSavedTags(
    client: string,
    userIdTagId: string
  ): Promise<GetSavedTagsQuery> {
    const statement = `query GetSavedTags($client: String!, $userIdTagId: String!) {
        getSavedTags(client: $client, userIdTagId: $userIdTagId) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      client,
      userIdTagId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSavedTagsQuery>response.data.getSavedTags;
  }
  async ListSavedTags(
    filter?: TableSavedTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSavedTagsQuery> {
    const statement = `query ListSavedTags($filter: TableSavedTagsFilterInput, $limit: Int, $nextToken: String) {
        listSavedTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client
            userIdTagId
            userId
            tagId
            createdAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSavedTagsQuery>response.data.listSavedTags;
  }
  async GetTicketImages(
    client: string,
    ticket_id: string
  ): Promise<GetTicketImagesQuery> {
    const statement = `query GetTicketImages($client: String!, $ticket_id: String!) {
        getTicketImages(client: $client, ticket_id: $ticket_id) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {
      client,
      ticket_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTicketImagesQuery>response.data.getTicketImages;
  }
  async ListTicketImages(
    filter?: TableTicketImagesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTicketImagesQuery> {
    const statement = `query ListTicketImages($filter: TableTicketImagesFilterInput, $limit: Int, $nextToken: String) {
        listTicketImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client
            ticket_id
            images
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTicketImagesQuery>response.data.listTicketImages;
  }
  async GetFormParameter(
    parameterID: string,
    client: string
  ): Promise<GetFormParameterQuery> {
    const statement = `query GetFormParameter($parameterID: String!, $client: String!) {
        getFormParameter(parameterID: $parameterID, client: $client) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parameterID,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFormParameterQuery>response.data.getFormParameter;
  }
  async ListFormParameters(
    filter?: TableFormParameterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFormParametersQuery> {
    const statement = `query ListFormParameters($filter: TableFormParameterFilterInput, $limit: Int, $nextToken: String) {
        listFormParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client
            parameterID
            other
            formdefault
            createdAt
            updatedAt
            parameterDescription
            parameterLable
            parameterType
            placeholder
            require
            value_1
            value_2
            list
            name
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFormParametersQuery>response.data.listFormParameters;
  }
  async GetFormType(typeID: string, client: string): Promise<GetFormTypeQuery> {
    const statement = `query GetFormType($typeID: String!, $client: String!) {
        getFormType(typeID: $typeID, client: $client) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {
      typeID,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFormTypeQuery>response.data.getFormType;
  }
  async ListFormTypes(
    filter?: TableFormTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFormTypesQuery> {
    const statement = `query ListFormTypes($filter: TableFormTypeFilterInput, $limit: Int, $nextToken: String) {
        listFormTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client
            typeID
            typeLable
            typeDescription
            emailSubject
            emailHeader
            emailBody
            emailFooter
            other
            emailEnable
            emailRecipent
            typeparameterID
            formIDvisible
            lablevisible
            descriptionvisible
            updatedAt
            createdAt
            mqtt_id
            iot_connect
            reserved
            block_time
            scan
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFormTypesQuery>response.data.listFormTypes;
  }
  async GetTask(formID: string, clientID: string): Promise<GetTaskQuery> {
    const statement = `query GetTask($formID: String!, $clientID: String!) {
        getTask(formID: $formID, clientID: $clientID) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formID,
      clientID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaskQuery>response.data.getTask;
  }
  async ListTasks(
    filter?: TableTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTasksQuery> {
    const statement = `query ListTasks($filter: TableTaskFilterInput, $limit: Int, $nextToken: String) {
        listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            clientID
            formID
            createdAt
            updatedAt
            created_Username
            updated_Username
            typeDes
            typelable
            parameters_data
            formDescription_visibilty
            formID_visibility
            formlable_visibility
            formTypeID
            label
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTasksQuery>response.data.listTasks;
  }
  async GetMqttConfiguration(
    mqtt_id: string,
    client: string
  ): Promise<GetMqttConfigurationQuery> {
    const statement = `query GetMqttConfiguration($mqtt_id: String!, $client: String!) {
        getMqttConfiguration(mqtt_id: $mqtt_id, client: $client) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {
      mqtt_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMqttConfigurationQuery>response.data.getMqttConfiguration;
  }
  async ListMqttConfigurations(
    filter?: TableMqttConfigurationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMqttConfigurationsQuery> {
    const statement = `query ListMqttConfigurations($filter: TableMqttConfigurationFilterInput, $limit: Int, $nextToken: String) {
        listMqttConfigurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client
            mqtt_id
            name
            host
            url
            port
            username
            password
            topic
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMqttConfigurationsQuery>response.data.listMqttConfigurations;
  }
  async GetShiftTable(
    shift_id: string,
    client: string
  ): Promise<GetShiftTableQuery> {
    const statement = `query GetShiftTable($shift_id: String!, $client: String!) {
        getShiftTable(shift_id: $shift_id, client: $client) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {
      shift_id,
      client
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetShiftTableQuery>response.data.getShiftTable;
  }
  async ListShiftTables(
    filter?: TableShiftTableFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListShiftTablesQuery> {
    const statement = `query ListShiftTables($filter: TableShiftTableFilterInput, $limit: Int, $nextToken: String) {
        listShiftTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client
            shift_id
            form_time
            to_time
            shifts
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListShiftTablesQuery>response.data.listShiftTables;
  }
  async GetDashboard(
    dashboard_id: string,
    client_id: string
  ): Promise<GetDashboardQuery> {
    const statement = `query GetDashboard($dashboard_id: String!, $client_id: String!) {
        getDashboard(dashboard_id: $dashboard_id, client_id: $client_id) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      dashboard_id,
      client_id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDashboardQuery>response.data.getDashboard;
  }
  async ListDashboards(
    filter?: TableDashboardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDashboardsQuery> {
    const statement = `query ListDashboards($filter: TableDashboardFilterInput, $limit: Int, $nextToken: String) {
        listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            client_id
            dashboard_id
            tile
            latest_10_tickets
            latest_10_user_ratings
            upcoming_event
            Graph_data
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDashboardsQuery>response.data.listDashboards;
  }
  async GetNotificationTable(
    username_client: string,
    createdDate: string
  ): Promise<GetNotificationTableQuery> {
    const statement = `query GetNotificationTable($username_client: String!, $createdDate: String!) {
        getNotificationTable(
          username_client: $username_client
          createdDate: $createdDate
        ) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username_client,
      createdDate
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationTableQuery>response.data.getNotificationTable;
  }
  async ListNotificationTables(
    filter?: TableNotificationTableFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotificationTablesQuery> {
    const statement = `query ListNotificationTables($filter: TableNotificationTableFilterInput, $limit: Int, $nextToken: String) {
        listNotificationTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdDate
            username_client
            main_notification_seen_flag
            message
            type
            ticket_id
            icon
            color
            task_id
            Problem
            formtype
            created_time
            update_time
            seen_flag
            ticket_info
            task_info
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationTablesQuery>response.data.listNotificationTables;
  }
  async GetLookupTableTicket(
    PK: string,
    SK: number
  ): Promise<GetLookupTableTicketQuery> {
    const statement = `query GetLookupTableTicket($PK: String!, $SK: Int!) {
        getLookupTableTicket(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableTicketQuery>response.data.getLookupTableTicket;
  }
  async ListLookupTableTickets(
    filter?: TableLookupTableTicketFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableTicketsQuery> {
    const statement = `query ListLookupTableTickets($filter: TableLookupTableTicketFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableTicketsQuery>response.data.listLookupTableTickets;
  }
  async GetLookupTableTask(
    PK: string,
    SK: number
  ): Promise<GetLookupTableTaskQuery> {
    const statement = `query GetLookupTableTask($PK: String!, $SK: Int!) {
        getLookupTableTask(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableTaskQuery>response.data.getLookupTableTask;
  }
  async ListLookupTableTasks(
    filter?: TableLookupTableTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableTasksQuery> {
    const statement = `query ListLookupTableTasks($filter: TableLookupTableTaskFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableTasksQuery>response.data.listLookupTableTasks;
  }
  async GetLookupTableNotification(
    PK: string,
    SK: number
  ): Promise<GetLookupTableNotificationQuery> {
    const statement = `query GetLookupTableNotification($PK: String!, $SK: Int!) {
        getLookupTableNotification(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableNotificationQuery>(
      response.data.getLookupTableNotification
    );
  }
  async ListLookupTableNotifications(
    filter?: TableLookupTableNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableNotificationsQuery> {
    const statement = `query ListLookupTableNotifications($filter: TableLookupTableNotificationFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableNotifications(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableNotificationsQuery>(
      response.data.listLookupTableNotifications
    );
  }
  async GetTaskTest(
    formID: string,
    clientID: string
  ): Promise<GetTaskTestQuery> {
    const statement = `query GetTaskTest($formID: String!, $clientID: String!) {
        getTaskTest(formID: $formID, clientID: $clientID) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formID,
      clientID
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaskTestQuery>response.data.getTaskTest;
  }
  async ListTaskTests(
    filter?: TableTaskTestFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaskTestsQuery> {
    const statement = `query ListTaskTests($filter: TableTaskTestFilterInput, $limit: Int, $nextToken: String) {
        listTaskTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            clientID
            formID
            createdAt
            updatedAt
            created_Username
            updated_Username
            typeDes
            typelable
            parameters_data
            formDescription_visibilty
            formID_visibility
            formlable_visibility
            formTypeID
            iotDateTime
            startDateTime
            endDateTime
            blockDateTime
            label
            device
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaskTestsQuery>response.data.listTaskTests;
  }
  async GetLookupTaskTest(
    PK: string,
    SK: number
  ): Promise<GetLookupTaskTestQuery> {
    const statement = `query GetLookupTaskTest($PK: String!, $SK: Int!) {
        getLookupTaskTest(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTaskTestQuery>response.data.getLookupTaskTest;
  }
  async ListLookupTaskTests(
    filter?: TableLookupTaskTestFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTaskTestsQuery> {
    const statement = `query ListLookupTaskTests($filter: TableLookupTaskTestFilterInput, $limit: Int, $nextToken: String) {
        listLookupTaskTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTaskTestsQuery>response.data.listLookupTaskTests;
  }
  async GetLookupTableProblem(
    PK: string,
    SK: number
  ): Promise<GetLookupTableProblemQuery> {
    const statement = `query GetLookupTableProblem($PK: String!, $SK: Int!) {
        getLookupTableProblem(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableProblemQuery>response.data.getLookupTableProblem;
  }
  async ListLookupTableProblems(
    filter?: TableLookupTableProblemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableProblemsQuery> {
    const statement = `query ListLookupTableProblems($filter: TableLookupTableProblemFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableProblems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableProblemsQuery>response.data.listLookupTableProblems;
  }
  async GetLookupTableAssetMap(
    PK: string,
    SK: number
  ): Promise<GetLookupTableAssetMapQuery> {
    const statement = `query GetLookupTableAssetMap($PK: String!, $SK: Int!) {
        getLookupTableAssetMap(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableAssetMapQuery>response.data.getLookupTableAssetMap;
  }
  async ListLookupTableAssetMaps(
    filter?: TableLookupTableAssetMapFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableAssetMapsQuery> {
    const statement = `query ListLookupTableAssetMaps($filter: TableLookupTableAssetMapFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableAssetMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableAssetMapsQuery>(
      response.data.listLookupTableAssetMaps
    );
  }
  async GetLookupTableAssetAddition(
    PK: string,
    SK: number
  ): Promise<GetLookupTableAssetAdditionQuery> {
    const statement = `query GetLookupTableAssetAddition($PK: String!, $SK: Int!) {
        getLookupTableAssetAddition(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableAssetAdditionQuery>(
      response.data.getLookupTableAssetAddition
    );
  }
  async ListLookupTableAssetAdditions(
    filter?: TableLookupTableAssetAdditionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableAssetAdditionsQuery> {
    const statement = `query ListLookupTableAssetAdditions($filter: TableLookupTableAssetAdditionFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableAssetAdditions(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableAssetAdditionsQuery>(
      response.data.listLookupTableAssetAdditions
    );
  }
  async GetLookupTableRole(
    PK: string,
    SK: number
  ): Promise<GetLookupTableRoleQuery> {
    const statement = `query GetLookupTableRole($PK: String!, $SK: Int!) {
        getLookupTableRole(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableRoleQuery>response.data.getLookupTableRole;
  }
  async ListLookupTableRoles(
    filter?: TableLookupTableRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableRolesQuery> {
    const statement = `query ListLookupTableRoles($filter: TableLookupTableRoleFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableRolesQuery>response.data.listLookupTableRoles;
  }
  async GetLookupTableDepartment(
    PK: string,
    SK: number
  ): Promise<GetLookupTableDepartmentQuery> {
    const statement = `query GetLookupTableDepartment($PK: String!, $SK: Int!) {
        getLookupTableDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableDepartmentQuery>(
      response.data.getLookupTableDepartment
    );
  }
  async ListLookupTableDepartments(
    filter?: TableLookupTableDepartmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableDepartmentsQuery> {
    const statement = `query ListLookupTableDepartments($filter: TableLookupTableDepartmentFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableDepartments(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableDepartmentsQuery>(
      response.data.listLookupTableDepartments
    );
  }
  async GetLookupTableSubDepartment(
    PK: string,
    SK: number
  ): Promise<GetLookupTableSubDepartmentQuery> {
    const statement = `query GetLookupTableSubDepartment($PK: String!, $SK: Int!) {
        getLookupTableSubDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableSubDepartmentQuery>(
      response.data.getLookupTableSubDepartment
    );
  }
  async ListLookupTableSubDepartments(
    filter?: TableLookupTableSubDepartmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableSubDepartmentsQuery> {
    const statement = `query ListLookupTableSubDepartments($filter: TableLookupTableSubDepartmentFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableSubDepartments(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableSubDepartmentsQuery>(
      response.data.listLookupTableSubDepartments
    );
  }
  async GetLookupTableLocation(
    PK: string,
    SK: number
  ): Promise<GetLookupTableLocationQuery> {
    const statement = `query GetLookupTableLocation($PK: String!, $SK: Int!) {
        getLookupTableLocation(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableLocationQuery>response.data.getLookupTableLocation;
  }
  async ListLookupTableLocations(
    filter?: TableLookupTableLocationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableLocationsQuery> {
    const statement = `query ListLookupTableLocations($filter: TableLookupTableLocationFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableLocationsQuery>(
      response.data.listLookupTableLocations
    );
  }
  async GetLookupTableLocationType(
    PK: string,
    SK: number
  ): Promise<GetLookupTableLocationTypeQuery> {
    const statement = `query GetLookupTableLocationType($PK: String!, $SK: Int!) {
        getLookupTableLocationType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableLocationTypeQuery>(
      response.data.getLookupTableLocationType
    );
  }
  async ListLookupTableLocationTypes(
    filter?: TableLookupTableLocationTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableLocationTypesQuery> {
    const statement = `query ListLookupTableLocationTypes($filter: TableLookupTableLocationTypeFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableLocationTypes(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableLocationTypesQuery>(
      response.data.listLookupTableLocationTypes
    );
  }
  async GetLookupTableManufacture(
    PK: string,
    SK: number
  ): Promise<GetLookupTableManufactureQuery> {
    const statement = `query GetLookupTableManufacture($PK: String!, $SK: Int!) {
        getLookupTableManufacture(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableManufactureQuery>(
      response.data.getLookupTableManufacture
    );
  }
  async ListLookupTableManufactures(
    filter?: TableLookupTableManufactureFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableManufacturesQuery> {
    const statement = `query ListLookupTableManufactures($filter: TableLookupTableManufactureFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableManufactures(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableManufacturesQuery>(
      response.data.listLookupTableManufactures
    );
  }
  async GetLookupTableVender(
    PK: string,
    SK: number
  ): Promise<GetLookupTableVenderQuery> {
    const statement = `query GetLookupTableVender($PK: String!, $SK: Int!) {
        getLookupTableVender(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableVenderQuery>response.data.getLookupTableVender;
  }
  async ListLookupTableVenders(
    filter?: TableLookupTableVenderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableVendersQuery> {
    const statement = `query ListLookupTableVenders($filter: TableLookupTableVenderFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableVenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableVendersQuery>response.data.listLookupTableVenders;
  }
  async GetLookupTableUser(
    PK: string,
    SK: number
  ): Promise<GetLookupTableUserQuery> {
    const statement = `query GetLookupTableUser($PK: String!, $SK: Int!) {
        getLookupTableUser(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableUserQuery>response.data.getLookupTableUser;
  }
  async ListLookupTableUsers(
    filter?: TableLookupTableUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableUsersQuery> {
    const statement = `query ListLookupTableUsers($filter: TableLookupTableUserFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableUsersQuery>response.data.listLookupTableUsers;
  }
  async GetLookupTablePriority(
    PK: string,
    SK: number
  ): Promise<GetLookupTablePriorityQuery> {
    const statement = `query GetLookupTablePriority($PK: String!, $SK: Int!) {
        getLookupTablePriority(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTablePriorityQuery>response.data.getLookupTablePriority;
  }
  async ListLookupTablePriorities(
    filter?: TableLookupTablePriorityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTablePrioritiesQuery> {
    const statement = `query ListLookupTablePriorities($filter: TableLookupTablePriorityFilterInput, $limit: Int, $nextToken: String) {
        listLookupTablePriorities(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTablePrioritiesQuery>(
      response.data.listLookupTablePriorities
    );
  }
  async GetLookupTableFormParameter(
    PK: string,
    SK: number
  ): Promise<GetLookupTableFormParameterQuery> {
    const statement = `query GetLookupTableFormParameter($PK: String!, $SK: Int!) {
        getLookupTableFormParameter(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableFormParameterQuery>(
      response.data.getLookupTableFormParameter
    );
  }
  async ListLookupTableFormParameters(
    filter?: TableLookupTableFormParameterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableFormParametersQuery> {
    const statement = `query ListLookupTableFormParameters($filter: TableLookupTableFormParameterFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableFormParameters(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableFormParametersQuery>(
      response.data.listLookupTableFormParameters
    );
  }
  async GetLookupTableFormType(
    PK: string,
    SK: number
  ): Promise<GetLookupTableFormTypeQuery> {
    const statement = `query GetLookupTableFormType($PK: String!, $SK: Int!) {
        getLookupTableFormType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableFormTypeQuery>response.data.getLookupTableFormType;
  }
  async ListLookupTableFormTypes(
    filter?: TableLookupTableFormTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableFormTypesQuery> {
    const statement = `query ListLookupTableFormTypes($filter: TableLookupTableFormTypeFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableFormTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableFormTypesQuery>(
      response.data.listLookupTableFormTypes
    );
  }
  async GetLookupTableShift(
    PK: string,
    SK: number
  ): Promise<GetLookupTableShiftQuery> {
    const statement = `query GetLookupTableShift($PK: String!, $SK: Int!) {
        getLookupTableShift(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableShiftQuery>response.data.getLookupTableShift;
  }
  async ListLookupTableShifts(
    filter?: TableLookupTableShiftFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableShiftsQuery> {
    const statement = `query ListLookupTableShifts($filter: TableLookupTableShiftFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableShiftsQuery>response.data.listLookupTableShifts;
  }
  async GetLookupTableModel(
    PK: string,
    SK: number
  ): Promise<GetLookupTableModelQuery> {
    const statement = `query GetLookupTableModel($PK: String!, $SK: Int!) {
        getLookupTableModel(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableModelQuery>response.data.getLookupTableModel;
  }
  async ListLookupTableModels(
    filter?: TableLookupTableModelFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableModelsQuery> {
    const statement = `query ListLookupTableModels($filter: TableLookupTableModelFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableModelsQuery>response.data.listLookupTableModels;
  }
  async GetDreamBoard(PK: string, SK: string): Promise<GetDreamBoardQuery> {
    const statement = `query GetDreamBoard($PK: String!, $SK: String!) {
        getDreamBoard(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDreamBoardQuery>response.data.getDreamBoard;
  }
  async ListDreamBoards(
    filter?: TableDreamBoardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDreamBoardsQuery> {
    const statement = `query ListDreamBoards($filter: TableDreamBoardFilterInput, $limit: Int, $nextToken: String) {
        listDreamBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            name
            description
            board_type
            settings
            rdt
            devices
            type
            onLoadJS
            onUpdateJS
            HTML
            createdTime
            updatedTime
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDreamBoardsQuery>response.data.listDreamBoards;
  }
  async GetLookupTableDreamBoard(
    PK: string,
    SK: string
  ): Promise<GetLookupTableDreamBoardQuery> {
    const statement = `query GetLookupTableDreamBoard($PK: String!, $SK: String!) {
        getLookupTableDreamBoard(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupTableDreamBoardQuery>(
      response.data.getLookupTableDreamBoard
    );
  }
  async ListLookupTableDreamBoards(
    filter?: TableLookupTableDreamBoardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupTableDreamBoardsQuery> {
    const statement = `query ListLookupTableDreamBoards($filter: TableLookupTableDreamBoardFilterInput, $limit: Int, $nextToken: String) {
        listLookupTableDreamBoards(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          Elements {
            __typename
            PK
            SK
            Elements
            createdTime
            updatedTime
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupTableDreamBoardsQuery>(
      response.data.listLookupTableDreamBoards
    );
  }
  async GetLookupMasterTicketTable(
    PK: string,
    SK: number
  ): Promise<GetLookupMasterTicketTableQuery> {
    const statement = `query GetLookupMasterTicketTable($PK: String!, $SK: Int!) {
        getLookupMasterTicketTable(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupMasterTicketTableQuery>(
      response.data.getLookupMasterTicketTable
    );
  }
  async ListLookupMasterTicketTables(
    filter?: TableLookupMasterTicketTableFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupMasterTicketTablesQuery> {
    const statement = `query ListLookupMasterTicketTables($filter: TableLookupMasterTicketTableFilterInput, $limit: Int, $nextToken: String) {
        listLookupMasterTicketTables(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            PK
            SK
            createdTime
            updatedTime
            Items
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupMasterTicketTablesQuery>(
      response.data.listLookupMasterTicketTables
    );
  }
  async GetLookupCamcDates(
    clientid: string,
    id: string
  ): Promise<GetLookupCamcDatesQuery> {
    const statement = `query GetLookupCamcDates($clientid: String!, $id: String!) {
        getLookupCamcDates(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientid,
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLookupCamcDatesQuery>response.data.getLookupCamcDates;
  }
  async ListLookupCamcDates(
    filter?: TableLookupCamcDatesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLookupCamcDatesQuery> {
    const statement = `query ListLookupCamcDates($filter: TableLookupCamcDatesFilterInput, $limit: Int, $nextToken: String) {
        listLookupCamcDates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            clientid
            id
            metadata
            options
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLookupCamcDatesQuery>response.data.listLookupCamcDates;
  }
  async GetClientTable(
    clientid: string,
    id: string
  ): Promise<GetClientTableQuery> {
    const statement = `query GetClientTable($clientid: String!, $id: String!) {
        getClientTable(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientid,
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetClientTableQuery>response.data.getClientTable;
  }
  async ListClientTables(
    filter?: TableClientTableFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListClientTablesQuery> {
    const statement = `query ListClientTables($filter: TableClientTableFilterInput, $limit: Int, $nextToken: String) {
        listClientTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            clientid
            id
            metadata
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListClientTablesQuery>response.data.listClientTables;
  }
  async GetAuditrail(PK: string, SK: number): Promise<GetAuditrailQuery> {
    const statement = `query GetAuditrail($PK: String!, $SK: Int!) {
        getAuditrail(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {
      PK,
      SK
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAuditrailQuery>response.data.getAuditrail;
  }
  async ListAuditrails(
    filter?: TableAuditrailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAuditrailsQuery> {
    const statement = `query ListAuditrails($filter: TableAuditrailFilterInput, $limit: Int, $nextToken: String) {
        listAuditrails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            PK
            SK
            action
            appName
            appVersion
            client
            description
            language
            module
            module_version
            platform
            records
            screenHeight
            screenWidth
            url
            userAgent
            username
            user_id
            web_version
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAuditrailsQuery>response.data.listAuditrails;
  }
  OnCreateLocationTypeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLocationType">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateLocationType {
        onCreateLocationType {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLocationType">>
  >;

  OnUpdateLocationTypeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLocationType">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateLocationType {
        onUpdateLocationType {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLocationType">>
  >;

  OnDeleteLocationTypeListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLocationType">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteLocationType {
        onDeleteLocationType {
          __typename
          client
          location_type_id
          Description
          Metadata
          locations {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLocationType">>
  >;

  OnCreateAssetLocationsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAssetLocations">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssetLocations {
        onCreateAssetLocations {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAssetLocations">
    >
  >;

  OnUpdateAssetLocationsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAssetLocations">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssetLocations {
        onUpdateAssetLocations {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAssetLocations">
    >
  >;

  OnDeleteAssetLocationsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAssetLocations">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssetLocations {
        onDeleteAssetLocations {
          __typename
          client
          asset_id
          location_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAssetLocations">
    >
  >;

  OnCreateLocationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLocation">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateLocation {
        onCreateLocation {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLocation">>
  >;

  OnUpdateLocationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLocation">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateLocation {
        onUpdateLocation {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLocation">>
  >;

  OnDeleteLocationListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLocation">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteLocation {
        onDeleteLocation {
          __typename
          client
          assets {
            __typename
            nextToken
          }
          location_id
          name
          location_type
          longitude
          latitude
          movable_latitude
          movable_longitude
          gmt_diff
          Metadata
          createdAt
          updatedAt
          locationTypeLocationsId
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLocation">>
  >;

  OnCreatePriorityListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePriority">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePriority {
        onCreatePriority {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePriority">>
  >;

  OnUpdatePriorityListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePriority">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePriority {
        onUpdatePriority {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePriority">>
  >;

  OnDeletePriorityListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePriority">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePriority {
        onDeletePriority {
          __typename
          client
          priority_id
          description
          metadata
          problems {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePriority">>
  >;

  OnCreateProblemListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProblem">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProblem {
        onCreateProblem {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProblem">>
  >;

  OnUpdateProblemListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProblem">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProblem {
        onUpdateProblem {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProblem">>
  >;

  OnDeleteProblemListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProblem">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProblem {
        onDeleteProblem {
          __typename
          client
          problem_id
          Origin_Status
          priority
          description
          detail
          prob_time
          notif_policy_rem
          escalation_lvl
          comment
          comment_Header
          comment_Suffix
          comment_Prefix
          technician_Comment
          impact
          createdAt
          updatedAt
          metadata
          priorityProblemsId
          items
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProblem">>
  >;

  OnCreateAmProblemsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAmProblems">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAmProblems {
        onCreateAmProblems {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAmProblems">>
  >;

  OnUpdateAmProblemsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAmProblems">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAmProblems {
        onUpdateAmProblems {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAmProblems">>
  >;

  OnDeleteAmProblemsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAmProblems">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAmProblems {
        onDeleteAmProblems {
          __typename
          client
          assetmap_id
          problem_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAmProblems">>
  >;

  OnCreateAssetMapListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssetMap">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssetMap {
        onCreateAssetMap {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssetMap">>
  >;

  OnUpdateAssetMapListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssetMap">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssetMap {
        onUpdateAssetMap {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssetMap">>
  >;

  OnDeleteAssetMapListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssetMap">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssetMap {
        onDeleteAssetMap {
          __typename
          client
          assetmap_id
          problems
          assets {
            __typename
            nextToken
          }
          Description
          Metadata
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssetMap">>
  >;

  OnCreateManufacturerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateManufacturer">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateManufacturer {
        onCreateManufacturer {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateManufacturer">>
  >;

  OnUpdateManufacturerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateManufacturer">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateManufacturer {
        onUpdateManufacturer {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateManufacturer">>
  >;

  OnDeleteManufacturerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteManufacturer">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteManufacturer {
        onDeleteManufacturer {
          __typename
          client
          manufacturer_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteManufacturer">>
  >;

  OnCreateModelListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateModel">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateModel {
        onCreateModel {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateModel">>
  >;

  OnUpdateModelListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateModel">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateModel {
        onUpdateModel {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateModel">>
  >;

  OnDeleteModelListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteModel">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteModel {
        onDeleteModel {
          __typename
          client
          model_id
          Description
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteModel">>
  >;

  OnCreateDepartmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDepartment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDepartment {
        onCreateDepartment {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDepartment">>
  >;

  OnUpdateDepartmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDepartment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDepartment {
        onUpdateDepartment {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDepartment">>
  >;

  OnDeleteDepartmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDepartment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDepartment {
        onDeleteDepartment {
          __typename
          client
          dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDepartment">>
  >;

  OnCreateSubDepartmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSubDepartment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSubDepartment {
        onCreateSubDepartment {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSubDepartment">>
  >;

  OnUpdateSubDepartmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSubDepartment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSubDepartment {
        onUpdateSubDepartment {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSubDepartment">>
  >;

  OnDeleteSubDepartmentListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSubDepartment">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSubDepartment {
        onDeleteSubDepartment {
          __typename
          client
          sub_dept_id
          Description
          Metadata
          users {
            __typename
            nextToken
          }
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSubDepartment">>
  >;

  OnCreateVendorCompanyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVendorCompany">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVendorCompany {
        onCreateVendorCompany {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVendorCompany">>
  >;

  OnUpdateVendorCompanyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVendorCompany">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVendorCompany {
        onUpdateVendorCompany {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVendorCompany">>
  >;

  OnDeleteVendorCompanyListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVendorCompany">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVendorCompany {
        onDeleteVendorCompany {
          __typename
          client
          vendor_id
          name
          Description
          email
          ph_no
          Metadata
          assets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVendorCompany">>
  >;

  OnCreateRoleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateRole {
        onCreateRole {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
  >;

  OnUpdateRoleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRole">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateRole {
        onUpdateRole {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRole">>
  >;

  OnDeleteRoleListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteRole {
        onDeleteRole {
          __typename
          client
          role_id
          Description
          time_mult
          Metadata
          users {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
  >;

  OnCreateUserLocationsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserLocations">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserLocations {
        onCreateUserLocations {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUserLocations">>
  >;

  OnUpdateUserLocationsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserLocations">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserLocations {
        onUpdateUserLocations {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUserLocations">>
  >;

  OnDeleteUserLocationsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserLocations">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserLocations {
        onDeleteUserLocations {
          __typename
          client
          user_id
          location_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUserLocations">>
  >;

  OnCreateAssetUserNotifyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAssetUserNotify">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssetUserNotify {
        onCreateAssetUserNotify {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAssetUserNotify">
    >
  >;

  OnUpdateAssetUserNotifyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAssetUserNotify">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssetUserNotify {
        onUpdateAssetUserNotify {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAssetUserNotify">
    >
  >;

  OnDeleteAssetUserNotifyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAssetUserNotify">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssetUserNotify {
        onDeleteAssetUserNotify {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAssetUserNotify">
    >
  >;

  OnCreateAssetOwnerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssetOwner">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssetOwner {
        onCreateAssetOwner {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssetOwner">>
  >;

  OnUpdateAssetOwnerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssetOwner">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssetOwner {
        onUpdateAssetOwner {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssetOwner">>
  >;

  OnDeleteAssetOwnerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssetOwner">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssetOwner {
        onDeleteAssetOwner {
          __typename
          client
          user_id
          asset_id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssetOwner">>
  >;

  OnCreateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  >;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  >;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          client
          user_id
          name
          username
          Description
          Metadata
          mobile
          email
          user_type
          mobile_privacy
          alert_email
          alert_sms
          esc_email
          esc_sms
          role
          department
          sub_department
          tickets {
            __typename
            nextToken
          }
          createdAt
          updatedAt
          departmentUsersId
          subDepartmentUsersId
          roleUsersId
          userReporting_toId
          formcreate
          formread
          formupdate
          formdelete
          imageurl
          formuserpermission
          notification_token
          telegramID
          telegram
          push_notification
          sms
          permission
          chat_Bot
          mobile_permission
          man_per_hour_cost
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  >;

  OnCreateAssetAdditionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssetAddition">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssetAddition {
        onCreateAssetAddition {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssetAddition">>
  >;

  OnUpdateAssetAdditionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssetAddition">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssetAddition {
        onUpdateAssetAddition {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssetAddition">>
  >;

  OnDeleteAssetAdditionListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssetAddition">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssetAddition {
        onDeleteAssetAddition {
          __typename
          client
          tag_id
          asset_map
          user_notify_id
          user_notify
          tech_multi_id
          multiple_technician
          asset_id
          model
          sub_department
          department
          vendor_company
          manufacturer
          location
          tickets {
            __typename
            nextToken
          }
          next_service
          last_service
          installation_date
          amc_expiry
          group
          bought_price
          current_price
          asset_type
          metadata
          tech_id
          technician
          createdAt
          updatedAt
          locationAssetsId
          assetMapAssetsId
          manufacturerAssetsId
          modelAssetsId
          departmentAssetsId
          subDepartmentAssetsId
          vendorCompanyAssetsId
          formpermission
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssetAddition">>
  >;

  OnCreateTicketsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTickets">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTickets {
        onCreateTickets {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTickets">>
  >;

  OnUpdateTicketsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTickets">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTickets {
        onUpdateTickets {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTickets">>
  >;

  OnDeleteTicketsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTickets">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTickets {
        onDeleteTickets {
          __typename
          client
          ticket_id
          tag_id
          asset_add
          asset_type_id
          problem_id
          problem
          user
          current_status
          metadata
          current_esc_lvl
          comment
          notification_sent_flag
          esc_users
          total_time
          Problem_time
          max_esc_reached
          createdAt
          max_esc_lvl
          technician_id
          mutli_assign
          updatedAt
          progress_update
          solved_update
          closed_update
          userTicketsId
          assetAdditionTicketsId
          moveble_latitude
          moveble_longitude
          status_progress
          status_solved
          status_closed
          other
          rating
          otp
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTickets">>
  >;

  OnCreateSavedTagsListener(
    client?: string,
    userIdTagId?: string,
    userId?: string,
    tagId?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSavedTags">>
  > {
    const statement = `subscription OnCreateSavedTags($client: String, $userIdTagId: String, $userId: String, $tagId: String, $createdAt: AWSDateTime) {
        onCreateSavedTags(
          client: $client
          userIdTagId: $userIdTagId
          userId: $userId
          tagId: $tagId
          createdAt: $createdAt
        ) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (userIdTagId) {
      gqlAPIServiceArguments.userIdTagId = userIdTagId;
    }
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (tagId) {
      gqlAPIServiceArguments.tagId = tagId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSavedTags">>
    >;
  }

  OnUpdateSavedTagsListener(
    client?: string,
    userIdTagId?: string,
    userId?: string,
    tagId?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSavedTags">>
  > {
    const statement = `subscription OnUpdateSavedTags($client: String, $userIdTagId: String, $userId: String, $tagId: String, $createdAt: AWSDateTime) {
        onUpdateSavedTags(
          client: $client
          userIdTagId: $userIdTagId
          userId: $userId
          tagId: $tagId
          createdAt: $createdAt
        ) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (userIdTagId) {
      gqlAPIServiceArguments.userIdTagId = userIdTagId;
    }
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (tagId) {
      gqlAPIServiceArguments.tagId = tagId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSavedTags">>
    >;
  }

  OnDeleteSavedTagsListener(
    client?: string,
    userIdTagId?: string,
    userId?: string,
    tagId?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSavedTags">>
  > {
    const statement = `subscription OnDeleteSavedTags($client: String, $userIdTagId: String, $userId: String, $tagId: String, $createdAt: AWSDateTime) {
        onDeleteSavedTags(
          client: $client
          userIdTagId: $userIdTagId
          userId: $userId
          tagId: $tagId
          createdAt: $createdAt
        ) {
          __typename
          client
          userIdTagId
          userId
          tagId
          createdAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (userIdTagId) {
      gqlAPIServiceArguments.userIdTagId = userIdTagId;
    }
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (tagId) {
      gqlAPIServiceArguments.tagId = tagId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSavedTags">>
    >;
  }

  OnCreateTicketImagesListener(
    client?: string,
    ticket_id?: string,
    images?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTicketImages">>
  > {
    const statement = `subscription OnCreateTicketImages($client: String, $ticket_id: String, $images: String) {
        onCreateTicketImages(client: $client, ticket_id: $ticket_id, images: $images) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (ticket_id) {
      gqlAPIServiceArguments.ticket_id = ticket_id;
    }
    if (images) {
      gqlAPIServiceArguments.images = images;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTicketImages">
      >
    >;
  }

  OnUpdateTicketImagesListener(
    client?: string,
    ticket_id?: string,
    images?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTicketImages">>
  > {
    const statement = `subscription OnUpdateTicketImages($client: String, $ticket_id: String, $images: String) {
        onUpdateTicketImages(client: $client, ticket_id: $ticket_id, images: $images) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (ticket_id) {
      gqlAPIServiceArguments.ticket_id = ticket_id;
    }
    if (images) {
      gqlAPIServiceArguments.images = images;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTicketImages">
      >
    >;
  }

  OnDeleteTicketImagesListener(
    client?: string,
    ticket_id?: string,
    images?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTicketImages">>
  > {
    const statement = `subscription OnDeleteTicketImages($client: String, $ticket_id: String, $images: String) {
        onDeleteTicketImages(client: $client, ticket_id: $ticket_id, images: $images) {
          __typename
          client
          ticket_id
          images
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (ticket_id) {
      gqlAPIServiceArguments.ticket_id = ticket_id;
    }
    if (images) {
      gqlAPIServiceArguments.images = images;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTicketImages">
      >
    >;
  }

  OnCreateFormParameterListener(
    client?: string,
    parameterID?: string,
    other?: string,
    formdefault?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateFormParameter">>
  > {
    const statement = `subscription OnCreateFormParameter($client: String, $parameterID: String, $other: String, $formdefault: String, $createdAt: AWSDateTime) {
        onCreateFormParameter(
          client: $client
          parameterID: $parameterID
          other: $other
          formdefault: $formdefault
          createdAt: $createdAt
        ) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (parameterID) {
      gqlAPIServiceArguments.parameterID = parameterID;
    }
    if (other) {
      gqlAPIServiceArguments.other = other;
    }
    if (formdefault) {
      gqlAPIServiceArguments.formdefault = formdefault;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateFormParameter">
      >
    >;
  }

  OnUpdateFormParameterListener(
    client?: string,
    parameterID?: string,
    other?: string,
    formdefault?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateFormParameter">>
  > {
    const statement = `subscription OnUpdateFormParameter($client: String, $parameterID: String, $other: String, $formdefault: String, $createdAt: AWSDateTime) {
        onUpdateFormParameter(
          client: $client
          parameterID: $parameterID
          other: $other
          formdefault: $formdefault
          createdAt: $createdAt
        ) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (parameterID) {
      gqlAPIServiceArguments.parameterID = parameterID;
    }
    if (other) {
      gqlAPIServiceArguments.other = other;
    }
    if (formdefault) {
      gqlAPIServiceArguments.formdefault = formdefault;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateFormParameter">
      >
    >;
  }

  OnDeleteFormParameterListener(
    client?: string,
    parameterID?: string,
    other?: string,
    formdefault?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteFormParameter">>
  > {
    const statement = `subscription OnDeleteFormParameter($client: String, $parameterID: String, $other: String, $formdefault: String, $createdAt: AWSDateTime) {
        onDeleteFormParameter(
          client: $client
          parameterID: $parameterID
          other: $other
          formdefault: $formdefault
          createdAt: $createdAt
        ) {
          __typename
          client
          parameterID
          other
          formdefault
          createdAt
          updatedAt
          parameterDescription
          parameterLable
          parameterType
          placeholder
          require
          value_1
          value_2
          list
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (parameterID) {
      gqlAPIServiceArguments.parameterID = parameterID;
    }
    if (other) {
      gqlAPIServiceArguments.other = other;
    }
    if (formdefault) {
      gqlAPIServiceArguments.formdefault = formdefault;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteFormParameter">
      >
    >;
  }

  OnCreateFormTypeListener(
    client?: string,
    typeID?: string,
    typeLable?: string,
    typeDescription?: string,
    emailSubject?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateFormType">>
  > {
    const statement = `subscription OnCreateFormType($client: String, $typeID: String, $typeLable: String, $typeDescription: String, $emailSubject: String) {
        onCreateFormType(
          client: $client
          typeID: $typeID
          typeLable: $typeLable
          typeDescription: $typeDescription
          emailSubject: $emailSubject
        ) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (typeID) {
      gqlAPIServiceArguments.typeID = typeID;
    }
    if (typeLable) {
      gqlAPIServiceArguments.typeLable = typeLable;
    }
    if (typeDescription) {
      gqlAPIServiceArguments.typeDescription = typeDescription;
    }
    if (emailSubject) {
      gqlAPIServiceArguments.emailSubject = emailSubject;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateFormType">>
    >;
  }

  OnUpdateFormTypeListener(
    client?: string,
    typeID?: string,
    typeLable?: string,
    typeDescription?: string,
    emailSubject?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateFormType">>
  > {
    const statement = `subscription OnUpdateFormType($client: String, $typeID: String, $typeLable: String, $typeDescription: String, $emailSubject: String) {
        onUpdateFormType(
          client: $client
          typeID: $typeID
          typeLable: $typeLable
          typeDescription: $typeDescription
          emailSubject: $emailSubject
        ) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (typeID) {
      gqlAPIServiceArguments.typeID = typeID;
    }
    if (typeLable) {
      gqlAPIServiceArguments.typeLable = typeLable;
    }
    if (typeDescription) {
      gqlAPIServiceArguments.typeDescription = typeDescription;
    }
    if (emailSubject) {
      gqlAPIServiceArguments.emailSubject = emailSubject;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateFormType">>
    >;
  }

  OnDeleteFormTypeListener(
    client?: string,
    typeID?: string,
    typeLable?: string,
    typeDescription?: string,
    emailSubject?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteFormType">>
  > {
    const statement = `subscription OnDeleteFormType($client: String, $typeID: String, $typeLable: String, $typeDescription: String, $emailSubject: String) {
        onDeleteFormType(
          client: $client
          typeID: $typeID
          typeLable: $typeLable
          typeDescription: $typeDescription
          emailSubject: $emailSubject
        ) {
          __typename
          client
          typeID
          typeLable
          typeDescription
          emailSubject
          emailHeader
          emailBody
          emailFooter
          other
          emailEnable
          emailRecipent
          typeparameterID
          formIDvisible
          lablevisible
          descriptionvisible
          updatedAt
          createdAt
          mqtt_id
          iot_connect
          reserved
          block_time
          scan
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (typeID) {
      gqlAPIServiceArguments.typeID = typeID;
    }
    if (typeLable) {
      gqlAPIServiceArguments.typeLable = typeLable;
    }
    if (typeDescription) {
      gqlAPIServiceArguments.typeDescription = typeDescription;
    }
    if (emailSubject) {
      gqlAPIServiceArguments.emailSubject = emailSubject;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteFormType">>
    >;
  }

  OnCreateTaskListener(
    clientID?: string,
    formID?: string,
    createdAt?: string,
    updatedAt?: string,
    typeDes?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTask">>
  > {
    const statement = `subscription OnCreateTask($clientID: String, $formID: String, $createdAt: AWSDateTime, $updatedAt: AWSDateTime, $typeDes: String) {
        onCreateTask(
          clientID: $clientID
          formID: $formID
          createdAt: $createdAt
          updatedAt: $updatedAt
          typeDes: $typeDes
        ) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientID) {
      gqlAPIServiceArguments.clientID = clientID;
    }
    if (formID) {
      gqlAPIServiceArguments.formID = formID;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (typeDes) {
      gqlAPIServiceArguments.typeDes = typeDes;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTask">>
    >;
  }

  OnUpdateTaskListener(
    clientID?: string,
    formID?: string,
    createdAt?: string,
    updatedAt?: string,
    typeDes?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTask">>
  > {
    const statement = `subscription OnUpdateTask($clientID: String, $formID: String, $createdAt: AWSDateTime, $updatedAt: AWSDateTime, $typeDes: String) {
        onUpdateTask(
          clientID: $clientID
          formID: $formID
          createdAt: $createdAt
          updatedAt: $updatedAt
          typeDes: $typeDes
        ) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientID) {
      gqlAPIServiceArguments.clientID = clientID;
    }
    if (formID) {
      gqlAPIServiceArguments.formID = formID;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (typeDes) {
      gqlAPIServiceArguments.typeDes = typeDes;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTask">>
    >;
  }

  OnDeleteTaskListener(
    clientID?: string,
    formID?: string,
    createdAt?: string,
    updatedAt?: string,
    typeDes?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTask">>
  > {
    const statement = `subscription OnDeleteTask($clientID: String, $formID: String, $createdAt: AWSDateTime, $updatedAt: AWSDateTime, $typeDes: String) {
        onDeleteTask(
          clientID: $clientID
          formID: $formID
          createdAt: $createdAt
          updatedAt: $updatedAt
          typeDes: $typeDes
        ) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          label
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientID) {
      gqlAPIServiceArguments.clientID = clientID;
    }
    if (formID) {
      gqlAPIServiceArguments.formID = formID;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (updatedAt) {
      gqlAPIServiceArguments.updatedAt = updatedAt;
    }
    if (typeDes) {
      gqlAPIServiceArguments.typeDes = typeDes;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTask">>
    >;
  }

  OnCreateMqttConfigurationListener(
    client?: string,
    mqtt_id?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateMqttConfiguration">
    >
  > {
    const statement = `subscription OnCreateMqttConfiguration($client: String, $mqtt_id: String) {
        onCreateMqttConfiguration(client: $client, mqtt_id: $mqtt_id) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (mqtt_id) {
      gqlAPIServiceArguments.mqtt_id = mqtt_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateMqttConfiguration">
      >
    >;
  }

  OnUpdateMqttConfigurationListener(
    client?: string,
    mqtt_id?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateMqttConfiguration">
    >
  > {
    const statement = `subscription OnUpdateMqttConfiguration($client: String, $mqtt_id: String) {
        onUpdateMqttConfiguration(client: $client, mqtt_id: $mqtt_id) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (mqtt_id) {
      gqlAPIServiceArguments.mqtt_id = mqtt_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateMqttConfiguration">
      >
    >;
  }

  OnDeleteMqttConfigurationListener(
    client?: string,
    mqtt_id?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteMqttConfiguration">
    >
  > {
    const statement = `subscription OnDeleteMqttConfiguration($client: String, $mqtt_id: String) {
        onDeleteMqttConfiguration(client: $client, mqtt_id: $mqtt_id) {
          __typename
          client
          mqtt_id
          name
          host
          url
          port
          username
          password
          topic
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (mqtt_id) {
      gqlAPIServiceArguments.mqtt_id = mqtt_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteMqttConfiguration">
      >
    >;
  }

  OnCreateShiftTableListener(
    client?: string,
    shift_id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateShiftTable">>
  > {
    const statement = `subscription OnCreateShiftTable($client: String, $shift_id: String) {
        onCreateShiftTable(client: $client, shift_id: $shift_id) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (shift_id) {
      gqlAPIServiceArguments.shift_id = shift_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateShiftTable">>
    >;
  }

  OnUpdateShiftTableListener(
    client?: string,
    shift_id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateShiftTable">>
  > {
    const statement = `subscription OnUpdateShiftTable($client: String, $shift_id: String) {
        onUpdateShiftTable(client: $client, shift_id: $shift_id) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (shift_id) {
      gqlAPIServiceArguments.shift_id = shift_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateShiftTable">>
    >;
  }

  OnDeleteShiftTableListener(
    client?: string,
    shift_id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteShiftTable">>
  > {
    const statement = `subscription OnDeleteShiftTable($client: String, $shift_id: String) {
        onDeleteShiftTable(client: $client, shift_id: $shift_id) {
          __typename
          client
          shift_id
          form_time
          to_time
          shifts
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client) {
      gqlAPIServiceArguments.client = client;
    }
    if (shift_id) {
      gqlAPIServiceArguments.shift_id = shift_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteShiftTable">>
    >;
  }

  OnCreateDashboardListener(
    client_id?: string,
    dashboard_id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDashboard">>
  > {
    const statement = `subscription OnCreateDashboard($client_id: String, $dashboard_id: String) {
        onCreateDashboard(client_id: $client_id, dashboard_id: $dashboard_id) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client_id) {
      gqlAPIServiceArguments.client_id = client_id;
    }
    if (dashboard_id) {
      gqlAPIServiceArguments.dashboard_id = dashboard_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDashboard">>
    >;
  }

  OnUpdateDashboardListener(
    client_id?: string,
    dashboard_id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDashboard">>
  > {
    const statement = `subscription OnUpdateDashboard($client_id: String, $dashboard_id: String) {
        onUpdateDashboard(client_id: $client_id, dashboard_id: $dashboard_id) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client_id) {
      gqlAPIServiceArguments.client_id = client_id;
    }
    if (dashboard_id) {
      gqlAPIServiceArguments.dashboard_id = dashboard_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDashboard">>
    >;
  }

  OnDeleteDashboardListener(
    client_id?: string,
    dashboard_id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDashboard">>
  > {
    const statement = `subscription OnDeleteDashboard($client_id: String, $dashboard_id: String) {
        onDeleteDashboard(client_id: $client_id, dashboard_id: $dashboard_id) {
          __typename
          client_id
          dashboard_id
          tile
          latest_10_tickets
          latest_10_user_ratings
          upcoming_event
          Graph_data
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (client_id) {
      gqlAPIServiceArguments.client_id = client_id;
    }
    if (dashboard_id) {
      gqlAPIServiceArguments.dashboard_id = dashboard_id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDashboard">>
    >;
  }

  OnCreateNotificationTableListener(
    createdDate?: string,
    username_client?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateNotificationTable">
    >
  > {
    const statement = `subscription OnCreateNotificationTable($createdDate: String, $username_client: String) {
        onCreateNotificationTable(
          createdDate: $createdDate
          username_client: $username_client
        ) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (createdDate) {
      gqlAPIServiceArguments.createdDate = createdDate;
    }
    if (username_client) {
      gqlAPIServiceArguments.username_client = username_client;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateNotificationTable">
      >
    >;
  }

  OnUpdateNotificationTableListener(
    createdDate?: string,
    username_client?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateNotificationTable">
    >
  > {
    const statement = `subscription OnUpdateNotificationTable($createdDate: String, $username_client: String) {
        onUpdateNotificationTable(
          createdDate: $createdDate
          username_client: $username_client
        ) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (createdDate) {
      gqlAPIServiceArguments.createdDate = createdDate;
    }
    if (username_client) {
      gqlAPIServiceArguments.username_client = username_client;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateNotificationTable">
      >
    >;
  }

  OnDeleteNotificationTableListener(
    createdDate?: string,
    username_client?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteNotificationTable">
    >
  > {
    const statement = `subscription OnDeleteNotificationTable($createdDate: String, $username_client: String) {
        onDeleteNotificationTable(
          createdDate: $createdDate
          username_client: $username_client
        ) {
          __typename
          createdDate
          username_client
          main_notification_seen_flag
          message
          type
          ticket_id
          icon
          color
          task_id
          Problem
          formtype
          created_time
          update_time
          seen_flag
          ticket_info
          task_info
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (createdDate) {
      gqlAPIServiceArguments.createdDate = createdDate;
    }
    if (username_client) {
      gqlAPIServiceArguments.username_client = username_client;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteNotificationTable">
      >
    >;
  }

  OnCreateLookupTableTicketListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableTicket">
    >
  > {
    const statement = `subscription OnCreateLookupTableTicket($PK: String, $SK: Int) {
        onCreateLookupTableTicket(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableTicket">
      >
    >;
  }

  OnUpdateLookupTableTicketListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableTicket">
    >
  > {
    const statement = `subscription OnUpdateLookupTableTicket($PK: String, $SK: Int) {
        onUpdateLookupTableTicket(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableTicket">
      >
    >;
  }

  OnDeleteLookupTableTicketListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableTicket">
    >
  > {
    const statement = `subscription OnDeleteLookupTableTicket($PK: String, $SK: Int) {
        onDeleteLookupTableTicket(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableTicket">
      >
    >;
  }

  OnCreateLookupTableTaskListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableTask">
    >
  > {
    const statement = `subscription OnCreateLookupTableTask($PK: String, $SK: Int) {
        onCreateLookupTableTask(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableTask">
      >
    >;
  }

  OnUpdateLookupTableTaskListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableTask">
    >
  > {
    const statement = `subscription OnUpdateLookupTableTask($PK: String, $SK: Int) {
        onUpdateLookupTableTask(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableTask">
      >
    >;
  }

  OnDeleteLookupTableTaskListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableTask">
    >
  > {
    const statement = `subscription OnDeleteLookupTableTask($PK: String, $SK: Int) {
        onDeleteLookupTableTask(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableTask">
      >
    >;
  }

  OnCreateLookupTableNotificationListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableNotification">
    >
  > {
    const statement = `subscription OnCreateLookupTableNotification($PK: String, $SK: Int) {
        onCreateLookupTableNotification(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableNotification">
      >
    >;
  }

  OnUpdateLookupTableNotificationListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableNotification">
    >
  > {
    const statement = `subscription OnUpdateLookupTableNotification($PK: String, $SK: Int) {
        onUpdateLookupTableNotification(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableNotification">
      >
    >;
  }

  OnDeleteLookupTableNotificationListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableNotification">
    >
  > {
    const statement = `subscription OnDeleteLookupTableNotification($PK: String, $SK: Int) {
        onDeleteLookupTableNotification(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableNotification">
      >
    >;
  }

  OnCreateTaskTestListener(
    clientID?: string,
    formID?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaskTest">>
  > {
    const statement = `subscription OnCreateTaskTest($clientID: String, $formID: String) {
        onCreateTaskTest(clientID: $clientID, formID: $formID) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientID) {
      gqlAPIServiceArguments.clientID = clientID;
    }
    if (formID) {
      gqlAPIServiceArguments.formID = formID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaskTest">>
    >;
  }

  OnUpdateTaskTestListener(
    clientID?: string,
    formID?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaskTest">>
  > {
    const statement = `subscription OnUpdateTaskTest($clientID: String, $formID: String) {
        onUpdateTaskTest(clientID: $clientID, formID: $formID) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientID) {
      gqlAPIServiceArguments.clientID = clientID;
    }
    if (formID) {
      gqlAPIServiceArguments.formID = formID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaskTest">>
    >;
  }

  OnDeleteTaskTestListener(
    clientID?: string,
    formID?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaskTest">>
  > {
    const statement = `subscription OnDeleteTaskTest($clientID: String, $formID: String) {
        onDeleteTaskTest(clientID: $clientID, formID: $formID) {
          __typename
          clientID
          formID
          createdAt
          updatedAt
          created_Username
          updated_Username
          typeDes
          typelable
          parameters_data
          formDescription_visibilty
          formID_visibility
          formlable_visibility
          formTypeID
          iotDateTime
          startDateTime
          endDateTime
          blockDateTime
          label
          device
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientID) {
      gqlAPIServiceArguments.clientID = clientID;
    }
    if (formID) {
      gqlAPIServiceArguments.formID = formID;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaskTest">>
    >;
  }

  OnCreateLookupTaskTestListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTaskTest">
    >
  > {
    const statement = `subscription OnCreateLookupTaskTest($PK: String, $SK: Int) {
        onCreateLookupTaskTest(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTaskTest">
      >
    >;
  }

  OnUpdateLookupTaskTestListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTaskTest">
    >
  > {
    const statement = `subscription OnUpdateLookupTaskTest($PK: String, $SK: Int) {
        onUpdateLookupTaskTest(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTaskTest">
      >
    >;
  }

  OnDeleteLookupTaskTestListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTaskTest">
    >
  > {
    const statement = `subscription OnDeleteLookupTaskTest($PK: String, $SK: Int) {
        onDeleteLookupTaskTest(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTaskTest">
      >
    >;
  }

  OnCreateLookupTableProblemListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableProblem">
    >
  > {
    const statement = `subscription OnCreateLookupTableProblem($PK: String, $SK: Int) {
        onCreateLookupTableProblem(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableProblem">
      >
    >;
  }

  OnUpdateLookupTableProblemListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableProblem">
    >
  > {
    const statement = `subscription OnUpdateLookupTableProblem($PK: String, $SK: Int) {
        onUpdateLookupTableProblem(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableProblem">
      >
    >;
  }

  OnDeleteLookupTableProblemListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableProblem">
    >
  > {
    const statement = `subscription OnDeleteLookupTableProblem($PK: String, $SK: Int) {
        onDeleteLookupTableProblem(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableProblem">
      >
    >;
  }

  OnCreateLookupTableAssetMapListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableAssetMap">
    >
  > {
    const statement = `subscription OnCreateLookupTableAssetMap($PK: String, $SK: Int) {
        onCreateLookupTableAssetMap(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableAssetMap">
      >
    >;
  }

  OnUpdateLookupTableAssetMapListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableAssetMap">
    >
  > {
    const statement = `subscription OnUpdateLookupTableAssetMap($PK: String, $SK: Int) {
        onUpdateLookupTableAssetMap(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableAssetMap">
      >
    >;
  }

  OnDeleteLookupTableAssetMapListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableAssetMap">
    >
  > {
    const statement = `subscription OnDeleteLookupTableAssetMap($PK: String, $SK: Int) {
        onDeleteLookupTableAssetMap(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableAssetMap">
      >
    >;
  }

  OnCreateLookupTableAssetAdditionListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableAssetAddition">
    >
  > {
    const statement = `subscription OnCreateLookupTableAssetAddition($PK: String, $SK: Int) {
        onCreateLookupTableAssetAddition(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableAssetAddition">
      >
    >;
  }

  OnUpdateLookupTableAssetAdditionListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableAssetAddition">
    >
  > {
    const statement = `subscription OnUpdateLookupTableAssetAddition($PK: String, $SK: Int) {
        onUpdateLookupTableAssetAddition(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableAssetAddition">
      >
    >;
  }

  OnDeleteLookupTableAssetAdditionListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableAssetAddition">
    >
  > {
    const statement = `subscription OnDeleteLookupTableAssetAddition($PK: String, $SK: Int) {
        onDeleteLookupTableAssetAddition(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableAssetAddition">
      >
    >;
  }

  OnCreateLookupTableRoleListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableRole">
    >
  > {
    const statement = `subscription OnCreateLookupTableRole($PK: String, $SK: Int) {
        onCreateLookupTableRole(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableRole">
      >
    >;
  }

  OnUpdateLookupTableRoleListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableRole">
    >
  > {
    const statement = `subscription OnUpdateLookupTableRole($PK: String, $SK: Int) {
        onUpdateLookupTableRole(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableRole">
      >
    >;
  }

  OnDeleteLookupTableRoleListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableRole">
    >
  > {
    const statement = `subscription OnDeleteLookupTableRole($PK: String, $SK: Int) {
        onDeleteLookupTableRole(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableRole">
      >
    >;
  }

  OnCreateLookupTableDepartmentListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableDepartment">
    >
  > {
    const statement = `subscription OnCreateLookupTableDepartment($PK: String, $SK: Int) {
        onCreateLookupTableDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableDepartment">
      >
    >;
  }

  OnUpdateLookupTableDepartmentListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableDepartment">
    >
  > {
    const statement = `subscription OnUpdateLookupTableDepartment($PK: String, $SK: Int) {
        onUpdateLookupTableDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableDepartment">
      >
    >;
  }

  OnDeleteLookupTableDepartmentListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableDepartment">
    >
  > {
    const statement = `subscription OnDeleteLookupTableDepartment($PK: String, $SK: Int) {
        onDeleteLookupTableDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableDepartment">
      >
    >;
  }

  OnCreateLookupTableSubDepartmentListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableSubDepartment">
    >
  > {
    const statement = `subscription OnCreateLookupTableSubDepartment($PK: String, $SK: Int) {
        onCreateLookupTableSubDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableSubDepartment">
      >
    >;
  }

  OnUpdateLookupTableSubDepartmentListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableSubDepartment">
    >
  > {
    const statement = `subscription OnUpdateLookupTableSubDepartment($PK: String, $SK: Int) {
        onUpdateLookupTableSubDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableSubDepartment">
      >
    >;
  }

  OnDeleteLookupTableSubDepartmentListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableSubDepartment">
    >
  > {
    const statement = `subscription OnDeleteLookupTableSubDepartment($PK: String, $SK: Int) {
        onDeleteLookupTableSubDepartment(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableSubDepartment">
      >
    >;
  }

  OnCreateLookupTableLocationListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableLocation">
    >
  > {
    const statement = `subscription OnCreateLookupTableLocation($PK: String, $SK: Int) {
        onCreateLookupTableLocation(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableLocation">
      >
    >;
  }

  OnUpdateLookupTableLocationListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableLocation">
    >
  > {
    const statement = `subscription OnUpdateLookupTableLocation($PK: String, $SK: Int) {
        onUpdateLookupTableLocation(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableLocation">
      >
    >;
  }

  OnDeleteLookupTableLocationListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableLocation">
    >
  > {
    const statement = `subscription OnDeleteLookupTableLocation($PK: String, $SK: Int) {
        onDeleteLookupTableLocation(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableLocation">
      >
    >;
  }

  OnCreateLookupTableLocationTypeListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableLocationType">
    >
  > {
    const statement = `subscription OnCreateLookupTableLocationType($PK: String, $SK: Int) {
        onCreateLookupTableLocationType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableLocationType">
      >
    >;
  }

  OnUpdateLookupTableLocationTypeListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableLocationType">
    >
  > {
    const statement = `subscription OnUpdateLookupTableLocationType($PK: String, $SK: Int) {
        onUpdateLookupTableLocationType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableLocationType">
      >
    >;
  }

  OnDeleteLookupTableLocationTypeListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableLocationType">
    >
  > {
    const statement = `subscription OnDeleteLookupTableLocationType($PK: String, $SK: Int) {
        onDeleteLookupTableLocationType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableLocationType">
      >
    >;
  }

  OnCreateLookupTableManufactureListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableManufacture">
    >
  > {
    const statement = `subscription OnCreateLookupTableManufacture($PK: String, $SK: Int) {
        onCreateLookupTableManufacture(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableManufacture">
      >
    >;
  }

  OnUpdateLookupTableManufactureListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableManufacture">
    >
  > {
    const statement = `subscription OnUpdateLookupTableManufacture($PK: String, $SK: Int) {
        onUpdateLookupTableManufacture(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableManufacture">
      >
    >;
  }

  OnDeleteLookupTableManufactureListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableManufacture">
    >
  > {
    const statement = `subscription OnDeleteLookupTableManufacture($PK: String, $SK: Int) {
        onDeleteLookupTableManufacture(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableManufacture">
      >
    >;
  }

  OnCreateLookupTableVenderListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableVender">
    >
  > {
    const statement = `subscription OnCreateLookupTableVender($PK: String, $SK: Int) {
        onCreateLookupTableVender(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableVender">
      >
    >;
  }

  OnUpdateLookupTableVenderListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableVender">
    >
  > {
    const statement = `subscription OnUpdateLookupTableVender($PK: String, $SK: Int) {
        onUpdateLookupTableVender(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableVender">
      >
    >;
  }

  OnDeleteLookupTableVenderListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableVender">
    >
  > {
    const statement = `subscription OnDeleteLookupTableVender($PK: String, $SK: Int) {
        onDeleteLookupTableVender(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableVender">
      >
    >;
  }

  OnCreateLookupTableUserListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableUser">
    >
  > {
    const statement = `subscription OnCreateLookupTableUser($PK: String, $SK: Int) {
        onCreateLookupTableUser(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableUser">
      >
    >;
  }

  OnUpdateLookupTableUserListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableUser">
    >
  > {
    const statement = `subscription OnUpdateLookupTableUser($PK: String, $SK: Int) {
        onUpdateLookupTableUser(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableUser">
      >
    >;
  }

  OnDeleteLookupTableUserListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableUser">
    >
  > {
    const statement = `subscription OnDeleteLookupTableUser($PK: String, $SK: Int) {
        onDeleteLookupTableUser(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableUser">
      >
    >;
  }

  OnCreateLookupTablePriorityListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTablePriority">
    >
  > {
    const statement = `subscription OnCreateLookupTablePriority($PK: String, $SK: Int) {
        onCreateLookupTablePriority(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTablePriority">
      >
    >;
  }

  OnUpdateLookupTablePriorityListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTablePriority">
    >
  > {
    const statement = `subscription OnUpdateLookupTablePriority($PK: String, $SK: Int) {
        onUpdateLookupTablePriority(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTablePriority">
      >
    >;
  }

  OnDeleteLookupTablePriorityListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTablePriority">
    >
  > {
    const statement = `subscription OnDeleteLookupTablePriority($PK: String, $SK: Int) {
        onDeleteLookupTablePriority(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTablePriority">
      >
    >;
  }

  OnCreateLookupTableFormParameterListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableFormParameter">
    >
  > {
    const statement = `subscription OnCreateLookupTableFormParameter($PK: String, $SK: Int) {
        onCreateLookupTableFormParameter(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableFormParameter">
      >
    >;
  }

  OnUpdateLookupTableFormParameterListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableFormParameter">
    >
  > {
    const statement = `subscription OnUpdateLookupTableFormParameter($PK: String, $SK: Int) {
        onUpdateLookupTableFormParameter(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableFormParameter">
      >
    >;
  }

  OnDeleteLookupTableFormParameterListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableFormParameter">
    >
  > {
    const statement = `subscription OnDeleteLookupTableFormParameter($PK: String, $SK: Int) {
        onDeleteLookupTableFormParameter(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableFormParameter">
      >
    >;
  }

  OnCreateLookupTableFormTypeListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableFormType">
    >
  > {
    const statement = `subscription OnCreateLookupTableFormType($PK: String, $SK: Int) {
        onCreateLookupTableFormType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableFormType">
      >
    >;
  }

  OnUpdateLookupTableFormTypeListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableFormType">
    >
  > {
    const statement = `subscription OnUpdateLookupTableFormType($PK: String, $SK: Int) {
        onUpdateLookupTableFormType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableFormType">
      >
    >;
  }

  OnDeleteLookupTableFormTypeListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableFormType">
    >
  > {
    const statement = `subscription OnDeleteLookupTableFormType($PK: String, $SK: Int) {
        onDeleteLookupTableFormType(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableFormType">
      >
    >;
  }

  OnCreateLookupTableShiftListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableShift">
    >
  > {
    const statement = `subscription OnCreateLookupTableShift($PK: String, $SK: Int) {
        onCreateLookupTableShift(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableShift">
      >
    >;
  }

  OnUpdateLookupTableShiftListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableShift">
    >
  > {
    const statement = `subscription OnUpdateLookupTableShift($PK: String, $SK: Int) {
        onUpdateLookupTableShift(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableShift">
      >
    >;
  }

  OnDeleteLookupTableShiftListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableShift">
    >
  > {
    const statement = `subscription OnDeleteLookupTableShift($PK: String, $SK: Int) {
        onDeleteLookupTableShift(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableShift">
      >
    >;
  }

  OnCreateLookupTableModelListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableModel">
    >
  > {
    const statement = `subscription OnCreateLookupTableModel($PK: String, $SK: Int) {
        onCreateLookupTableModel(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableModel">
      >
    >;
  }

  OnUpdateLookupTableModelListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableModel">
    >
  > {
    const statement = `subscription OnUpdateLookupTableModel($PK: String, $SK: Int) {
        onUpdateLookupTableModel(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableModel">
      >
    >;
  }

  OnDeleteLookupTableModelListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableModel">
    >
  > {
    const statement = `subscription OnDeleteLookupTableModel($PK: String, $SK: Int) {
        onDeleteLookupTableModel(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableModel">
      >
    >;
  }

  OnCreateDreamBoardListener(
    PK?: string,
    SK?: string,
    name?: string,
    description?: string,
    board_type?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDreamBoard">>
  > {
    const statement = `subscription OnCreateDreamBoard($PK: String, $SK: String, $name: String, $description: String, $board_type: String) {
        onCreateDreamBoard(
          PK: $PK
          SK: $SK
          name: $name
          description: $description
          board_type: $board_type
        ) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (description) {
      gqlAPIServiceArguments.description = description;
    }
    if (board_type) {
      gqlAPIServiceArguments.board_type = board_type;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDreamBoard">>
    >;
  }

  OnUpdateDreamBoardListener(
    PK?: string,
    SK?: string,
    name?: string,
    description?: string,
    board_type?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDreamBoard">>
  > {
    const statement = `subscription OnUpdateDreamBoard($PK: String, $SK: String, $name: String, $description: String, $board_type: String) {
        onUpdateDreamBoard(
          PK: $PK
          SK: $SK
          name: $name
          description: $description
          board_type: $board_type
        ) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (description) {
      gqlAPIServiceArguments.description = description;
    }
    if (board_type) {
      gqlAPIServiceArguments.board_type = board_type;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDreamBoard">>
    >;
  }

  OnDeleteDreamBoardListener(
    PK?: string,
    SK?: string,
    name?: string,
    description?: string,
    board_type?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDreamBoard">>
  > {
    const statement = `subscription OnDeleteDreamBoard($PK: String, $SK: String, $name: String, $description: String, $board_type: String) {
        onDeleteDreamBoard(
          PK: $PK
          SK: $SK
          name: $name
          description: $description
          board_type: $board_type
        ) {
          __typename
          PK
          SK
          name
          description
          board_type
          settings
          rdt
          devices
          type
          onLoadJS
          onUpdateJS
          HTML
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (description) {
      gqlAPIServiceArguments.description = description;
    }
    if (board_type) {
      gqlAPIServiceArguments.board_type = board_type;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDreamBoard">>
    >;
  }

  OnCreateLookupTableDreamBoardListener(
    PK?: string,
    SK?: string,
    Elements?: string,
    createdTime?: number,
    updatedTime?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupTableDreamBoard">
    >
  > {
    const statement = `subscription OnCreateLookupTableDreamBoard($PK: String, $SK: String, $Elements: AWSJSON, $createdTime: Int, $updatedTime: Int) {
        onCreateLookupTableDreamBoard(
          PK: $PK
          SK: $SK
          Elements: $Elements
          createdTime: $createdTime
          updatedTime: $updatedTime
        ) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    if (Elements) {
      gqlAPIServiceArguments.Elements = Elements;
    }
    if (createdTime) {
      gqlAPIServiceArguments.createdTime = createdTime;
    }
    if (updatedTime) {
      gqlAPIServiceArguments.updatedTime = updatedTime;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupTableDreamBoard">
      >
    >;
  }

  OnUpdateLookupTableDreamBoardListener(
    PK?: string,
    SK?: string,
    Elements?: string,
    createdTime?: number,
    updatedTime?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupTableDreamBoard">
    >
  > {
    const statement = `subscription OnUpdateLookupTableDreamBoard($PK: String, $SK: String, $Elements: AWSJSON, $createdTime: Int, $updatedTime: Int) {
        onUpdateLookupTableDreamBoard(
          PK: $PK
          SK: $SK
          Elements: $Elements
          createdTime: $createdTime
          updatedTime: $updatedTime
        ) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    if (Elements) {
      gqlAPIServiceArguments.Elements = Elements;
    }
    if (createdTime) {
      gqlAPIServiceArguments.createdTime = createdTime;
    }
    if (updatedTime) {
      gqlAPIServiceArguments.updatedTime = updatedTime;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupTableDreamBoard">
      >
    >;
  }

  OnDeleteLookupTableDreamBoardListener(
    PK?: string,
    SK?: string,
    Elements?: string,
    createdTime?: number,
    updatedTime?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupTableDreamBoard">
    >
  > {
    const statement = `subscription OnDeleteLookupTableDreamBoard($PK: String, $SK: String, $Elements: AWSJSON, $createdTime: Int, $updatedTime: Int) {
        onDeleteLookupTableDreamBoard(
          PK: $PK
          SK: $SK
          Elements: $Elements
          createdTime: $createdTime
          updatedTime: $updatedTime
        ) {
          __typename
          PK
          SK
          Elements
          createdTime
          updatedTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    if (Elements) {
      gqlAPIServiceArguments.Elements = Elements;
    }
    if (createdTime) {
      gqlAPIServiceArguments.createdTime = createdTime;
    }
    if (updatedTime) {
      gqlAPIServiceArguments.updatedTime = updatedTime;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupTableDreamBoard">
      >
    >;
  }

  OnCreateLookupMasterTicketTableListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupMasterTicketTable">
    >
  > {
    const statement = `subscription OnCreateLookupMasterTicketTable($PK: String, $SK: Int) {
        onCreateLookupMasterTicketTable(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupMasterTicketTable">
      >
    >;
  }

  OnUpdateLookupMasterTicketTableListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupMasterTicketTable">
    >
  > {
    const statement = `subscription OnUpdateLookupMasterTicketTable($PK: String, $SK: Int) {
        onUpdateLookupMasterTicketTable(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupMasterTicketTable">
      >
    >;
  }

  OnDeleteLookupMasterTicketTableListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupMasterTicketTable">
    >
  > {
    const statement = `subscription OnDeleteLookupMasterTicketTable($PK: String, $SK: Int) {
        onDeleteLookupMasterTicketTable(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          createdTime
          updatedTime
          Items
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupMasterTicketTable">
      >
    >;
  }

  OnCreateLookupCamcDatesListener(
    clientid?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateLookupCamcDates">
    >
  > {
    const statement = `subscription OnCreateLookupCamcDates($clientid: String, $id: String) {
        onCreateLookupCamcDates(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientid) {
      gqlAPIServiceArguments.clientid = clientid;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateLookupCamcDates">
      >
    >;
  }

  OnUpdateLookupCamcDatesListener(
    clientid?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateLookupCamcDates">
    >
  > {
    const statement = `subscription OnUpdateLookupCamcDates($clientid: String, $id: String) {
        onUpdateLookupCamcDates(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientid) {
      gqlAPIServiceArguments.clientid = clientid;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateLookupCamcDates">
      >
    >;
  }

  OnDeleteLookupCamcDatesListener(
    clientid?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteLookupCamcDates">
    >
  > {
    const statement = `subscription OnDeleteLookupCamcDates($clientid: String, $id: String) {
        onDeleteLookupCamcDates(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
          options
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientid) {
      gqlAPIServiceArguments.clientid = clientid;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteLookupCamcDates">
      >
    >;
  }

  OnCreateClientTableListener(
    clientid?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateClientTable">>
  > {
    const statement = `subscription OnCreateClientTable($clientid: String, $id: String) {
        onCreateClientTable(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientid) {
      gqlAPIServiceArguments.clientid = clientid;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateClientTable">>
    >;
  }

  OnUpdateClientTableListener(
    clientid?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateClientTable">>
  > {
    const statement = `subscription OnUpdateClientTable($clientid: String, $id: String) {
        onUpdateClientTable(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientid) {
      gqlAPIServiceArguments.clientid = clientid;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateClientTable">>
    >;
  }

  OnDeleteClientTableListener(
    clientid?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteClientTable">>
  > {
    const statement = `subscription OnDeleteClientTable($clientid: String, $id: String) {
        onDeleteClientTable(clientid: $clientid, id: $id) {
          __typename
          clientid
          id
          metadata
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientid) {
      gqlAPIServiceArguments.clientid = clientid;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteClientTable">>
    >;
  }

  OnCreateAuditrailListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAuditrail">>
  > {
    const statement = `subscription OnCreateAuditrail($PK: String, $SK: Int) {
        onCreateAuditrail(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAuditrail">>
    >;
  }

  OnUpdateAuditrailListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAuditrail">>
  > {
    const statement = `subscription OnUpdateAuditrail($PK: String, $SK: Int) {
        onUpdateAuditrail(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAuditrail">>
    >;
  }

  OnDeleteAuditrailListener(
    PK?: string,
    SK?: number
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAuditrail">>
  > {
    const statement = `subscription OnDeleteAuditrail($PK: String, $SK: Int) {
        onDeleteAuditrail(PK: $PK, SK: $SK) {
          __typename
          PK
          SK
          action
          appName
          appVersion
          client
          description
          language
          module
          module_version
          platform
          records
          screenHeight
          screenWidth
          url
          userAgent
          username
          user_id
          web_version
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (PK) {
      gqlAPIServiceArguments.PK = PK;
    }
    if (SK) {
      gqlAPIServiceArguments.SK = SK;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAuditrail">>
    >;
  }
}
