import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { APIService } from "src/app/API.service";

@Component({
  selector: "app-page404",
  templateUrl: "./page404.component.html",
  styleUrls: ["./page404.component.scss"],
})
export class Page404Component implements OnInit {
  loginDetail: any;
  appname:string
  url:any;
  constructor(private router: Router, private api: APIService,) {}

  async ngOnInit() {

    this.loginDetail = JSON.parse(localStorage.getItem("currentUser"))
    const client=this.loginDetail.client;
    
    const res=await this.api.GetClientTable("Intel",client)
    console.log(res)
    const metadataObject = JSON.parse(res.metadata);
    if (metadataObject.img) {
      const img = metadataObject.img;
      this.appname=metadataObject.name;
      console.log("Image:", img);
      this.url = `https://dreamboard-latest.s3.ap-south-1.amazonaws.com/` + img;
    } else {
      console.log("Image not found in metadata");
    }


  }

  goToHomePage() {
    // Specify the path you want to navigate to
    const homePagePath = "admin/configuration/allUsers"; // Change this to your actual home page path
    this.router.navigate([homePagePath]);
  }
}
