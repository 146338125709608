// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cognitoUserPoolId:  "ap-south-1_aXzYjB0Al",
  cognitoAppClientId: "1b771nkg0bkq9hfpf6ao66sur2",
  apiUrl: "http://localhost:4200",
  apiKey: "a711c168ba9328a1d9857b072c4f425b",
  google_map_api_key:'AIzaSyBHyyHrCdUsQujRwBs0XOlKnvBG2miE26Q',
  baseUrl: "https://api.themoviedb.org/3",
  images: "https://image.tmdb.org/t/p",
  url:"https://cre2m354w4.execute-api.ap-south-1.amazonaws.com/prod/",
  ticketUrl: "https://cre2m354w4.execute-api.ap-south-1.amazonaws.com/prod/ticket", //get it from the API GATEWAY FROM AWS
  assignedUrl: "https://cre2m354w4.execute-api.ap-south-1.amazonaws.com/prod/assigned",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
