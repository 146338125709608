import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { APIService } from "src/app/API.service";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from "@angular/core";
//import { ROUTES } from "./sidebar-items";
import { Sidebar} from "./sidebar-items";
import { AuthService } from "src/app/core/service/auth.service";
import { Role } from "src/app/core/models/role";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  loginDetail: any;
  userPermissions: any[];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private api: APIService,
    private data:Sidebar,
    private route: ActivatedRoute,
  ) {
    const body = this.elementRef.nativeElement.closest("body");
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        // logic for select active menu in dropdown
        const role = ["admin", "employee", "client"];
        const currenturl = event.url.split("?")[0];
        const firstString = currenturl.split("/").slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = event.url.split("/")[2];
          this.level2Menu = event.url.split("/")[3];
        } else {
          this.level1Menu = event.url.split("/")[1];
          this.level2Menu = event.url.split("/")[2];
        }

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });


    this.route.url.subscribe(urlSegments => {
      // Converting URL segments array to a string path
      const path = urlSegments.map(segment => segment.toString()).join('/');

      console.log("Current Path:", path);
      

      //  this.routeChecking.getRoutePermission(path);
   

    });
  }
  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }
  async ngOnInit() {
   

    this.loginDetail = JSON.parse(localStorage.getItem("currentUser"))
    console.log("LoginDetail:",this.loginDetail )

    if (this.loginDetail) {
      const id = this.loginDetail.id;
      const client = this.loginDetail.client;
    
    
        let userPermissions = [];
        //this.data.getAllEmployeess();
        let routes=await this.data.generatedreamboard();
        console.log("rou",routes)

       
        // Fetch user details and permissions
        if (this.authService.currentUserValue) {
          // const { id, client } = this.authService.currentUserValue;
          const userDetails = await this.api.GetUser(id, client);
          console.log("User Details:", userDetails);
          userPermissions = userDetails.permission || [];
          console.log("User Permissions:", userPermissions);
          console.log("User Permissions (before):", userPermissions);

          // Adding "default" to the array
          userPermissions.push("default");

          console.log("User Permissions (after):", userPermissions);
        }
        this.userPermissions = userPermissions;
        
        if (this.authService.currentUserValue) {
          const userRole = this.authService.currentUserValue.role;
          this.userFullName = this.authService.currentUserValue.name;
      
          // console.log("Permissions",this.userPermissions)
          // console.log("SideBarUserName:", this.userFullName);
          console.log(this.data.ROUTES)

          
      
          // Ensure that ROUTES is defined before filtering
          // if (Array.isArray(ROUTES)) {
          //   this.sidebarItems = ROUTES.filter(
          //     (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf("All") !== -1
          //   );
          //   if (userRole === Role.Admin) {
          //     this.userType = Role.Admin;
          //   } else if (userRole === Role.Client) {
          //     this.userType = Role.Client;
          //   } else if (userRole === Role.Employee) {
          //     this.userType = Role.Employee;
          //   } else {
          //     this.userType = Role.Admin;
          //   }
          // } else {
          //   console.error("ROUTES is not an array or is undefined");
          // }
        
          if (Array.isArray(this.data.ROUTES)) {
            // Filter sidebarItems based on userRole and userPermissions
            this.sidebarItems = this.data.ROUTES.filter((mainMenuItem) => {
              const roleCheck =
                mainMenuItem.role && (mainMenuItem.role.includes(userRole) || mainMenuItem.role.includes("All"));
              const permissionCheck =
                !mainMenuItem.permission || mainMenuItem.permission.length === 0 || (this.userPermissions && mainMenuItem.permission.some((perm) => this.userPermissions.includes(perm)));
          
             // console.log(`Main Menu Item: ${mainMenuItem.title}, Role Check: ${roleCheck}, Permission Check: ${permissionCheck}`);
          
              if (roleCheck && permissionCheck) {
                // If the main menu item passes the checks, filter its submenus based on permissions
                if (mainMenuItem.submenu && Array.isArray(mainMenuItem.submenu)) {
                  mainMenuItem.submenu = mainMenuItem.submenu.filter((submenuItem) => {
                    const submenuPermissionCheck =
                      !submenuItem.permission || submenuItem.permission.length === 0 || (this.userPermissions && submenuItem.permission.some((perm) => this.userPermissions.includes(perm)));
          
                    //console.log(`  Submenu Item: ${submenuItem.title}, Permission Check: ${submenuPermissionCheck}`);
          
                    return submenuPermissionCheck;
                  });
          
                  // Include the main menu item if it has submenus or if at least one submenu item passes the checks
                  return mainMenuItem.submenu.length > 0;
                }
          
                return true; // Include the main menu item if it has no submenus
              }
          
              return false; // Exclude the main menu item if it fails the checks
            });
          
            // Set the user type based on userRole
            if (userRole === Role.Admin) {
              this.userType = Role.Admin;
            } else if (userRole === Role.Client) {
              this.userType = Role.Client;
            } else if (userRole === Role.Employee) {
              this.userType = Role.Employee;
            } else {
              this.userType = Role.Admin;
            }
          
            console.log("Filtered Sidebar Items:", this.sidebarItems);
          } else {
            console.error("ROUTES is not an array or is undefined");
          }
        }          
      
      
  }

    // this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  logout() {


    localStorage.clear();
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }
}
