import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { APIService } from "src/app/API.service";
import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  loginDetail: any;
  constructor(private authService: AuthService, private router: Router,private api:APIService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      
      const userRole = this.authService.currentUserValue;
      if (userRole) {
        return true
      }
      return false;
    }

    if(route.routeConfig){
    this.loginDetail = JSON.parse(localStorage.getItem("currentUser"))
    console.log("LoginDetail:",this.loginDetail )
    const id = this.loginDetail.id;
      const client = this.loginDetail.client;

    const test= await this.api.GetUser(id, client);
    //console.log("User Details:", test);
    const userPermissions = test.permission || [];
    console.log("User Permissions:", userPermissions);
    // this.router.navigate(["/authentication/signin"]);
    // console.log(route)

      const queryParamsAsString = route.routeConfig
      console.log("query",queryParamsAsString)
      alert("auth")
    
      // Assuming userPermissions is an array of strings
     let istrue= userPermissions.some(permission => permission === queryParamsAsString)
  
      if (istrue) {
        console.log("User has permission");
        return true;
      } else {
       this.router.navigate(['/**'])
        return false;
      }
  }
this.authService.logout()
    this.router.navigate(["/authentication/signin"]);
    return false;
  }
}
