import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
//import { ok } from "assert";
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router,) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    
  }

  public get currentUserValue(): User {

    console.log(this.currentUserSubject.value)
    return this.currentUserSubject.value;
  }
  setting(X){
    localStorage.setItem("currentUser", JSON.stringify(X));
    this.currentUserSubject.next(X);
  }
  cognitoAttributes(cognitoUser){
          cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log("\n\Some Error: ", err, "\n\n");
            } else {
              console.log('ATTRIBUTES', result);
              let X = {
                id: result[5].Value,
                //img: user.img,
                username: result[8].Value,
                name: result[9].Value,
                user_type: result[15].Value,
                //lastName: user.lastName,
                password: "DUMMY",
                role: "Admin",
                token: "admin-token",
              }
              localStorage.setItem("currentUser", JSON.stringify(X));

              const role = this.currentUserValue.role;
                if (role === "All" || role === "Admin") {
                  this.router.navigate(["/admin/dashboard/main"]);
                } else if (role === "Employee") {
                  this.router.navigate(["/employee/dashboard"]);
                } else if (role === "Client") {
                  this.router.navigate(["/client/dashboard"]);
                } else {
                  this.router.navigate(["/authentication/signin"]);
                }
                //this.loading=false
            }
          })
}
  cognitoLogin(username: string, password: string){
        let X;
        let poolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId
        };
        var authenticationData = {
            Username : username,
            Password : password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        var userData = {
            Username : username,
            Pool : userPool
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          console.log("SUCCESS")
          var accessToken = result.getAccessToken().getJwtToken();
          console.log("RESULT:", result)
          console.log("RE", accessToken)
          /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
          var idToken = result.getIdToken().getJwtToken();
          console.log("RESU:", idToken)
          //this.cognitoAttributes(cognitoUser)
          cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log("\n\Some Error: ", err, "\n\n");
            } else {
              console.log('ATTRIBUTES', result);
              let X = {
                id: result[5].Value,
                //img: user.img,
                username: result[8].Value,
                name: result[9].Value,
                user_type: result[15].Value,
                //lastName: user.lastName,
                password: "DUMMY",
                role: "Admin",
                token: "admin-token",
              }
              localStorage.setItem("currentUser", JSON.stringify(X));
              //this.currentUserSubject.next(X);

              const role = X.role;
                if (role === "All" || role === "Admin") {
                  this.router.navigate(["/admin/dashboard/main"]);
                } else if (role === "Employee") {
                  this.router.navigate(["/employee/dashboard"]);
                } else if (role === "Client") {
                  this.router.navigate(["/client/dashboard"]);
                } else {
                  this.router.navigate(["/authentication/signin"]);
                }
                //this.loading=false
            }
          })
        },

        onFailure: function (err) {
          console.log("FAILURE")
          X=1
          //X={ error: "Username or password is incorrect" }
          //alert(err);
        },

      });
      //return X
  }
  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/authenticate`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
